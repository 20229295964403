<template>
    <div>
        <!-- Burst Progress Bar -->
        <v-row class="mt-2">
            <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <!-- Header-->
                <v-expansion-panel-header :color="$vuetify.theme.dark ? '#363636' : '#d6d6d6'">
                  <div
                      class="d-flex justify-between align-center mouse-pointer font-weight-bold"
                      :style="{ width: `${100}%`, height: `${50}px` }"
                  >
                    <div class="ml-3 d-flex align-center" style="gap: 0.6em;"><div style="font-size: 1.2em;">{{ burstCopy.name.charAt(0).toUpperCase() + burstCopy.name.slice(1) }}</div> <div>-</div> <div style="font-size: 1.2em;">{{ burstCopy.deliveryType.name }}</div></div>
                        <div class="font-weight-light d-flex align-center" style="font-size: 0.8em;">
                            <!-- Dates -->
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-5"
                                >{{ burstCopy.startDate | moment}} - </span>
                                </template>
                              <span>Start Date</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1"
                              > {{ burstCopy.endDate | moment}}</span>
                            </template>
                              <span>End Date</span>
                          </v-tooltip>
                        </div>

                        <div style="margin-top: 5px;">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                <span
                                    class="
                                    d-inline-block 
                                    text-truncate
                                    "
                                    style="
                                    margin-left: 70px;
                                    max-width: 400px;
                                    font-size: 0.85em;

                                    "
                                    v-bind="attrs" v-on="on"
                                >
                                    <b>Latest:</b> {{ burstCopy.workflowStatus ? burstCopy.workflowStatus.as : 'Miltestones pending' }}
                                </span>
                                </template>
                                <span>{{ burstCopy.workflowStatus ? burstCopy.workflowStatus.as : 'Miltestones pending' }}</span>
                            </v-tooltip>
                        </div>

                        <div class="ml-auto font-weight-light d-flex align-center">
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-10"
                                    v-if="!!burstCopy.workflowStatus && burstCopy.workflowStatus.id !== 0"
                                >
                                    <v-progress-circular
                                        :value="getProgress(burstCopy)"
                                        size="30"
                                        width="4"
                                        color="primary"
                                    />
                                </span>
                            </template>
                            <span v-if="!!burstCopy.workflowStatus && burstCopy.workflowStatus.id !== 0">{{ getProgress(burstCopy) }} / 100 %</span>
                          </v-tooltip>
                        </div> 
                      </div>
                </v-expansion-panel-header>
                <!-- Content-->
                <v-expansion-panel-content>
                    <div v-if="!!burstCopy.workflowStatus && burstCopy.workflowStatus.id !== 0">
                    <div class="d-flex align-center justify-end my-4">
                    <div v-for="item in [{name: 'Completed', color: '#499F68'}, {name: 'In progress', color: '#F464A9'}, {name: 'Triage', color: '#4AA6D5'}]">
                        <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="d-flex align-center text-caption mr-4"
                                >
                                    <div 
                                        :style="{ backgroundColor: item.color, width: '15px', height: '15px', borderRadius: '50%' }"
                                        class="mx-2"
                                    ></div>
                                    {{ item.name }}
                                </div>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </div>
                    </div>
                  <!-- Epic Progress Bar display -->
                  <div class="d-flex align-center" style="min-width: 100%; height: 25px; border-radius: 10px"> 
                    <v-tooltip top v-for="category, index in burstCopy.workflowStatus.categories" :key="category.id">
                        <template v-slot:activator="{ on, attrs }" >
                            <v-hover v-slot="{ hover }" >
                                <!-- Sub Issue of an epic display-->
                                <div 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="$store.state.Permissions.campaignMilestoneEdit ? openIssue(index, category, burstCopy) : ''"
                                    class="text-center"
                                    :class="burstCopy.workflowStatus.categories.length > 1 ? 'subIssue' : 'oneIssue'"
                                    :style="{
                                        backgroundColor: filterColor(category.s),
                                        width: `${100 / burstCopy.workflowStatus.categories.length}%`,
                                        opacity: hover ? 0.5 : 1,
                                        height: '25px',
                                        
                                    }">
                                    <span class="text-caption">{{ category.n }}</span>
                                </div>
                            </v-hover>
                        </template>
                        <span>{{ category.n }}</span>
                    </v-tooltip>
                    
                  </div>
                    </div>
                    <div v-else style="width: 100%;">
                        <span>No profile milestone attached.</span>
                    </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </v-col>
        </v-row>
        <v-card style="height: 100%" class="mb-6">
            <!-- Burst title
            <v-row class="primary ma-0">
                <v-col cols="6">
                    <h3 style="color: white">
                        {{ burstCopy.name }} - {{ burstCopy.deliveryType.name }}
                        <span v-if="burst.startDate" :style="{ fontWeight: `300`, fontSize: `12px`, marginLeft: '10px' }">
                            {{ burstCopy.startDate | moment }} - {{ burstCopy.endDate | moment }}.
                        </span>
                    </h3>
                </v-col>
            </v-row> -->
            <!-- Burst details -->
            <v-card-text>
                <v-row>
                    <v-data-table 
                        :headers="headers"
                        :items="getMediaOwnersList"
                        :hide-default-footer="false"
                        :items-per-page="10" 
                        :expanded.sync="expanded"
                        :loading="data.length == 0"
                        show-expand
                        style="width: 100%"
                    >
                        <!-- Get the item.live header and add a tolltip to the text-->
                        <template v-slot:header.live="{ header }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                                </template>
                                <span>Aggregate: Check, Cumulative: Uncheck</span>
                            </v-tooltip>
                        </template> 

                        <!-- Name + Logo -->
                        <template v-slot:name="{ item }">
                            <div v-if="item.logo !== null">
                                <v-img :src="item.logo" style="width: 60px; height: 50px"></v-img>
                            </div>
                            <div v-else style="max-width: 100px;">
                                {{ item.name }}
                            </div>
                        </template>

                        <!-- Formats -->
                        <template v-slot:item.format="{ item }">
                            <div style="max-width: 100px;">
                                {{ item.screen.length }}
                            </div>
                        </template>

                        <!-- kpi -->
                        <template v-slot:item.kpi="{ item }">
                            <div style="max-width: 150px;" class="d-flex justify-end ml-3" >
                                <!-- Select button with 3 values to choose from-->
                                <v-select
                                    :items="kpiList"
                                    v-model="item.kpi"
                                    item-value="name"
                                    label="KPI"
                                    outlined
                                    dense
                                    hide-details
                                    @change="changeKPI(item)"
                                >
                                </v-select>
                            </div>
                        </template>

                        <!-- Dates -->
                        <!-- TODO: change dates to display the earliest startDate present and the latest endDate present -->
                        <template v-slot:item.date="{ item }">
                            <div>
                                <small :class="hasAnalyticsEditPermission && type.includes('tracking') && !datesMatchBurst(item) ? 'datesMismatch' : ''" style="font-size: x-small;">
                                    <span v-if="getEarliestDate(item) && getLatestDate(item)">
                                        {{ getEarliestDate(item) | moment }} - {{ getLatestDate(item) | moment }}
                                    </span>
                                    <span v-else>
                                        <span v-if="type.includes('playout')">Burst Only</span>
                                        <span v-else>Playout Only</span>
                                    </span>
                                </small>
                            </div>
                        </template>

                        <!-- Booked -->
                        <template v-slot:item.booked="{ item }">
                            <div>
                                <small>{{ calculateBookedTotal(item) }}</small>
                            </div>
                        </template>

                        <!-- Delivered -->
                        <template v-slot:item.deliver="{ item }">
                            <div>
                                <small v-if="type.includes('tracking')">{{ calculateDeliverTotal(item) }}</small>
                                <small v-else-if="type.includes('graph')">{{ calculcateActualsTotal(item) }}</small>
                                <small v-else-if="type.includes('playout')">{{ calculatePlayoutTotal(item) }}</small> 
                            </div>
                        </template>

                        <!-- Performance -->
                        <template v-slot:item.performance="{ item }">
                            <div>
                                <small v-if="type.includes('graph')">{{ calcPerformance(calculateBookedTotal(item), calculcateActualsTotal(item)) }}</small>
                                <small v-else-if="type.includes('tracking')">{{ calcPerformance(calculateBookedTotal(item), calculateDeliverTotal(item)) }}</small>
                                <small v-else-if="type.includes('playout')">{{ calcPerformance(calculateBookedTotal(item), calculatePlayoutTotal(item)) }}</small>
                                <small>%</small>
                            </div>
                        </template>

                        <!-- Comments -->
                        <template v-slot:item.comment="{ item }">
                            <div v-if="hasValidCBS(item)">
                                <v-btn
                                    :color=" hasMoNotes(item) ? 'primary' : 'grey'" 
                                    id="no-background-hover" 
                                    icon
                                    @click="showCommentDialogAll(item)"
                                >
                                    <v-icon>mdi-comment</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <!-- Expansion-->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headersExpanded.length" style="width: 100%" class="ma-0 pa-0">
                                <v-data-table
                                    :headers="headersExpanded"
                                    :items="item.screen"
                                    hide-default-footer
                                    :items-per-page="100"
                                    :loading="data.length == 0"
                                    style="width: 100%;"
                                >
                                    <!-- Name -->
                                    <template v-slot:item.name="{ item }" >
                                        <div style="max-width: 100px;">{{ item.name }}</div>
                                    </template>
                                    <!-- Dates -->
                                    <template v-slot:item.date="{ item }">
                                        <v-menu
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            @input="openDatePicker(item)"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <small
                                                    style="font-size: x-small;"
                                                    :class="hasAnalyticsEditPermission && type.includes('tracking') && !datesMatch(item) ? 'datesMismatch' : ''"
                                                > <span v-if="(item.targetsManual[getKPIIndex(item)].startDate)&& (item.targetsManual[getKPIIndex(item)].endDate)"
                                                        v-bind="(hasAnalyticsEditPermission && type.includes('tracking')) ? attrs : ''"
                                                        v-on="(hasAnalyticsEditPermission && type.includes('tracking')) ? on : ''">
                                                    {{ item.targetsManual[getKPIIndex(item)].startDate | moment }} - {{ item.targetsManual[getKPIIndex(item)].endDate | moment }} 
                                                  </span>
                                                  <span v-else>
                                                    <span v-if="type.includes('playout')">Burst Only</span>
                                                    <span v-else>Playout Only</span>
                                                  </span>
                                                </small>
                                            </template>
                                            <v-date-picker
                                                v-model="datePicker"
                                                @change="updateTarget(item)"
                                                :allowed-dates="allowedDates(item)"
                                                :picker-date="displayDate"
                                                @update:picker-date="updatePickerDate"
                                                range
                                                no-title
                                                scrollable
                                            >
                                                <template v-slot:actions>
                                                    <v-btn color="primary" text @click="clearDate">Reset</v-btn>
                                                </template>
                                            </v-date-picker>
                                            <v-row style="flex-direction: column; padding: 5px; width: 10px;">
                                                <v-btn @click="resetDates(item)" class="my-2" color="warning" dark>Reset</v-btn>
                                            </v-row>
                                        </v-menu>
                                    </template>
                                    <!-- Booked -->
                                    <template v-slot:item.booked="{ item }">
                                        <small>
                                            <div class="editable" :contenteditable="hasAnalyticsEditPermission && type.includes('tracking')" @blur="(e) => updateTarget(item, e, 'booked')">
                                                <span v-if="type.includes('graph')">{{ item.targetsManual[getKPIIndex(item)].graphBooked }}</span>
                                                <span v-else-if="type.includes('tracking')">{{ item.targetsManual[getKPIIndex(item)].booked }}</span>
                                                <span v-else-if="type.includes('playout')">{{ item.targetsManual[getKPIIndex(item)].playoutBooked }}</span>
                                            </div>
                                        </small>
                                    </template>

                                    <!-- Delivered / Actuals -->
                                    <template v-slot:item.deliver="{ item }">
                                        <small>
                                            <div :contenteditable="hasAnalyticsEditPermission && type.includes('tracking')" @blur="(e) => updateTarget(item, e, 'deliver')">
                                                <span v-if="type.includes('graph')">{{ item.targetsManual[getKPIIndex(item)].graphActuals }}</span>
                                                <span v-else-if="type.includes('tracking')">{{ item.targetsManual[getKPIIndex(item)].deliver }}</span>
                                                <span v-else-if="type.includes('playout')">{{ item.targetsManual[getKPIIndex(item)].playoutActuals }}</span>
                                            </div>
                                        </small>
                                    </template>

                                    <!-- Performance -->
                                    <template v-slot:item.performance="{ item }">
                                        <div>
                                            <small v-if="type.includes('graph')">{{ calcPerformance(item.targetsManual[getKPIIndex(item)].graphBooked, item.targetsManual[getKPIIndex(item)].graphActuals) }}</small>
                                            <small v-else-if="type.includes('tracking')">{{ calcPerformance(item.targetsManual[getKPIIndex(item)].booked, item.targetsManual[getKPIIndex(item)].deliver) }}</small>
                                            <small v-else-if="type.includes('playout')">{{ calcPerformance(item.targetsManual[getKPIIndex(item)].graphBooked, item.targetsManual[getKPIIndex(item)].playoutActuals) }}</small>
                                            <small>%</small>
                                        </div>
                                    </template>
                                    <!-- Aggregate -->
                                    <template v-slot:item.live="{item}">
                                        <div class="d-flex justify-center" v-if="item.cbsid != 0">
                                            <v-checkbox
                                                :disabled="!hasAnalyticsEditPermission || !type.includes('tracking')"
                                                v-model="item.targetsManual[getKPIIndex(item)].targetFullRange"
                                                color="primary"
                                                hide-details
                                                class="ma-0 pa-0"
                                                @change="updateTarget(item)"
                                            ></v-checkbox>
                                        </div>
                                    </template>
                                   <!-- Comments -->
                                    <template v-slot:item.comment="{ item }">
                                        <div v-if="item.cbsid != 0">
                                            <v-btn
                                                :color="hasFormatNotes(item) ? 'primary' : 'grey'" 
                                                id="no-background-hover" 
                                                icon
                                                @click="showCommentDialog(item)"
                                            >
                                                <v-icon>mdi-comment</v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                    <!-- KPI -->
                                    <template v-slot:item.kpi="{item}">
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- comment dialog -->
        <v-dialog 
            v-model="showCommDialog" 
            max-width="500px"
            v-if="selectedItem"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <v-card v-if="selectedItem.note" class="
                        d-flex 
                        justify-center 
                        align-center 
                        flex-column 
                        text-center
                        mb-4
                        pa-4
                        border
                    ">
                        <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                        ">
                            <v-col cols="4">
                                <small>Format: {{ findScreenNamebyCBSID(selectedItem.note.campaignBurstScreenId) }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>Mode: {{ selectedItem.note.dashboardMode }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>KPI: {{ selectedItem.note.dashboardKPI }}</small>
                            </v-col>
                        </v-row>
                        {{ selectedItem.note.note }}
                    </v-card>
                    <v-textarea
                        v-if="hasAnalyticsEditPermission"
                        v-model="comment"
                        label="Note"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        class="mt-4"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!hasAnalyticsEditPermission" color="primary" text @click="addNote(selectedItem)">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Comment dialog -->
        <v-dialog 
            v-model="showCommDialogMO" 
            max-width="500px"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <div v-if="selectedMoNotes.length > 0">
                        <v-card v-for="note in selectedMoNotes" :key="note.id"
                        class="
                            d-flex 
                            justify-center 
                            align-center 
                            flex-column 
                            text-center
                            mb-4
                            pa-4
                            border
                        ">
                            <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                            ">
                                <v-col cols="4">
                                    <small>Format: {{ findScreenNamebyCBSID(note.campaignBurstScreenId) }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>Mode: {{ note.dashboardMode }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>KPI: {{ note.dashboardKPI }}</small>
                                </v-col>
                            </v-row>
                           <span>{{ note.note }}</span>
                        </v-card>
                    </div>
                    <div v-else>
                        No notes
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Issue modal component -->
        <IssueModal
            v-if="openIssueModal"
            :selectedIssue="selectedIssue"
            :selectedBurst="selBurst ? selBurst : selectedBurst"
            :selectedCampaign="campaign"
            :issueIndex="issueIndex"
            :openIssueModal="openIssueModal"
        />
    </div>
</template>

<script>
// External libraries
import moment from 'moment'
// Controllers
import RouteController from '@/services/controllers/Route'
import ReportingController from '@/services/controllers/Reporting'
/** Components/Modals */
import IssueModal from '@/components/campaigns/modals/ProgressIssueModal'
import all from 'gsap/all'
export default {
    components: {
        IssueModal
    },
    name: 'Report',
    props: {
        burst: {
            type: Object,
            required: true
        },
        campaign: {
            type: Object,
            required: true
        },
        selectedBurst: {
            type: Object,
            default: () => null
        },
        type: {
            type: String,
            default: 'report'
        },
        data: {
            type: Array,
            required: true
        }
    },
    provide: function () {
        return {
        closeIssue: this.closeIssue,
        }
    },
    data() {
        return {
            headers: [
                { text: 'Media Owner', value: 'name', width: '140px', align: 'left', sortable: false },
                { text: 'Formats', value: 'format', width: '100px', align: 'center', sortable: false },
                { text: 'Dates', value: 'date', width: '100px', align: 'center', sortable: false },
                { text: 'KPI', value: 'kpi', width: '200px', align: 'center', sortable: false },
                { text: 'Booked', value: 'booked', width: '100px', align: 'center', sortable: false },
                { text: 'Aggregate', value: 'live', width: '100px', align: 'center', sortable: false },
                { text: this.type.includes('graph') ? 'Actuals' : 'Delivered', value: 'deliver', width: '100px', align: 'center', sortable: false },
                { text: 'Performance %', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: 'Comments', value: 'comment', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            headersExpanded: [
                { text: '', value: 'placeholder', width: '0px', align: 'left', sortable: false },
                { text: '', value: 'name', width: '210px', align: 'left', sortable: false },
                { text: '', value: 'date', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'kpi', width: '200px', align: 'center', sortable: false },
                { text: '', value: 'booked', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'live', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'deliver', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'comment', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            expanded: [],
            burtCopy: {},
            showCommDialog: false,
            showCommDialogMO: false,
            selectedItem: null,
            comment: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            dashboardTargets: [],
            kpiList: ['Plays', 'Impacts/Impressions'],
            notesData: [],
            datePicker: null,
            displayDate: null,
            targetFullRange: false,
            selectedMoNotes: [],
            openIssueModal: false,
            selBurst: null,
            issueIndex: null,
            mapped: false,
            dashboardTargets: null,
        } 
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
    watch: {
        expanded: function(val, oldVal) {
            // Call the function after 100 ms so the DOM can update
            setTimeout(() => {
                this.addPasteEventListener()
            }, 100)
        },
        data: function(val, oldVal) {
            {
                if(this.mapped) return
                if(this.data.length === 0) return
                // clone the data
                this.dashboardTargets = JSON.parse(JSON.stringify(this.data))
                this.mapDashboardTargets()
                this.mapped = true;
            }
        }
    },
    beforeMount() {
        // Deep copy the burst prop
        this.burstCopy = JSON.parse(JSON.stringify(this.burst)) 
        this.$nextTick() 
    },
    computed: {
    

        addCommas() {
            return (number) => {
            const num = parseFloat(number).toFixed(2);
            // Convert the number to a string
            const numberString = num.toString();
            // Split the number into integer and decimal parts
            const [integerPart, decimalPart] = numberString.split('.');
            // Add commas to the integer part
            const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            // Combine the integer part with the decimal part (if any)
            const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
            // Return the result
            
            return result;
            }
        },
        removeCommas(){
            return (number) => {
                return number.replace(/,/g, '')
            }
        },
        allowedDates() {
            return (format) => {
                return (val) => {
                    let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                    let startDate = moment(cbsDates.startDate);
                    let endDate = moment(cbsDates.endDate);
                    let now = moment(val)
                    return now.isAfter(startDate) && now.isBefore(endDate) || now.isSame(startDate) || now.isSame(endDate);
                };
            };
        },
        getDisplayDate() {
            return (format) => {
                let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                let startDate = format.targetsManual[this.getKPIIndex(format)].startDate || cbsDates.startDate;
                return startDate
            };
        },
        datesMatchBurst() {
            return (burst) => {
                // ensure all dates match within the burst
                let allMatch = true;
                burst.screen.forEach(cbs => {
                    if(!this.datesMatch(cbs))
                        allMatch = false
                })
                return allMatch
            };
        },
        datesMatch() {
            return (format) => {
                    if (format.targetsManual === undefined) return false
                    let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                    let startDate = moment(cbsDates.startDate);
                    let endDate = moment(cbsDates.endDate);
                    let kindex = this.getKPIIndex(format)
                    let targetStartDate = moment(format.targetsManual[kindex].startDate)
                    let targetEndDate = moment(format.targetsManual[kindex].endDate)
                    return (
                        targetStartDate.diff(startDate,'second') === 0 && targetEndDate.diff(endDate,'second') === 0
                    );
            };
        },
        getCampaignBurstScreenDates() {
            return (campaignBurstScreenId) => {
                let cbs = this.burstCopy.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId) // get CampaignBurstScreen
                if(cbs === undefined) 
                {
                    cbs = {} // this means data has come from playout, but is not in the campaignBurstScreens
                }
                let startDate = cbs.startDate || this.burstCopy.startDate || this.campaign.startDate;
                let endDate = cbs.endDate || this.burstCopy.endDate || this.campaign.endDate;
                return { startDate, endDate }
            }
        },
        getCampaignBurstDates() {
            return () => {
                let startDate = this.burst.startDate || this.campaign.startDate;
                let endDate = this.burst.endDate || this.campaign.endDate;
                return { startDate, endDate }
            }
        },
        getEarliestDate() {
            return (item) => {
                // Iterate through item.screen and append each targetsManual[item.kpi].startDate to an array
                let dates = []
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined && screen.targetsManual[this.getKPIIndex(screen)].startDate)
                        dates.push(screen.targetsManual[this.getKPIIndex(screen)].startDate)
                })
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                let earliestDate = dates.reduce((a, b) => {
                    return a < b ? a : b
                })
                return earliestDate
            };
        },
        getLatestDate(){
            return (item) => {
                // Iterate through item.screen and append each targetsManual[item.kpi].startDate to an array
                let dates = []
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined && screen.targetsManual[this.getKPIIndex(screen)].endDate)
                        dates.push(screen.targetsManual[this.getKPIIndex(screen)].endDate)
                })
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                let latestDate = dates.reduce((a, b) => {
                    return a > b ? a : b
                })
                return latestDate
            }
        },
        getMediaOwnersListFromTargets() {
            // get the media owners from the targets
            let mediaOwners = []
            if(this.dashboardTargets === null) return []
            let campaignBurstScreens = this.dashboardTargets.map(target => target.campaignBurstScreen)
            campaignBurstScreens.forEach(screen => {
                let shouldAdd = screen.id > 0 || (this.hasPlayoutVisibleOnTable || this.type.includes('playout'));
                let mo = screen.screen.mediaOwner
                let isMediaOwnerInList = mediaOwners.find(mediaOwner => mediaOwner.id === mo.id)
                if (!isMediaOwnerInList && shouldAdd) mediaOwners.push(mo)
                // get all the screens for the media owner
                // get all the distinct screens for the media owner
                let screens = campaignBurstScreens.filter(cbs => cbs.screen.mediaOwner.id === mo.id).map(cbs => cbs.screen).filter((screen, index, self) => self.findIndex(s => s.id === screen.id) === index)
                mo.screen = screens
            })

            mediaOwners = this.getMediaOwners(mediaOwners, campaignBurstScreens)

            return mediaOwners
        },
        hasPlayoutVisibleOnTable() {
            return this.$store.state.Permissions.playoutVisibleOnTable
        },
        getMediaOwnersList() {
            let moList = this.getMediaOwnersListFromTargets
            // remove media owners with no screens
            moList = moList.filter(mo => mo.screen.length > 0)
            return moList
                
            let mediaOwners = [];
            this.burstCopy.campaignBurstScreens.forEach((screen) => {
                // Check if media owner is already in the list
                var shouldAdd = screen.id > 0 || (this.hasPlayoutVisibleOnTable || this.type.includes('playout'));
                var mo = screen.screen.mo || screen.screen.mediaOwner;
                let isMediaOwnerInList = mediaOwners.find((mediaOwner) => Object.entries(mediaOwner).toString() === Object.entries(mo).toString());
                if (!isMediaOwnerInList && shouldAdd) mediaOwners.push(mo)
            });
            mediaOwners = this.getMediaOwners(mediaOwners, this.burstCopy.campaignBurstScreens)
            
            // For every screen in the mediaOwners, find the dashboardTarget that matches the screen.id
            return mediaOwners;
        },

        calcPerformance() {
            return (booked, delivered) => {
                let perf = parseFloat(this.removeCommas(delivered)) / parseFloat(this.removeCommas(booked)) * 100;
                    // Check if perf is not in range of 1-100 or if it is NaN or Infinity
                    if (isNaN(perf) || !isFinite(perf) || perf < 1) return 0;
                    return perf.toFixed(2);
            };
        },
        calculateBookedTotal(){
            return (item) => {
                let total = 0
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined) {
                        if(this.type.includes('graph'))
                        {
                            total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].graphBooked))
                        } 
                        else if(this.type.includes('tracking'))
                        {
                            total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].booked))
                        }
                        else if(this.type.includes('playout'))
                        {
                            total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].playoutBooked))
                        }
                    }
                })
                return this.addCommas(total)
            }
        },
        calculateDeliverTotal(){
            return (item) => {
                let total = 0
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined) total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].deliver))
                })
                return this.addCommas(total)
            }
        },
        calculcateActualsTotal() {
            return (item) => {
                let total = 0
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined) total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].graphActuals))
                })
                return this.addCommas(total)
            }
        },
        calculatePlayoutTotal(){
            return (item) => {
                let total = 0
                item.screen.forEach(screen => {
                    if(screen.targetsManual !== undefined) total += parseFloat(this.removeCommas(screen.targetsManual[this.getKPIIndex(screen)].playoutActuals))
                })
                return this.addCommas(total)
            }
        },
        getMoFormatActual(){
            return async (item) => {
                // FInd the media owner th
                return 0
            }
        },
        // Computed function to find index of this.selectedKPI in the kpiList
        getKPIIndex(){
            //return this.kpiList.findIndex(kpi => kpi === this.selectedKPI)
            return (item) => {
                if(this.kpiList.findIndex(kpi => kpi === item.kpi) === -1) return 0
                return this.kpiList.findIndex(kpi => kpi === item.kpi)
            }
        },
        findScreenNamebyCBSID(){
            return (cbsid) => {
                let screen = this.burstCopy.campaignBurstScreens.find(screen => screen.id === cbsid)
                return screen.screen.name
            }
        },
        // Function that gets a media owner object as a prop and return true or false depending if there are screens in mediaOwner.screen that have notes
        hasMoNotes() {
            return (mediaOwner) => {
            let hasNotes = false
            mediaOwner.screen.forEach(screen => {
                const isNote = this.hasFormatNotes(screen)
                if (isNote) {
                    hasNotes = true
                    return
                }

            })
            return hasNotes
            }
        },
        hasFormatNotes() {
            return (format) => {
                let hasNotes = false
                let isNotes = this.notesData.filter(note => note.campaignBurstScreenId === format.cbsid)
                // Filterthe notesData where 
                let mode = this.type.includes("graph") === 2 ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
                isNotes = isNotes.filter(note => note.dashboardMode === mode)
                // Check if the format.kpi is Impressions, Plays or Impacts/Impressions
                let kpi = ''
                if (format.kpi === 'Impressions') {
                    kpi = 'hours';
                } else if (format.kpi === 'Plays') {
                    kpi = 'plays';
                } else if (format.kpi === 'Impacts/Impressions') {
                    kpi = 'impacts';
                }
                const isNote = isNotes.find(note => note.dashboardKPI === kpi)
                if (isNote) {
                    hasNotes = true
                    if(isNote.note === '') return hasNotes = false
                }
                return hasNotes
            }
        },
        // Function to check is user has analyticsEdit permission
       hasAnalyticsEditPermission() {
        return this.$store.state.Permissions.analyticsEdit
      },
    },
    async created(){
        // Get the dashboardTargets for Tracking & Verified
        if(this.type.includes('tracking') || this.type.includes('graph') || this.type.includes('playout'))
        {
            //await this.mapDashboardTargets()
        }

        this.getMoFormatActual()

        
        await RouteController.getDashboardNotes(this.burst.id).then(response => {
            this.notesData = [...response.data]
        })
        // Global Functions to update the campaign after modifing a milestone
        this.$root.$on('updateOrDeleteCampaign', (action, item) => {{
            if (this.burstCopy.id === item.id) {
                if (action === 'update') {
                    this.burstCopy.workflowStatus = item;
                } else if (action === 'delete') {
                    this.burstCopy.workflowStatus = { id: 0 }
                }
                this.$nextTick()
            }
        }})
    },
    methods: {
        hasValidCBS(item) {
            let hasNotes = item.screen.find(screen => screen.cbsid !== 0) !== undefined
            return hasNotes
        },

        getMediaOwners(mediaOwners, campaignBurstScreens) {
            // Iterate through this.burstCopy and add the screens to the mediaOwners if the screen.screen.mediaOwner is in the mediaOwners list
            campaignBurstScreens.forEach((screen, index) => {
                var shouldAdd = screen.id > 0 || (this.hasPlayoutVisibleOnTable || this.type.includes('playout'));
                var mo = screen.screen.mo || screen.screen.mediaOwner;
                let mediaOwner = mediaOwners.find((owner) => owner.id === mo.id);
                if (mediaOwner && shouldAdd) {
                    var moScreen = mediaOwner.s || mediaOwner.screen;
                    if (!moScreen) {
                        mediaOwner.screen = [];
                    }
                    screen.screen.cbsid = campaignBurstScreens[index].id;
                    screen.screen.kpi = this.kpiList[0]
                    // find the dashboardTarget.campaignBurstScreenId that matches the screen.id
                    // only push screen.screen if the screen is not in the mediaOwner.screen
                    if (!mediaOwner.screen.find((s) => s.id === screen.screen.id)) {
                        mediaOwner.screen.push(screen.screen);
                    }
                }
            });

            return mediaOwners;
        },


        openDatePicker(format) {
            this.displayDate = this.getDisplayDate(format);
            let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
            this.datePicker = [format.targetsManual[this.getKPIIndex(format)].startDate || cbsDates.startDate, format.targetsManual[this.getKPIIndex(format)].endDate || cbsDates.endDate];
        },

        updatePickerDate(newDate) {
        // This method updates the initialDate when the user changes the month
            this.displayDate = newDate;
        },

        openIssue(index, issue, burst){
            this.selectedIssue = issue
            this.selBurst = burst
            this.issueIndex = index
            this.openIssueModal = true
        },
        closeIssue(){
            this.openIssueModal = false
        },
        //TODO: Add safeguard workflowStatus.id = 0 
        filterColor(status) {
            switch(status) {
                case 1:
                    return '#499F68'
                case 2:
                    return 'grey'
                case 3:
                    return '#F464A9'
                case 4:
                    return '#4AA6D5'
                case 0:
                    return 'transparent'
                default:
                    return 'transparent'
            }  
        },
        getProgress(burst){
            let totalTasks = 0
            let completedTasks = 0
            if(burst){
                if(burst.workflowStatus){
                if(burst.workflowStatus.categories.length > 0){
                    burst.workflowStatus.categories.forEach(category => {
                    category.dIs.forEach(dIssue => {
                        dIssue.tasks.forEach(task => {
                        totalTasks++
                        if(task.s === 1)
                            completedTasks++
                        })
                    })
                    })
                }
                }
            }
            if(totalTasks === 0) return 0
            else return Math.round((completedTasks / totalTasks) * 100)
        },
        closeDialog() {
            this.showCommDialog = false
            this.showCommDialogMO = false
            this.selectedItem = null
            this.selectedMoNotes = []
            this.comment = ''
            this.$nextTick()
        },
        addPasteEventListener() {
            const editableElements = document.querySelectorAll('[contenteditable]');
            editableElements.forEach((element) => {
                element.addEventListener('paste', this.handlePaste);
            });
        },
        handlePaste(event) {
            event.preventDefault();
            // clear the innerText of the element . editable
            event.target.innerText = '';
            var text = event.clipboardData.getData('text/plain')
            document.execCommand('insertText', false, text)
        },
        async resetDates(format, e, type) {
            let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
            // set the datepicker to the allowed dates
            this.datePicker = [moment(cbsDates.startDate).format('YYYY-MM-DD'), moment(cbsDates.endDate).format('YYYY-MM-DD')]
            await this.updateTarget(format)
        },

        async updateTarget(item, e, type){
            let target = this.dashboardTargets.find(target => target.campaignBurstScreenId === item.cbsid)
            // Fornat input text if exists
            if(e){
                e.preventDefault()
                // Check if the input text is empty
                e.target.innerText = e.target.innerText.replace('\n', '')
                if (e.target.innerText === '' || e.target.innerText === 'NaN') {
                    e.target.innerText = 0;
                }

                // Remove commas from the number string
                const strippedNumberString = this.removeCommas(e.target.innerText);
                e.target.innerText = strippedNumberString
                // Convert the stripped number string to a float number
                var floatNumber = strippedNumberString
                floatNumber = this.addCommas(floatNumber);
                //e.target.innerText = floatNumber
                // Update the targetsManual array
                item.booked = type === 'booked' ?  floatNumber : item.targetsManual[this.getKPIIndex(item)].booked;
                item.deliver = type === 'deliver' ?  floatNumber : item.targetsManual[this.getKPIIndex(item)].deliver;
                item.booked = this.addCommas(item.booked)
                item.deliver = this.addCommas(item.deliver)
            }

            // switcheroo dates if the start date is after the end date
            let startDate = this.datePicker ? this.datePicker[0] : item.targetsManual[this.getKPIIndex(item)].startDate
            let endDate = this.datePicker ? this.datePicker[1] : item.targetsManual[this.getKPIIndex(item)].endDate
            if (moment(startDate).isAfter(endDate)) {
                let temp = startDate
                startDate = endDate
                endDate = temp
            }

            const newScreen = {
                campaignBurstScreenId: item.cbsid,
                mode: 'manual',
                routeDemographicId: target.routeDemographicId,
                routeGranularityAudienceId: target.routeGranularityAudienceId,
                demographicCustom: target.demographicCustom,
                routeVersionId: target.routeVersionId,
                target: type === 'booked' ? floatNumber : this.removeCommas(item.targetsManual[this.getKPIIndex(item)].booked),
                actual: type === 'deliver' ? floatNumber : this.removeCommas(item.targetsManual[this.getKPIIndex(item)].deliver),
                targetFullRange: item.targetsManual[this.getKPIIndex(item)].targetFullRange,
                startDate: startDate,
                endDate: endDate
            };
            // Set the type of the newScreen object
            if (item.kpi === 'Hours') {
                newScreen.type = 'hours';
            } else if (item.kpi === 'Plays') {
                newScreen.type = 'plays';
            } else if (item.kpi === 'Impacts/Impressions') {
                newScreen.type = 'impacts';
            }
            // Update the dashboardTargets
            await RouteController.updateDashboardTargets(newScreen).then(response => {
                if (response.data) {
                    // Find the index of the item in this.getMediaOwnersList
                    let index = this.getMediaOwnersList.findIndex(mo => mo.id === item.moId);
                    let screenIndex = this.getMediaOwnersList[index].screen.findIndex(screen => screen.cbsid === item.cbsid);
                    
                    // Update the targetsManual array
                    let target = this.getMediaOwnersList[index].screen[screenIndex].targetsManual[this.getKPIIndex(item)];
                    if(this.datePicker){
                        target.startDate = this.datePicker[0]
                        target.endDate = this.datePicker[1]
                        this.datePicker = null
                    }
                    if (type === 'booked') {
                        target.booked = this.addCommas(e.target.innerText);
                    } else {
                        target.booked = item.targetsManual[this.getKPIIndex(item)].booked
                    }

                    if (type === 'deliver') {
                        target.deliver = this.addCommas(e.target.innerText);
                    } else {
                        target.deliver = item.targetsManual[this.getKPIIndex(item)].deliver
                    }
                    //if(type === 'deliver' || type === 'booked') e.target.innerText = e.target.innerText
                    // Update the targetsManual array
                    this.getMediaOwnersList[index].screen[screenIndex].targetsManual.splice(
                        this.getKPIIndex(item),
                        1,
                        target
                    );
                    // Update the targetsManual array,using changeKPI to update the data (otherwise the table won't update)
                    this.$nextTick()
                    this.changeKPI(item, 'upd')
                    let expandCopy = [...this.expanded]
                    this.expanded = []
                    this.$nextTick()
                    setTimeout(() => {
                        this.expanded = [...expandCopy]
                    }, 100)

                    // Display snackbar depending on the type of the target changed
                    if(type === 'booked') this.$root.$emit('snackbarSuccess', 'Booked updated successfully');
                    else if(type === 'deliver') this.$root.$emit('snackbarSuccess', 'Delivered updated successfully');
                    else if(this.datePicker) this.$root.$emit('snackbarSuccess', 'Dates updated successfully');
                    else this.$root.$emit('snackbarSuccess', 'Full range ' + (item.targetsManual[this.getKPIIndex(item)].targetFullRange ? 'enabled' : 'disabled') + ' successfully');
                }
            });
        }, 
        async changeKPI(item, type){
            if(type === 'upd') {// change th e kpi value intentionally and then back to it to update table after updating the target
                let oldKPI = item.kpi
                if(oldKPI === 'Impressions') item.kpi = 'Plays'
                else if(oldKPI === 'Plays') item.kpi = 'Impacts/Impressions'
                else if(oldKPI === 'Impacts/Impressions') item.kpi = 'Plays'
                this.$nextTick()
                item.kpi = oldKPI
            } else {
            // find index of item in this.getMediaOwnersList
                let index = this.getMediaOwnersList.findIndex(mo => mo.id === item.id)
                this.getMediaOwnersList[index].screen.forEach(screen => {
                    screen.kpi = item.kpi
                let expandCopy = [...this.expanded]
                this.expanded = []
                this.$nextTick()
                this.expanded = [...expandCopy]
                this.$nextTick()
            })
        }
        },
        async mapDashboardTargets() {
        {
            //await RouteController.getDashboardTargets(this.burst.id).then(response => 
            {
                //this.dashboardTargets = [...response.data]
                // get the dashboardStrucures from the data object where the campaignBurstId = this.burst.id
                let dashboardTargetsFull = this.dashboardTargets.filter(target => target.campaignBurstId === this.burst.id)
                if(dashboardTargetsFull === undefined) return
                this.dashboardTargets = dashboardTargetsFull[0].dashboardStructures
                // FInd the dashboardTarget that matches this.getMediaOwnersList screen.id (keep it mind that every media owner has an array of screens
                this.getMediaOwnersListFromTargets.forEach(mediaOwner => {
                    // Assign impressions as first value for mo

                    mediaOwner.kpi = this.kpiList[0]

                    // splice screens where cbsid = 0
                    if(this.type.includes('tracking') || this.type.includes('graph'))
                        mediaOwner.screen = mediaOwner.screen.filter(screen => screen.cbsid !== 0)

                    mediaOwner.screen.forEach(screen => {
                        let target = this.dashboardTargets.find(target => target.campaignBurstScreen.screen.id === screen.id)
                        if(target){
                            // get the date range based on the type
                            let mode = 'auto'
                            if(this.type.includes('tracking'))
                            {
                                mode = 'manual'
                            }
                            else if(this.type.includes('graph'))
                            {
                                mode = 'auto'
                            }
                            else if(this.type.includes('playout'))
                            {
                                mode = 'playout'
                            }
                            // get date ranges, and the first entry of each date range
                            let dateRangesImpactsActual = target.dateRanges.find(dateRange => dateRange.mode === mode && dateRange.kpi === 'impacts' && dateRange.designation === 'actual')
                            let dateRangesPlaysActual = target.dateRanges.find(dateRange => dateRange.mode === mode && dateRange.kpi === 'plays' && dateRange.designation === 'actual')
                            if(this.type.includes('playout'))
                            {
                                mode = 'auto' // We do this because we don't have playout targets yet
                            }
                            let dateRangesImpactsTarget = target.dateRanges.find(dateRange => dateRange.mode === mode && dateRange.kpi === 'impacts' && dateRange.designation === 'target')
                            let dateRangesPlaysTarget = target.dateRanges.find(dateRange => dateRange.mode === mode && dateRange.kpi === 'plays' && dateRange.designation === 'target')
                            screen.moId = mediaOwner.id
                            screen.kpi = this.kpiList[0]
                            screen.targetsManual = [
                                /*
                                {
                                    name: 'Impressions',
                                    booked: target.targetHoursManual,
                                    deliver: target.actualHoursManual,
                                    startDate: impressionsDateRange.startDate,
                                    endDate: impressionsDateRange.endDate,
                                }, */
                                {
                                    name: 'Plays',
                                    // Booked
                                    booked: this.addCommas(target.targetPlaysManual),
                                    deliver: this.addCommas(target.actualPlaysManual),
                                    // Verified tab
                                    graphBooked: this.addCommas(target.targetPlaysAuto),
                                    graphActuals: this.addCommas(target.actualPlays),
                                    // Playout tab
                                    playoutBooked: this.addCommas(target.targetPlaysAuto),
                                    playoutActuals: this.addCommas(target.actualPlaysPlayout),

                                    startDate: dateRangesPlaysActual ? dateRangesPlaysActual.startDate : null,
                                    endDate: dateRangesPlaysActual ? dateRangesPlaysActual.endDate : null,
                                    targetFullRange: dateRangesImpactsTarget ? dateRangesImpactsTarget.targetFullRange : null,
                                },
                                {
                                    name: 'Impacts/Impressions',
                                    // Booked
                                    booked: this.addCommas(target.targetImpactsManual),
                                    deliver: this.addCommas(target.actualImpactsManual),
                                    // Verified tab 
                                    graphBooked: this.addCommas(target.targetImpactsAuto),
                                    graphActuals: this.addCommas(target.actualImpacts),
                                    // Playout tab
                                    playoutBooked: this.addCommas(target.targetImpactsAuto),
                                    playoutActuals: this.addCommas(target.actualImpactsPlayout),

                                    startDate: dateRangesImpactsActual ? dateRangesImpactsActual.startDate : null,
                                    endDate: dateRangesImpactsActual ? dateRangesImpactsActual.endDate : null,
                                    targetFullRange: dateRangesPlaysTarget ? dateRangesPlaysTarget.targetFullRange : null,
                                },
                            ]                            
                        }
                    })
                })

                // IF thereas a media Owner with name includes JCD, change the kpi to 'Impacts/Impressions'
                this.getMediaOwnersList.forEach(mediaOwner => {
                    var name = mediaOwner.n || mediaOwner.name;
                    if(name.includes('JCD')) {
                        mediaOwner.kpi = 'Impacts/Impressions'
                        mediaOwner.screen.forEach(screen => {
                            screen.kpi = 'Impacts/Impressions'
                            /* swap kpi names 
                            let temp = screen.targetsManual[0]
                            screen.targetsManual[0] = screen.targetsManual[1]
                            screen.targetsManual[1] = temp */

                        })
                    }
                })
                // Force the table to re-render
                this.$nextTick()
            }
        }
        },
        async addNote(item) {
            try {
                const obj = {
                    campaignBurstScreenId: this.selectedItem.cbsid,
                    mode: this.type.includes("graph") === 2 ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout"),
                    note: this.comment,
                };

                if (item.kpi === 'Plays') {
                    obj.type = 'plays';
                } else if (item.kpi === 'Impacts/Impressions') {
                    obj.type = 'impacts';
                }

                const res = await RouteController.setDashboardNotes(obj);

                const formatObj = {
                    campaignBurstScreenId: obj.campaignBurstScreenId,
                    dashboardKPI: obj.type,
                    note: obj.note,
                    dashboardMode: obj.mode,
                };

                let index = this.notesData.findIndex(note => note.campaignBurstScreenId === obj.campaignBurstScreenId && note.dashboardKPI === obj.type);

                if (index !== -1) {
                    // Update existing note or replace it
                    this.$set(this.notesData, index, formatObj);
                } else {
                    // Push new note if not found
                    this.notesData.push(formatObj);
                }

                // Update selectedItem and item notes using Vue.set for reactivity
                this.$set(this.selectedItem, 'note', formatObj);
                this.$set(item, 'note', formatObj);

                this.$root.$emit('snackbarSuccess', "Note added successfully");
            } catch (err) {
                console.log(err);
            } finally {
                this.message = '';

                // Trigger reactivity by forcing the component to re-render
                this.$forceUpdate();
            }
        },

        showCommentDialog(item){
            // Data pre-processing
            this.selectedItem = item
            // check if selectedItem has one or more notes, if so, assign to this.selectedItem.notes array
            let notes = this.notesData.filter(note => note.campaignBurstScreenId === item.cbsid)
            // If current tab === 2 (verified) then filter notes by mode = auto
            let mode = this.type.includes("graph") === 2 ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
            notes = notes.filter(note => note.dashboardMode === mode)
            // Find the note that has type = item.kpi
            let kpi = null
            if (item.kpi === 'Impressions') {
                kpi = 'hours';
            } else if (item.kpi === 'Plays') {
                kpi = 'plays';
            } else if (item.kpi === 'Impacts/Impressions') {
                kpi = 'impacts';
            }
            let note = notes.find(note => note.dashboardKPI === kpi)
            if(note) this.selectedItem.note = note
            else this.selectedItem.note = null
            if(note) this.comment = note.note
            // Modal control
            this.showCommDialog = true
            this.$nextTick()
        },
        showCommentDialogAll(mediaOwner){
            this.showCommDialogMO = true
            // Get a list of notes that have the same campaignBurstScreenId as the mediaOwner.screen.cbsid (where screen is an array)
            let notes = this.notesData.filter(note => mediaOwner.screen.find(screen => screen.cbsid === note.campaignBurstScreenId))
            // If current tab === 2 (verified) then filter notes by mode = auto7
            let mode = this.type.includes("graph") === 2 ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
            notes = notes.filter(note => note.dashboardMode === mode)
            // Filter notes by kpi
            let kpi = ''
            if(mediaOwner.kpi === 'Impressions') kpi = 'hours'
            else if(mediaOwner.kpi === 'Plays') kpi = 'plays'
            else if(mediaOwner.kpi === 'Impacts/Impressions') kpi = 'impacts'
            notes = notes.filter(note => note.dashboardKPI === kpi)
            this.selectedMoNotes = notes
        },
    }
}
</script>
<style scoped>
*::v-deep .v-data-table__expanded .v-data-table-header th {
  height: 0px !important;
}
*::v-deep td .no-margin {
  padding: 0 !important;
}
</style>
<style lang="scss">
#no-background-hover {
   background-color: transparent !important;
}
</style>
<style scoped>
.v-card {
    margin-top:0;
    margin-bottom:0;
}
.together{
    min-width: 0
  }
.v-expansion-panel-header {
    padding: 0px 24px 0px 0px;
}
.subIssues {
    width: 100%;
}
.subIssue {
    cursor: pointer;
    /* add transition speed 300 */
    transition: 0.3s;
    height: 25px;
}
.subIssue:first-child,
.roundCorners  {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.subIssue:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subIssue:not(:last-child) {
    border-right: 0.1px solid black;
}
.subIssue:hover {
    /* add transition speed 300 */
    transition: 0.3s;
    background-color: black;
}
.customSpacing:not(:first-child) {
    /* If not first item, add margin top 1 em */
    margin-top: 1.5em;
}
.oneIssue {
    border-radius: 10px;
}
.datesMismatch {
    color: #ff9029;
}
</style>