<template>
    <div class="ma-0 mx-2" id="graph-full-container">

        <!-- ROUTE LOGO + VERSION -->
        <v-row>
            <!-- Route version -->
            <v-col
                cols="12"
                class="d-flex align-end mt-n2"
                :style="{ position: `relative` }"
            >
                <div 
                    class="mb-2"
                    :style="{ width: `fit-content` }"
                >
                    <v-img
                        src="route.png"
                        contain
                        width="140px"
                    />
                </div>

                <span
                    class="ml-2 pb-1 primary--text"
                    :style="{ 
                        width: `100%`,
                        fontSize: `12px`
                    }"
                >{{ currentRouteVersion }}</span>
            </v-col>
        </v-row>

        <!-- Route logo & Chart filters -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            class="ma-0 pa-0 px-0 mt-5 mb-2"
        >
            <!-- Burst filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartView"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="Filter Burst"
                    :items="burstOptions"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

            <!-- Media Owner filter -->
            <v-col 
                v-if="mediaOwnerOptions"
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartMediaOwners"
                    :items="mediaOwnerOptions"
                    item-text="name"
                    item-value="id"
                    label="Filter Media Owners"
                    multiple
                    clearable
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">
                            <span>{{ (hideText(item.name , 7, true)) }}</span>
                        </span>

                        <span
                            v-if="index === 1"
                            class="ml-1 grey--text text-caption"
                        >
                            (+{{ localChartMediaOwners.length - 1 }})
                        </span>
                    </template>
                </v-select>
            </v-col>

            <!-- Screen filter -->
            <v-col
                v-if="screenOptions"
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-if="localChartMediaOwners && localChartMediaOwners.length > 0"
                    v-model="localChartFormats"
                    :items="screenOptions"
                    item-text="name"
                    item-value="id"
                    label="Filter Formats"
                    multiple
                    chips
                    clearable
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">
                            <span>{{ (hideText(item.name , 7, true)) }}</span>
                        </span>

                        <span
                            v-if="index === 1"
                            class="ml-1 grey--text text-caption"
                        >
                            (+{{ localChartFormats.length - 1 }})
                        </span>
                    </template>
                </v-select>
            </v-col>

            <!-- Plaster for responsiveness -->
            <v-spacer />
            <v-col v-if="$vuetify.breakpoint.lgAndDown" cols="2"></v-col>

            <!-- Format filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="chartDataBehavior"
                    :items="[{ label: 'Cumulative Data', value: 'cumulative' }, { label: 'Source Data', value: 'raw' }]"
                    item-text="label"
                    item-value="value"
                    label="Chart Format"
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>
  
            <!-- Type filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartDataType"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="Chart Type"
                    :items="[{ text: 'Auto Data', value: 'auto' }, { text: 'Manual Data', value: 'manual' }]"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

            <!-- KPI filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartMetric"
                    return-object
                    outlined
                    label="Chart KPI"
                    :items="['Impacts', 'Plays', 'Hours']"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

        </v-row>

        <!-- Route Chart & Statistics -->
        <v-card id="graph-only-container"
            v-if="chartData && routeAnalytics"
            class="mt-4"
            :style="{ height: `100%`, width: `100%`, borderRadius: `0` }"
        >
        <div id="graph-thumbnail" style="width: 100%;padding: 20px;display:none">
        <div class="text-h6 font-weight-bold primary--text">
            <h1>{{ campaign.name }}</h1>
        <div class="text-h6 font-weight-bold grey--text">
            <span style="text-transform: uppercase;font-size: larger;">
                {{ 
                    localChartView !== 0 
                    ? burstOptions.find(burst => burst.id === localChartView).name
                    : 'All Bursts'
                }}
            </span>
        </div>

        <div class="text-h6 font-weight-bold grey--text">
            <span style="text-transform: uppercase; font-size: larger;">

                {{ localChartMediaOwners
                    .map(mediaOwnerId => {
                        const mediaOwner = mediaOwnerOptions.find(option => option.id === mediaOwnerId);
                        return mediaOwner ? mediaOwner.name : '';
                    })
                    .sort()
                    .join(', ') }}

            </span>
        </div>

        <div class="text-h6 font-weight-bold grey--text" style="max-width: 50%;">
            <span style="font-style: italic;">

                {{ localChartFormats
                    .map(chartFormatsId => {
                        const chartFormats = screenOptions.find(option => option.id === chartFormatsId);
                        return chartFormats ? chartFormats.name : '';
                    })
                    .sort()
                    .join(', ') }}
            </span>
        </div>
    </div>
        </div>
            <v-row
                :style="{ width: `${100}%` }"
            >
                <!-- Chart -->
                <v-row 
                    v-if="$store.state.Permissions.campaignHistoricalStats && $vuetify.breakpoint.lgAndUp" 
                    class="ma-0 pa-0 px-4"
                    :style=" { width: `${100}%` }"
                >
                    <apexchart
                        :options="chartOptions"
                        :series="sanitizedChartData"
                        height="365"
                        :style=" { width: `${100}%` }"
                    />
                </v-row>

                <!-- Statistics -->
                <v-row
                    v-if="$store.state.Permissions.analytics"
                    class="d-flex justify-center ma-0 pa-0 px-4"
                    style="height: auto;"
                >
                    <!-- KPI -->
                    <v-col cols="1" class="pa-2 my-0">
                        <v-col class="pa-0 ma-0">
                            <span
                                class="font-weight-light"
                                style="font-size: 13px; color: #4aa6d5"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">KPI</span>
                                    </template>
                                    <span>Key Performance Indicator</span>
                                </v-tooltip>
                            </span>
                        </v-col>
                        <div :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'primary--text' : 'grey--text'}`">
                            <!-- Add tooltip top to impact text-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">IMPACTS</span>
                                </template>
                                <span>Unique viewers</span>
                            </v-tooltip>
                        </div>
                        <div :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'primary--text' : 'grey--text'}`">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">PLAYS</span>
                                </template>
                                <span>On Screen Plays</span>
                            </v-tooltip>
                        </div>
                        <div :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'primary--text' : 'grey--text'}`">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">HOURS</span>
                                </template>
                                <span>Cumulative hours</span>
                            </v-tooltip>
                        </div>
                    </v-col>

                    <!-- Target -->
                    <v-col cols="2" class="d-flex justify-center pa-2 my-0">
                        <div>
                            <v-col class="py-0 my-0">
                                <span
                                    class="font-weight-light"
                                    style="font-size: 13px; color: #4aa6d5"
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">Target</span>
                                        </template>
                                        <span>Target Performance</span>
                                    </v-tooltip>
                                </span>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'primary--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.targetImpacts }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'primary--text' : 'grey--text'}`">{{ activeRouteGraphSummaryData.targetPlays }}</span>
                                </span>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'primary--text' : 'ys--text'}`">{{ activeRouteGraphSummaryData.targetHours }}</span>
                                </span>
                            </v-col>
                        </div>
                    </v-col>

                    <!-- Actual -->
                    <v-col cols="2" class="d-flex justify-center pa-2 my-0">
                        <div>
                            <v-col class="py-0 my-0">
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Actuals</span>
                                            </template>
                                            <span>Verified Activity</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon(activeRouteGraphSummaryData['actualPercentage' + chartMetric]).color"
                                    >
                                        {{ routeGraphPerformanceIcon(activeRouteGraphSummaryData['actualPercentage' + chartMetric]).icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ activeRouteGraphSummaryData['actualPercentage' + chartMetric] }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'primary--text' : 'grey--text'}`">{{ activeRouteGraphSummaryData.actualImpacts }}</span>
                                </span>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'primary--text' : 'grey--text'}`">{{ activeRouteGraphSummaryData.actualPlays }}</span>
                                </span>
                            </v-col>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'primary--text' : 'grey--text'}`">{{ activeRouteGraphSummaryData.actualHours }}</span>
                                </span>
                            </v-col>
                        </div>
                    </v-col>

                    <!-- Scope -->
                    <v-col :cols="($vuetify.breakpoint.lgAndDown) ? 3 : 2" class="d-flex justify-center pa-2 my-0" 
                        v-if="$store.state.Permissions.analyticsOutOfScope"
                    >
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Out of Scope</span>
                                            </template>
                                            <span>Less Out of Scope Activity</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon(activeRouteGraphSummaryData['outOfScopePercentage' + chartMetric]).color"
                                    >
                                        {{ routeGraphPerformanceIcon(activeRouteGraphSummaryData['outOfScopePercentage' + chartMetric]).icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ activeRouteGraphSummaryData['outOfScopePercentage' + chartMetric] }}</span>
                                </div>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'red--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.outOfScopeImpacts }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'red--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.outOfScopePlays }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'red--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.outOfScopeHours }}
                                    </span>
                                </span>
                            </v-col>
                        </div>
                    </v-col>

                    <!-- Performance -->
                    <v-col v-if="showPerformance && $store.state.Permissions.analyticsPerformance" cols="2" class="d-flex justify-center pa-2 my-0">
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Performance</span>
                                            </template>
                                            <span>Adjusted Verified Performance</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon(currentPerformancePercent(activeRouteGraphSummaryData)).color"
                                    >
                                        {{ routeGraphPerformanceIcon(currentPerformancePercent(activeRouteGraphSummaryData)).icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ currentPerformancePercent(activeRouteGraphSummaryData) }}</span>
                                </div>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'primary--text' : 'grey--text'}`">
                                        {{ currentPerformance(activeRouteGraphSummaryData, 'Impacts').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'primary--text' : 'grey--text'}`">
                                        {{ currentPerformance(activeRouteGraphSummaryData, 'Plays').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'primary--text' : 'grey--text'}`">
                                        {{ currentPerformance(activeRouteGraphSummaryData, 'Hours').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                    </span>
                                </span>
                            </v-col>
                        </div>
                    </v-col>

                    <!-- Forecast -->
                    <v-col v-if="showForecast" cols="2" class="d-flex justify-center pa-2 my-0">
                        <div>
                            <v-col class="py-0 my-0">
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Forecast</span>
                                            </template>
                                            <span>Projected Performance</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon(activeRouteGraphSummaryData['forecastPercentage' + chartMetric]).color"
                                    >
                                        {{ routeGraphPerformanceIcon(activeRouteGraphSummaryData['forecastPercentage' + chartMetric]).icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ activeRouteGraphSummaryData['forecastPercentage' + chartMetric] }}</span>
                                </div>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'impacts') ? 'primary--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.forecastImpacts }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'plays') ? 'primary--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.forecastPlays }}
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(this.localChartMetric.toLowerCase() === 'hours') ? 'primary--text' : 'grey--text'}`">
                                        {{ activeRouteGraphSummaryData.forecastHours }}
                                    </span>
                                </span>
                            </v-col>
                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.lgAndDown" cols="1"></v-col>

                    <!-- Collected -->
                    <v-col cols="1" class="pa-2 my-0"
                        v-if="$store.state.Permissions.analyticsCollected"
                    >
                        <div>
                            <v-col
                                cols="auto"
                                class="d-flex justify-center py-0 my-0"
                            >
                                <span
                                    class="font-weight-light"
                                    style="font-size: 13px; color: #4aa6d5" 
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">Collected</span>
                                        </template>
                                        <span>% on Activity Reports</span>
                                    </v-tooltip>
                                </span>
                            </v-col>
                            <v-col
                                cols="auto"
                                class="d-flex justify-center py-0 my-3"
                            >
                                <v-progress-circular

                                    v-if="campaignVerifications"
                                    @click="showVerifyRouteCollected = true"
                                    :rotate="360"
                                    :size="56"
                                    :width="5"
                                    :value="activeRouteGraphVerificationsPercentage"
                                    :color="activeRouteGraphVerificationsColor"
                                    :class="($vuetify.breakpoint.xl) ? 'mouse-pointer ma-0 pa-0' : 'mouse-pointer ma-0 pa-0 mt-n5'"
                                    :style="($vuetify.breakpoint.xl) ? 'font-size: 16px' : 'font-size: 12px'"
                                >{{ `${activeRouteGraphVerificationsPercentage}%` }}</v-progress-circular>
                            </v-col>
                        </div>
                    </v-col>
                </v-row>
            </v-row>

            <!-- Verify Route Collected Dialog -->
            <v-dialog
                v-model="showVerifyRouteCollected"
                width="800"
            >
                <verify-route-collected
                    v-if="showVerifyRouteCollected"
                    :campaignId="campaign.id"
                    :campaignVerifications="campaignVerifications"
                />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    // Components
    import VerifyRouteCollected from '@/components/verify/route/Collected'
    
    // Services
    import ReportingController from '@/services/controllers/Reporting'
    import RouteController from '@/services/controllers/Route'

    // Mixins
    import helpersColor from '@/mixins/helpers/color'
    import roundNumber from '@/mixins/helpers/roundNumber'
    import helpersConcatText from '@/mixins/helpers/concatText'

    // External libraries
    import moment from 'moment'
    import apexchart from 'vue-apexcharts'
    import cumulativeSum from "cumulative-sum"
    
    export default {
        mixins: [
            helpersColor,
            roundNumber,
            helpersConcatText
        ],

        props: {
            campaign: {
                required: true
            },

            chartMetric: {
                type: String,
                required: true,
            },

            chartDataType: {
                type: String,
                required: true,
            },

            chartView: {
                required: true,
            },

            chartMediaOwners: {
                required: true,
            },

            chartScreens: {
                required: true,
            },
            selectedFilterBurst: {
                required: true,
            },
        },

        components: {
            VerifyRouteCollected,
            apexchart,
        },

        data() {
            return {
                showForecast: true,
                showPerformance: true,

                calculatedRouteVersion: '',

                chartData: null,
                chartDataBehavior: 'cumulative',

                routeAnalytics: null,

                showVerifyRouteCollected: false,

                campaignVerifications: null,

                // set metrics
                metrics: ['targetData','actualData','forecastData']
            }
        },

        watch: {
            localChartView: function (newVal, oldVal) {
                let results = []

                if(this.chartMediaOwners && this.chartMediaOwners.length > 0) {
                    this.mediaOwnerOptions.forEach(mediaOwnerOption => {
                        this.chartMediaOwners.forEach(selectedMediaOwnerId => {
                            if(mediaOwnerOption.id == selectedMediaOwnerId)
                                results.push(selectedMediaOwnerId)
                        })
                    })

                    const uniqueResults = [...new Set(results)].sort()
                    const compareResults = this.chartMediaOwners.sort()

                    if(JSON.stringify(compareResults) !== JSON.stringify(uniqueResults)) {
                        this.$emit('updateChartMediaOwners', uniqueResults)
                    }
                }
                if(newVal){
                    // Find burst by id
                    if(newVal !== 0) {
                        this.$emit('updateSelectedFilterBurst', this.burstOptions.find(burst => burst.id === newVal))
                    }
                } else {
                    this.$emit('updateSelectedFilterBurst', null)
                }
            },

            localChartMediaOwners: function (newVal, oldVal) {
                let results = []

                if(this.chartScreens && this.chartScreens.length > 0) {
                    this.screenOptions.forEach(screenOption => {
                        this.chartScreens.forEach(selectedScreenId => {
                            if(screenOption.id == selectedScreenId)
                                results.push(selectedScreenId)
                        })
                    })

                    const uniqueResults = [...new Set(results)].sort()
                    const compareResults = this.chartScreens.sort()

                    if(JSON.stringify(compareResults) !== JSON.stringify(uniqueResults)) {
                        this.$emit('updateChartScreens', uniqueResults)
                    }
                }
            },

            selectedFilterBurst: function (newVal, oldVal) {
                if(newVal) {
                    this.localChartView = newVal.id
                } else {
                    this.localChartView = 0
                }
            },
        },

        computed: {
            // PURPOSE: Selected chart KPI
            localChartMetric: {
                get() {
                    return this.chartMetric
                },

                set(v) {
                    this.$emit('updateChartMetric', v);
                }
            },

            // PURPOSE: Selected chart type
            localChartDataType: {
                get() {
                    return this.chartDataType
                },

                set(v) {
                    this.$emit('updateChartDataType', v);
                }
            },

            // PURPOSE: Selected chart burst & default to 0 if all selected
            localChartView: {
                get() {
                    return this.chartView;
                },

                set(v) {
                    this.$emit('updateChartView', v);
                }
            },

            // PURPOSE: Selected chart media owners
            localChartMediaOwners: {
                get() {
                    return this.chartMediaOwners
                },

                set(v) {
                    this.$emit('updateChartMediaOwners', v);
                }
            },

            // PURPOSE: Selected chart screens/formats
            localChartFormats: {
                get() {
                    return this.chartScreens
                },

                set(v) {
                    this.$emit('updateChartScreens', v);
                }
            },
            // PURPOSE: remove all option from bursts if they have a clash in dates
            burstOptions() {
                let bursts = [...this.campaign.campaignBursts]

                if(bursts.length > 1) {
                    bursts.unshift({id: 0, name: 'All'})
                }

                if(bursts.length === 1) {
                    this.localChartView = bursts[0].id
                }

                return bursts
            },

            filteredMediaOwners() {
      return this.mediaOwnerOptions
        .filter(mediaOwner => this.localChartMediaOwners.includes(mediaOwner.ID))
        .map(mediaOwner => mediaOwner.NAME);
    },

            // PURPOSE: Show available media owners
            mediaOwnerOptions() {
                if(this.chartData) {
                    let mediaOwners = []
                    // Filter by mode (auto/manual)
                    const chartByDataType = JSON.parse(JSON.stringify(this.chartData[this.localChartDataType]))

                    if(this.localChartView !== 0) {
                        const selectedBurst = chartByDataType[0].bursts.find(burst => burst.id === this.localChartView)

                        selectedBurst.mediaOwners.forEach(mediaOwner => {
                            mediaOwners.push({ id: mediaOwner.id, name: mediaOwner.name })
                        })
                    }
                    else {
                        chartByDataType[0].bursts.forEach(burst => {
                            burst.mediaOwners.forEach(mediaOwner => {
                                if(!mediaOwners.includes({ id: mediaOwner.id, name: mediaOwner.name })) {
                                    mediaOwners.push({ id: mediaOwner.id, name: mediaOwner.name })
                                }
                            })
                        })
                    }
                    
                    return mediaOwners
                    }

                return []
            },

            // PURPOSE: Show available screens
            screenOptions() {
                if(this.chartData) {
                    let screens = []
                    // Filter by mode (auto/manual)
                    const chartByDataType = JSON.parse(JSON.stringify(this.chartData[this.localChartDataType]))

                    // Get selected burst(s)
                    const selectedBursts = (this.localChartView !== 0) ? [chartByDataType[0].bursts.find(burst => burst.id === this.localChartView)] : chartByDataType[0].bursts

                    // Loop through bursts
                    selectedBursts.forEach(burst => {
                        // Loop through media owners
                        burst.mediaOwners.forEach(mediaOwner => {
                            // Loop through screens
                            mediaOwner.screens.forEach(screen => {
                                // Filter by selected media owners
                                if(this.localChartMediaOwners && this.localChartMediaOwners.length > 0) {
                                    this.localChartMediaOwners.forEach(id => {
                                        if(!screens.includes({ id: screen.id, name: screen.name }) && (id === mediaOwner.id)) {
                                            screens.push({ id: screen.id, name: screen.name })
                                        }
                                    })
                                }
                                // Filter by all
                                else {
                                    screens.push({ id: screen.id, name: screen.name })
                                }
                            })
                        })
                    })
                    
                    return screens
                }

                return []
            },

            // PURPOSE: Show current route version
            currentRouteVersion() {
                let currentRouteVersion = []

                if(this.chartData) {
                    // Filter by mode (auto/manual)
                    const chartByDataType = JSON.parse(JSON.stringify(this.chartData[this.localChartDataType]))

                    // Filter by bursts needed (get an array)
                    let chartByBurst = this.splitChartByBurst(chartByDataType)

                    chartByBurst.forEach(burst => {
                        if(burst.data.routeVersions.length > 0)
                            currentRouteVersion.push(burst.data.routeVersions.toString())
                    })
                }

                const unique = currentRouteVersion.filter((x, i, a) => a.indexOf(x) == i)

                return (unique.length > 0) ? unique.join(',') : ''
            },

            // PURPOSE: Chart styling options
            chartOptions() {
                let options = {
                    chart: {
                        foreColor: (this.$vuetify.theme.dark) ? '#fff' : '#000',
                        type: "line",
                        height: 350,
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                                export: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                                customIcons: [{
                                    icon: '<div class="mdi mdi-content-copy" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 4,
                                    title: 'Copy Graph to Clipboard',
                                    click: () => { this.saveGraphScreenshot(true) }
                                },
                                {
                                    icon: '<div class="mdi mdi-file-image" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 5,
                                    title: 'Export Graph to PNG',
                                    click: () => { this.saveGraphScreenshot(false) }
                                },
                                {
                                    icon: '<div class="mdi mdi-file-table" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 6,
                                    title: 'Export Graph to CSV',
                                    click: () => { this.exportToCSV() }
                                }]
                            },
                            export: {
          csv: {
            //
            filename: this.campaign.name + '-chart-export-',
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value'
          },
          svg: false,
          png: false,
        },
                            autoSelected: 'zoom'
                        },
                    },

                    stroke: {
                        width: 3,
                    },

                    xaxis: {
                        tickAmount: this.xTickCalculation,
                        tickPlacement: 'between',
                        type: 'datetime',
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                            minHeight: 45,
                            formatter: function (val, timestamp, index) {
                                return moment(new Date(timestamp)).format("DD MMM");
                            },
                        },
                    },
                    grid: {
                        padding: {
                            left: 20,
                            right: 6,
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                return value.toFixed(0)
                            }
                        }
                    },

                    tooltip: {
                        shared: false,
                        x: {
                            formatter: (x) => {
                                if (typeof x !== "undefined") {
                                    return moment(new Date(x)).format("DD MMM HH:mm:ss")
                                }
                                return x;
                            }
                        },
                        y: {
                            formatter: (y) => {
                                if (typeof y !== "undefined")
                                    return ((y) ? y.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'No ') + " " + this.chartMetric
                                return y.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                    },

                    markers: {
                        size: 4,
                    },

                    legend: {
                        show: false,
                        position: "bottom",
                        offsetX: 0,
                        offsetY: 0,
                    }
                }

                return options;
            },
            // PURPOSE: Show a date for every point on the chart
            xTickCalculation() {
                if(this.sanitizedChartData.filter(data => data.dataType === 'targetData') !== undefined) {
                    const targets = this.sanitizedChartData.filter(data => data.dataType === 'targetData')
                    if(targets.length === 1) {
                        return targets[0].data.length - 1 
                    }
                }
                return null
            },

            // PURPOSE: Get chart data based on selection of filters
            sanitizedChartData() {
                if(this.chartData) {
                    // Filter by mode (auto/manual)
                    const chartByDataType = JSON.parse(JSON.stringify(this.chartData[this.localChartDataType])) // clone

                    // Filter by campaign
                    let chartByCampaign = this.splitChartByCampaign(chartByDataType)

                    // Filter by bursts needed (get an array)
                    let chartByBurst = this.splitChartByBurst(chartByDataType)

                    // Filter by all or 1 burst
                    let chartDisplayData = chartByCampaign[0]
                    if(this.localChartView !== 0){
                        chartDisplayData = chartByBurst[0]
                    }


                    // Filter by screens
                    if(this.localChartFormats && this.localChartFormats.length > 0) {
                        chartDisplayData = this.splitChartByScreens(chartByBurst)
                    }

                    // Filter by media owners
                    else if(this.localChartMediaOwners && this.localChartMediaOwners.length > 0) {
                        chartDisplayData = this.splitChartByMediaOwners(chartByBurst)
                    }

                    // Filter by metric selected
                    const chartByMetric = this.splitChartByMetric(chartDisplayData)

                    // Remove overlapping chart data
                    //const splitChartData = this.removeOverlappingChartData(chartByMetric)

                    // Map data by format type
                    const chartByFormatType = this.mapChartByFormatType(chartByMetric)

                    // Remove redundant forecast data
                    const chartSanitized = this.removeChartForecast(chartByFormatType)

                    // Split chart data into individual objects in the array
                    const chartData = this.splitChartData(chartSanitized)


                    // Delete item from an object in the chartData if its .x or .y proerty is === 0
                    chartData.forEach((item, index) => {
                        if(item.dataType === 'targetData') {
                           item.data.forEach((item, index) => {
                                if(item.y === 0 || item.x === 0){
                                    // Delete item from chartData array
                                    chartData.splice(index, 1)
                                }
                            })
                        }
                    })
                    this.$nextTick()
                    
                    return chartData
                }

                return []
            },

            // PURPOSE: Get chart summary data (text underneath)
            activeRouteGraphSummaryData() {
                let result = {
                    actualHours: 0,
                    actualImpacts: 0,
                    actualPlays: 0,
                    actualPercentageHours: 0,
                    actualPercentageImpacts: 0,
                    actualPercentagePlays: 0,

                    forecastHours: 0,
                    forecastImpacts: 0,
                    forecastPlays: 0,
                    forecastPercentageHours: 0,
                    forecastPercentageImpacts: 0,
                    forecastPercentagePlays: 0,

                    targetHours: 0,
                    targetImpacts: 0,
                    targetPlays: 0,
                    
                    outOfScopeHours: 0,
                    outOfScopeImpacts: 0,
                    outOfScopePlays: 0,
                    outOfScopePercentageHours: 0,
                    outOfScopePercentageImpacts: 0,
                    outOfScopePercentagePlays: 0,
                }

                const mergeNestedData = (bursts) => {
                    if((this.localChartMediaOwners && this.localChartMediaOwners.length > 0) || (this.localChartFormats && this.localChartFormats.length > 0)) {
                        let results = []

                        bursts.forEach(burst => {
                            burst.mediaOwners.forEach(mediaOwner => {
                                // Filter screens
                                if(this.localChartFormats && this.localChartFormats.length > 0) {
                                    this.localChartFormats.forEach(id => {
                                        mediaOwner.screens.forEach(screen => {
                                            if(screen.screenId == id) {
                                                results.push({ campaignBurstId: burst.campaignBurstId, name: burst.name, auto: screen.auto, manual: screen.manual })
                                            }
                                        })
                                    })
                                }
                                else {
                                    // Filter media owner
                                    this.localChartMediaOwners.forEach(id => {
                                        if(mediaOwner.mediaOwnerId == id) {
                                            results.push({ campaignBurstId: burst.campaignBurstId, name: burst.name, auto: mediaOwner.auto, manual: mediaOwner.manual })
                                        }
                                    })
                                }
                            })
                        })

                        return results
                    }

                    return bursts
                }

                const mergeBurstData = (bursts) => {
                    // loop through burst
                    for (let index = 0; index < bursts.length; index++) {
                        const burst = bursts[index];
                        const burstType = burst[this.localChartDataType];

                        result.actualHours += Number(burstType.actualHours.replace(/[^\d\.\-]/g, ""))
                        result.actualImpacts += Number( burstType.actualImpacts.replace(/[^\d\.\-]/g, ""))
                        result.actualPlays += Number(burstType.actualPlays.replace(/[^\d\.\-]/g, ""))

                        result.forecastHours += Number(burstType.forecastHours.replace(/[^\d\.\-]/g, ""))
                        result.forecastImpacts += Number(burstType.forecastImpacts.replace(/[^\d\.\-]/g, ""))
                        result.forecastPlays += Number(burstType.forecastPlays.replace(/[^\d\.\-]/g, ""))

                        result.targetHours += Number(burstType.targetHours.replace(/[^\d\.\-]/g, ""))
                        result.targetImpacts += Number(burstType.targetImpacts.replace(/[^\d\.\-]/g, ""))
                        result.targetPlays += Number(burstType.targetPlays.replace(/[^\d\.\-]/g, ""))
                        
                        result.outOfScopeHours += Number(burstType.outOfScopeHours.replace(/[^\d\.\-]/g, ""))
                        result.outOfScopeImpacts += Number(burstType.outOfScopeImpacts.replace(/[^\d\.\-]/g, ""))
                        result.outOfScopePlays += Number(burstType.outOfScopePlays.replace(/[^\d\.\-]/g, ""))
                    }
                }

                const formatResultData = (bursts) => {
                    // Forecast
                    result.forecastPercentageHours = (result.forecastHours / result.targetHours) ? `${(result.forecastHours / result.targetHours * 100).toFixed(2)}%` : '0%'
                    result.forecastPercentageImpacts = (result.forecastHours / result.targetHours) ? `${(result.forecastImpacts / result.targetImpacts * 100).toFixed(2)}%`: '0%'
                    result.forecastPercentagePlays = (result.forecastHours / result.targetHours) ? `${(result.forecastPlays / result.targetPlays * 100).toFixed(2)}%`: '0%'

                    result.forecastHours = result.forecastHours.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    result.forecastImpacts = result.forecastImpacts.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    result.forecastPlays = result.forecastPlays.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                    // Out of scope
                    result.outOfScopePercentageHours = (result.outOfScopeHours / result.actualHours) ? `${(result.outOfScopeHours / result.actualHours * 100).toFixed(2)}%`: '0%'
                    result.outOfScopePercentageImpacts = (result.outOfScopeImpacts / result.actualImpacts) ? `${(result.outOfScopeImpacts / result.actualImpacts * 100).toFixed(2)}%`: '0%'
                    result.outOfScopePercentagePlays = (result.outOfScopePlays / result.actualPlays) ? `${(result.outOfScopePlays / result.actualPlays * 100).toFixed(2)}%`: '0%'

                    result.outOfScopeHours = result.outOfScopeHours.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    result.outOfScopeImpacts = result.outOfScopeImpacts.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    result.outOfScopePlays = result.outOfScopePlays.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    
                    // Actual
                    result.actualPercentageHours = (result.actualHours / result.targetHours) ? `${(result.actualHours / result.targetHours * 100).toFixed(2)}%`: '0%'
                    result.actualPercentageImpacts = (result.actualImpacts / result.targetHours) ? `${(result.actualImpacts / result.targetImpacts * 100).toFixed(2)}%`: '0%'
                    result.actualPercentagePlays = (result.actualPlays / result.targetHours) ? `${(result.actualPlays / result.targetPlays * 100).toFixed(2)}%`: '0%'

                    result.actualHours = result.actualHours.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    result.actualImpacts = result.actualImpacts.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    result.actualPlays = result.actualPlays.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 

                    // Target
                    result.targetHours = result.targetHours.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    result.targetImpacts = result.targetImpacts.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    result.targetPlays = result.targetPlays.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                }

                // Init functions
                const bursts = mergeNestedData((this.chartView == 0) ? this.routeAnalytics.bursts : [this.routeAnalytics.bursts.find((burst) => burst.campaignBurstId == this.chartView)])
                
                mergeBurstData(bursts)

                formatResultData(bursts)

                return result
            },

            // PURPOSE: Show collected % based off current selection
            activeRouteGraphVerificationsPercentage() {
                // Result (return at bottom)
                let percentage = 0

                // Filter the screen data
                if(this.localChartFormats && this.localChartFormats.length > 0) { 
                    let amountAdded = 0
                    
                    this.campaignVerifications.bursts.forEach(burst => {
                        burst.mediaOwners.forEach(mediaOwner => {
                            this.localChartFormats.forEach(id => {
                                let screen = mediaOwner.screens.find(screen => screen.screenId === id)
                                
                                if(screen && (this.localChartView == 0 || this.localChartView == burst.campaignBurstId)) {
                                    percentage += Number(screen.percentage)
                                    amountAdded += 1
                                }
                            })
                        })
                    })

                    percentage = (amountAdded > 0) ? this.roundMaxDecimals(percentage / amountAdded, 1) : 0
                }

                // Filter the media owner data
                else if(this.localChartMediaOwners && this.localChartMediaOwners.length > 0) { 
                    let amountAdded = 0
                    
                    this.campaignVerifications.bursts.forEach(burst => {
                        this.localChartMediaOwners.forEach(id => {
                            let mediaOwner = burst.mediaOwners.find(mediaOwner => mediaOwner.mediaOwnerId === id)
                            
                            if(mediaOwner && (this.localChartView == 0 || this.localChartView == burst.campaignBurstId)) {
                                percentage += Number(mediaOwner.percentage)
                                amountAdded += 1
                            }
                        })
                    })

                    percentage = (amountAdded > 0) ? this.roundMaxDecimals(percentage / amountAdded, 1) : 0
                }

                // Filter the bursts data
                else if(this.localChartView == 0) {
                    percentage = this.roundMaxDecimals(this.campaignVerifications.percentage, 1)
                }

                // Filter burst data
                else if(this.localChartView != 0) {
                    percentage = this.roundMaxDecimals(this.campaignVerifications.bursts.find(burst => burst.campaignBurstId == this.localChartView).percentage, 1)
                }

                // Return the result
                return percentage
            },

            // PURPOSE: Get a color based off collected %
            activeRouteGraphVerificationsColor() {
                if(this.activeRouteGraphVerificationsPercentage >= 80) {
                    return 'green'
                }
                else if(this.activeRouteGraphVerificationsPercentage >= 50) {
                    return 'amber'
                }
                
                return 'red'
            }
        },

        async created() {
            await this.getAllData()
        },
        mounted() {
            const thisInstance = this
            this.$root.$on('reloadRouteGraph', () =>{ thisInstance.getAllData() })
        },
        methods: {
            async downloadDashboardReport(campaignId) {
                let res = await ReportingController.getDashboardReportDownload(campaignId)

                const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
                const fileName = folderPath[folderPath.length-1]
                const blob = new Blob([res.data])
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileName
                link.click()
                URL.revokeObjectURL(link.href)
            },
            async saveGraphScreenshot(isClipboard) {
                // Get the element to be captured
                const graphElement = document.getElementById('graph-only-container');
                // Check if the element is found
                if (!graphElement) {
                    this.$root.$emit('snackbarError', 'Error capturing screenshot: Graph element not found, try reloading the page.');
                    return;
                }
                try {
                    // Use html2canvas to capture the content of the element
                    const canvas = await this.$html2canvas(graphElement, {
                    scale: 6,
                    dataURL: true,
                    logging: false,
                    onclone: function(document) {
                        var graphHoverElements = document.querySelectorAll('.apexcharts-active');
                        graphHoverElements.forEach(function(element) {
                        // Remove 'theme--dark' class
                        element.classList.remove('apexcharts-active');
                        });
                        var graphThumbnail = document.querySelector('#graph-thumbnail');
                        graphThumbnail.style.display = 'block';

                        // Get all elements with the class 'theme--dark'
                        var darkElements = document.querySelectorAll('.theme--dark');
                        // Loop through each element and switch the classes
                        darkElements.forEach(function(element) {
                            // Remove 'theme--dark' class
                            element.classList.remove('theme--dark');
                            // Add 'theme--light' class
                            element.classList.add('theme--light');
                        });

                        // REMOVE GRAPH TOOLBAR
                        var graphToolbar = document.querySelector('.apexcharts-toolbar');
                        if (graphToolbar) {
                            graphToolbar.style.display = 'none';
                        }

                        // Get all <text> elements on the page
                        var textElements = document.querySelectorAll('text');

                        // Loop through each <text> element and change the fill attribute
                        textElements.forEach(function(element) {
                        // Change the fill attribute to your desired value (e.g., 'red')
                        element.setAttribute('fill', '#6e6e6e');
                        });

                        // Get all mdi-trending-neutral elements on the page
                        var textElem = document.querySelectorAll('.mdi');
                        textElem.forEach(function(element) {

                        element.classList.add('mdi-dark');
                        // make fill black important css
                        element.style.color = 'black!important';
                        element.style.caretColor = 'black!important';
                        });
                    }
                    });

                    const dataUrl = canvas.toDataURL('image/png');
                    if (isClipboard) {
                    // If isClipboard is true, copy the image data to the clipboard
                    const blob = await (await fetch(dataUrl)).blob();

                    await navigator.clipboard.write([
                        new ClipboardItem({ 'image/png': blob })
                    ]);
                    this.$root.$emit('snackbarSuccess', 'Graph copied to clipboard.')
                    } else {
                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    // Datetime unique file name for each screenshot
                    link.download = this.campaign.name + '-export-img-' + new Date().toISOString() + '.png';
                    // Append the link to the document and trigger the download
                    document.body.appendChild(link);
                    link.click();
                    // Remove the link from the document
                    document.body.removeChild(link);
                    this.$root.$emit('snackbarSuccess', 'Graph exported to PNG.')
                    }
                } catch (error) {
                    this.$root.$emit('snackbarError', 'Error capturing screenshot: ' + error);
                }
            },
            exportToCSV() {
                try{
                    // Extract data from the chart instance
                    const chartData = Object.values(this.sanitizedChartData);

                    // Filter out data with the name containing "dead space"
                    const filteredChartData = chartData.filter(item => !item.name.includes('dead space'));

                    // Create a CSV content with headers
                    let csvContent = this.campaign.name + " (" + this.localChartMetric + ") - Verified\n\n";

                    try{
                        // BURSTS
                        csvContent += 'Burst';
                        if (this.localChartView) {
                            csvContent += ':," ' + this.burstOptions.find(burst => burst.id === this.localChartView).name;
                        }
                        else {
                            csvContent += 's (All):," ' + this.burstOptions.map(burst => burst.name).sort().slice(1).join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END BURSTS

                    // MEDIA OWNERS
                    try{
                        csvContent += 'Media Owner';
                        if (this.localChartMediaOwners.length > 1) {
                            csvContent += 's:," ' + this.localChartMediaOwners
                                .map(mediaOwnerId => {
                                    const mediaOwner = this.mediaOwnerOptions.find(option => option.id === mediaOwnerId);
                                    return mediaOwner ? mediaOwner.name : '';
                                })
                                .sort()
                                .join(", ");
                        } 
                        else if (this.localChartMediaOwners.length === 1) {
                            csvContent += ':," ' + this.mediaOwnerOptions.find(option => option.id === this.localChartMediaOwners[0]).name;
                        }
                        else {
                            csvContent += 's (All):," ' + this.mediaOwnerOptions.map(mediaOwner => mediaOwner.name)
                            .sort()
                            .join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END MEDIA OWNERS

                    // FORMATS
                    try{
                        csvContent += 'Format';

                        if (this.localChartFormats.length > 1) {
                            csvContent += 's:," ' + this.localChartFormats
                                .map(chartFormatsId => {
                                    const chartFormats = this.screenOptions.find(option => option.id === chartFormatsId);
                                    return chartFormats ? chartFormats.name : '';
                                })
                        .sort()
                        .join(', ');
                        }
                        else if (this.localChartFormats.length === 1) {
                        csvContent += ':," ' + this.screenOptions.find(option => option.id === this.localChartFormats[0]).name;
                        }
                        else {
                        csvContent += 's (All):," ' + this.screenOptions.map(option => option.name)
                        .sort()
                        .join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END FORMATS

                    // Add date heading
                    csvContent += "\nDate,";

                    // Extract unique names from the filtered chart data
                    const uniqueNames = Array.from(
                        new Set(filteredChartData.map(item => item.name))
                    );

                    // Remove this.campaign.name string from the start of each unique names by the length
                    uniqueNames.forEach(name => {
                        csvContent += getCSVHeadingSuffix(name) + ",";
                    });
                    csvContent += "\n";

                    // Sort chart data items by date ascending
                    filteredChartData.forEach(item => {
                        item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
                    });

                    // Create an object with data grouped by date
                    const dataByDate = {};
                    filteredChartData.forEach(item => {
                        item.data.forEach(point => {
                            const date = timestamp(new Date(point.x));
                            if (!dataByDate[date]) {
                                dataByDate[date] = {};
                            }
                            dataByDate[date][item.name] = point.y;
                        });
                    });

                    // Add rows to CSV
                    Object.keys(dataByDate).forEach(date => {
                        csvContent += date;
                        uniqueNames.forEach(name => {
                            csvContent += `,${Math.round(dataByDate[date][name] || 0)}`;
                        });
                        csvContent += "\n";
                    });

                    csvContent += "\n[Exported " + timestamp(null, true) + "]";

                    // Create a data URL for the CSV
                    const csvData = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", "data:text/csv;charset=utf-8," + csvData);
                    link.setAttribute("download", this.campaign.name + '-export-csv-' + new Date().toISOString() + ".csv");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.$root.$emit('snackbarSuccess', 'Graph exported to CSV.');
                } catch (error) {
                    this.$root.$emit('snackbarError', 'Error exporting to CSV: ' + error);
                }

                function timestamp(date, addTime = false) {
                    if (!date) {
                        date = new Date();
                    }
                    var returnDate = "";
                    returnDate = date.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' });
                    if (addTime) {
                        returnDate += " " + date.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' });
                    }
                    return returnDate;
                }

                function getCSVHeadingSuffix(inputHeading) {
                    // Find the last occurrence of a hyphen
                    const lastHyphenIndex = inputHeading.lastIndexOf('-');

                    var result = null;
                    
                    // Check if a hyphen was found
                    if (lastHyphenIndex !== -1) {
                        // Extract the substring after the last hyphen
                        result = inputHeading.substring(lastHyphenIndex + 1).trim();
                        
                    } else {
                        // If no hyphen is found, return the original heading
                        result = inputHeading.trim();
                    }

                    return result;
                }
            },
            // PURPOSE: Test data for chart data
            testGetAnalyticsChartData() {
                return {
                    auto: [{
                        name: 'Kopparberg Summer 2022',

                        bursts: [
                            {
                                id: 9,
                                name: 'Burst 1',

                                data: {
                                    routeVersions: ['v41.2 [8.10]'],

                                    targetData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    forecastData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    actualData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                    ],
                                },

                                mediaOwners: [
                                    {
                                        id: 1,
                                        name: 'JCDecaux',
                                        
                                        data: {
                                            targetData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            actualData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                                { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                            ],
                                        },

                                        screens: [
                                            {
                                                id: 4,
                                                name: 'BenQ',
                                                data: {
                                                    targetData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    forecastData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    actualData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                                    ],
                                                }
                                            },
                                        ]
                                    },
                                ]
                            },

                            {
                                id: 12,
                                name: 'Burst 2',

                                data: {
                                    routeVersions: ['v41.2 [8.10]'],
                                    
                                    targetData: [
                                        { date: '2022-09-19T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    forecastData: [
                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                    ],

                                    actualData: [
                                        // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                        // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],
                                },

                                mediaOwners: [
                                    {
                                        id: 1,
                                        name: 'JCDecaux',
                                        
                                        data: {
                                            targetData: [
                                                { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-15T00:00:00', impacts: 200, plays: 0, hours: 0 },
                                                { date: '2022-10-16T00:00:00', impacts: 200, plays: 10, hours: 18 },
                                                { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                            ],

                                            actualData: [ ],
                                        },

                                        screens: [
                                            {
                                                id: 4,
                                                name: 'BenQ',
                                                data: {
                                                    targetData: [
                                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-15T00:00:00', impacts: 200, plays: 0, hours: 0 },
                                                        { date: '2022-10-16T00:00:00', impacts: 200, plays: 10, hours: 18 },
                                                        { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    forecastData: [
                                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    ],

                                                    actualData: [ ],
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        id: 2,
                                        name: 'Global',
                                        
                                        data: {
                                            targetData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-14T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-15T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                { date: '2022-10-16T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                // { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                            ],

                                            actualData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],
                                        },

                                        screens: [
                                            {
                                                id: 6,
                                                name: 'Samsung',
                                                data: {
                                                    targetData: [
                                                        // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        // { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-14T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-10-15T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                        { date: '2022-10-16T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                        // { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    forecastData: [
                                                        // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        // { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        // { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        // { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        // { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        // { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        // { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        // { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    ],

                                                    actualData: [
                                                        // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                                        // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                        // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],
                                                }
                                            },
                                        ]
                                    },
                                ]
                            }
                        ]
                    }],

                    manual: [{
                        name: 'Kopparberg Summer 2022',

                        bursts: [
                            {
                                id: 9,
                                name: 'Burst 1',

                                data: {
                                    routeVersions: ['v41.2 [8.10]'],

                                    targetData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    forecastData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    actualData: [
                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                    ],
                                },

                                mediaOwners: [
                                    {
                                        id: 1,
                                        name: 'JCDecaux',
                                        
                                        data: {
                                            targetData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            actualData: [
                                                { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                                { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                                { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                                { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                            ],
                                        },

                                        screens: [
                                            {
                                                id: 4,
                                                name: 'BenQ',
                                                data: {
                                                    targetData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    forecastData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 10, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    actualData: [
                                                        { date: '2022-09-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-09-11T00:00:00', impacts: 250, plays: 5, hours: 18 },
                                                        { date: '2022-09-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-13T00:00:00', impacts: 250, plays: 13, hours: 84 },
                                                        { date: '2022-09-14T00:00:00', impacts: 300, plays: 13, hours: 104 },
                                                        { date: '2022-09-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                        { date: '2022-09-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-09-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                        { date: '2022-09-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-09-19T00:00:00', impacts: 400, plays: 18, hours: 104 },
                                                        { date: '2022-09-20T00:00:00', impacts: 300, plays: 5, hours: 0 },
                                                    ],
                                                }
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                id: 12,
                                name: 'Burst 2',

                                data: {
                                    routeVersions: ['v41.2 [8.10]'],
                                    
                                    targetData: [
                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-15T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],

                                    forecastData: [
                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                        { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                    ],

                                    actualData: [
                                        // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                        // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                        // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                        // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                        // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                        // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                        // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                    ],
                                },

                                mediaOwners: [
                                    {
                                        id: 1,
                                        name: 'JCDecaux',
                                        
                                        data: {
                                            targetData: [
                                                { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-15T00:00:00', impacts: 200, plays: 0, hours: 0 },
                                                { date: '2022-10-16T00:00:00', impacts: 200, plays: 10, hours: 18 },
                                                { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                            ],

                                            actualData: [ ],
                                        },

                                        screens: [
                                            {
                                                id: '4',
                                                name: 'BenQ',
                                                data: {
                                                    targetData: [
                                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-15T00:00:00', impacts: 200, plays: 0, hours: 0 },
                                                        { date: '2022-10-16T00:00:00', impacts: 200, plays: 10, hours: 18 },
                                                        { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                    ],

                                                    forecastData: [
                                                        { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                        { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                        { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                        { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    ],

                                                    actualData: [ ],
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        id: 2,
                                        name: 'Global',
                                        
                                        data: {
                                            targetData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                { date: '2022-10-14T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                { date: '2022-10-15T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                { date: '2022-10-16T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                // { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],

                                            forecastData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                // { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                            ],

                                            actualData: [
                                                // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                                // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                            ],
                                        },

                                        screens: [
                                            {
                                                id: 6,
                                                name: 'Samsung',

                                                targetData: [
                                                    // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                    // { date: '2022-10-11T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                    // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-13T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    { date: '2022-10-14T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                    { date: '2022-10-15T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                    { date: '2022-10-16T00:00:00', impacts: 100, plays: 10, hours: 18 },
                                                    // { date: '2022-10-17T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-19T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                ],

                                                forecastData: [
                                                    // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                    // { date: '2022-10-11T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                    // { date: '2022-10-12T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    // { date: '2022-10-13T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                    { date: '2022-10-14T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    { date: '2022-10-15T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                    { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    // { date: '2022-10-17T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                    // { date: '2022-10-18T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                    // { date: '2022-10-19T00:00:00', impacts: 400, plays: 20, hours: 60 },
                                                    // { date: '2022-10-20T00:00:00', impacts: 300, plays: 10, hours: 30 },
                                                ],

                                                actualData: [
                                                    // { date: '2022-10-10T00:00:00', impacts: 0, plays: 0, hours: 0 },
                                                    // { date: '2022-10-11T00:00:00', impacts: 400, plays: 10, hours: 18 },
                                                    // { date: '2022-10-12T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-13T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                    // { date: '2022-10-14T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-15T00:00:00', impacts: 400, plays: 0, hours: 0 },
                                                    // { date: '2022-10-16T00:00:00', impacts: 300, plays: 10, hours: 18 },
                                                    // { date: '2022-10-17T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                    // { date: '2022-10-18T00:00:00', impacts: 300, plays: 13, hours: 84 },
                                                    // { date: '2022-10-19T00:00:00', impacts: 400, plays: 13, hours: 84 },
                                                    // { date: '2022-10-20T00:00:00', impacts: 300, plays: 0, hours: 0 },
                                                ],
                                            },
                                        ]
                                    },
                                ]
                            }
                        ]
                    }]
                }
            },

            // PURPOSE: Test data for chart summary of data
            testGetDashboardAnalytics() {
                // Insert correct campaign ID, Burst ID/s and Media Owner ID/s
                return {
                    id: 1026,
                    name: 'Kopparberg Summer 2022',
    
                    bursts: [
                        // Burst 1
                        {
                            campaignBurstId: 9,
                            name: 'Burst 1',

                            auto: {
                                actualHours: '580.00',
                                actualImpacts: '3,200.00',
                                actualPlays: '103.00',

                                forecastHours: '580.00',
                                forecastImpacts: '3,200.00',
                                forecastPlays: '103.00',

                                targetHours: '540.00',
                                targetImpacts: '3,000.00',
                                targetPlays: '98.00',

                                outOfScopeHours: '100',
                                outOfScopeImpacts: '1000',
                                outOfScopePlays: '10',

                            },

                            manual: {
                                actualHours: '580.00',
                                actualImpacts: '3,200.00',
                                actualPlays: '103.00',

                                forecastHours: '580.00',
                                forecastImpacts: '3,200.00',
                                forecastPlays: '103.00',

                                targetHours: '916.00',
                                targetImpacts: '4,000.00',
                                targetPlays: '218.00',

                                outOfScopeHours: '100',
                                outOfScopeImpacts: '1000',
                                outOfScopePlays: '10',
                            },

                            mediaOwners: [
                                {
                                    mediaOwnerId: 1,
                                    name: "JCDecaux - (GB)",

                                    auto: {
                                        actualHours: '580.00',
                                        actualImpacts: '3,200.00',
                                        actualPlays: '103.00',

                                        forecastHours: '580.00',
                                        forecastImpacts: '3,200.00',
                                        forecastPlays: '103.00',

                                        targetHours: '540.00',
                                        targetImpacts: '3,000.00',
                                        targetPlays: '98.00',

                                        outOfScopeHours: '100',
                                        outOfScopeImpacts: '1000',
                                        outOfScopePlays: '10',
                                    },

                                    manual: {
                                        actualHours: '580.00',
                                        actualImpacts: '3,200.00',
                                        actualPlays: '103.00',

                                        forecastHours: '580.00',
                                        forecastImpacts: '3,200.00',
                                        forecastPlays: '103.00',

                                        targetHours: '916.00',
                                        targetImpacts: '4,000.00',
                                        targetPlays: '218.00',

                                        outOfScopeHours: '100',
                                        outOfScopeImpacts: '1000',
                                        outOfScopePlays: '10',
                                    },

                                    screens: [
                                        {
                                            screenId: 4,
                                            name: "BenQ",

                                            auto: {
                                                actualHours: '580.00',
                                                actualImpacts: '3,200.00',
                                                actualPlays: '103.00',

                                                forecastHours: '580.00',
                                                forecastImpacts: '3,200.00',
                                                forecastPlays: '103.00',

                                                targetHours: '540.00',
                                                targetImpacts: '3,000.00',
                                                targetPlays: '98.00',

                                                outOfScopeHours: '100',
                                                outOfScopeImpacts: '1000',
                                                outOfScopePlays: '10',
                                            },

                                            manual: {
                                                actualHours: '580.00',
                                                actualImpacts: '3,200.00',
                                                actualPlays: '103.00',

                                                forecastHours: '580.00',
                                                forecastImpacts: '3,200.00',
                                                forecastPlays: '103.00',

                                                targetHours: '916.00',
                                                targetImpacts: '4,000.00',
                                                targetPlays: '218.00',

                                                outOfScopeHours: '100',
                                                outOfScopeImpacts: '1000',
                                                outOfScopePlays: '10',
                                            },
                                        }
                                    ]
                                }
                            ],
                        },

                        // Burst 2
                        {
                            campaignBurstId: 12,
                            name: 'Burst 2',

                            auto: {
                                actualHours: '0.00',
                                actualImpacts: '0.00',
                                actualPlays: '0.00',

                                forecastHours: '450.00',
                                forecastImpacts: '3,500.00',
                                forecastPlays: '150.00',

                                targetHours: '540.00',
                                targetImpacts: '3,000.00',
                                targetPlays: '98.00',

                                outOfScopeHours: '0',
                                outOfScopeImpacts: '0',
                                outOfScopePlays: '0',
                            },

                            manual: {
                                actualHours: '0.00',
                                actualImpacts: '0.00',
                                actualPlays: '0.00',

                                forecastHours: '450.00',
                                forecastImpacts: '3,500.00',
                                forecastPlays: '150.00',

                                targetHours: '500.00',
                                targetImpacts: '4,000.00',
                                targetPlays: '200.00',

                                outOfScopeHours: '0',
                                outOfScopeImpacts: '0',
                                outOfScopePlays: '0',
                            },

                            mediaOwners: [
                                {
                                    mediaOwnerId: 1,
                                    name: "JCDecaux - (GB)",

                                    auto: {
                                        actualHours: '0.00',
                                        actualImpacts: '0.00',
                                        actualPlays: '0.00',

                                        forecastHours: '540.00',
                                        forecastImpacts: '3,100.00',
                                        forecastPlays: '98.00',

                                        targetHours: '540.00',
                                        targetImpacts: '2,800.00',
                                        targetPlays: '98.00',

                                        outOfScopeHours: '0',
                                        outOfScopeImpacts: '0',
                                        outOfScopePlays: '0',
                                    },

                                    manual: {
                                        actualHours: '0.00',
                                        actualImpacts: '0.00',
                                        actualPlays: '0.00',

                                        forecastHours: '540.00',
                                        forecastImpacts: '3,100.00',
                                        forecastPlays: '98.00',

                                        targetHours: '540.00',
                                        targetImpacts: '2,800.00',
                                        targetPlays: '98.00',

                                        outOfScopeHours: '0',
                                        outOfScopeImpacts: '0',
                                        outOfScopePlays: '0',
                                    },

                                    screens: [
                                        {
                                            screenId: 4,
                                            name: "BenQ",

                                            auto: {
                                                actualHours: '0.00',
                                                actualImpacts: '0.00',
                                                actualPlays: '0.00',

                                                forecastHours: '540.00',
                                                forecastImpacts: '3,100.00',
                                                forecastPlays: '98.00',

                                                targetHours: '540.00',
                                                targetImpacts: '2,800.00',
                                                targetPlays: '98.00',

                                                outOfScopeHours: '0',
                                                outOfScopeImpacts: '0',
                                                outOfScopePlays: '0',
                                            },

                                            manual: {
                                                actualHours: '0.00',
                                                actualImpacts: '0.00',
                                                actualPlays: '0.00',

                                                forecastHours: '540.00',
                                                forecastImpacts: '3,100.00',
                                                forecastPlays: '98.00',

                                                targetHours: '540.00',
                                                targetImpacts: '2,800.00',
                                                targetPlays: '98.00',

                                                outOfScopeHours: '0',
                                                outOfScopeImpacts: '0',
                                                outOfScopePlays: '0',
                                            },
                                        }
                                    ]
                                },

                                {
                                    mediaOwnerId: 2,
                                    name: "Global",

                                    auto: {
                                        actualHours: '0.00',
                                        actualImpacts: '0.00',
                                        actualPlays: '0.00',

                                        forecastHours: '36.00',
                                        forecastImpacts: '400.00',
                                        forecastPlays: '200.00',

                                        targetHours: '36.00',
                                        targetImpacts: '200.00',
                                        targetPlays: '200.00',

                                        outOfScopeHours: '0',
                                        outOfScopeImpacts: '0',
                                        outOfScopePlays: '0',
                                    },

                                    manual: {
                                        actualHours: '0.00',
                                        actualImpacts: '0.00',
                                        actualPlays: '0.00',

                                        forecastHours: '36.00',
                                        forecastImpacts: '400.00',
                                        forecastPlays: '200.00',

                                        targetHours: '36.00',
                                        targetImpacts: '200.00',
                                        targetPlays: '200.00',

                                        outOfScopeHours: '0',
                                        outOfScopeImpacts: '0',
                                        outOfScopePlays: '0',
                                    },

                                    screens: [
                                        {
                                            screenId: 6,
                                            name: "Samsung",

                                            auto: {
                                                actualHours: '0.00',
                                                actualImpacts: '0.00',
                                                actualPlays: '0.00',

                                                forecastHours: '36.00',
                                                forecastImpacts: '400.00',
                                                forecastPlays: '200.00',

                                                targetHours: '36.00',
                                                targetImpacts: '200.00',
                                                targetPlays: '200.00',

                                                outOfScopeHours: '0',
                                                outOfScopeImpacts: '0',
                                                outOfScopePlays: '0',
                                            },

                                            manual: {
                                                actualHours: '0.00',
                                                actualImpacts: '0.00',
                                                actualPlays: '0.00',

                                                forecastHours: '36.00',
                                                forecastImpacts: '400.00',
                                                forecastPlays: '200.00',

                                                targetHours: '36.00',
                                                targetImpacts: '200.00',
                                                targetPlays: '200.00',

                                                outOfScopeHours: '0',
                                                outOfScopeImpacts: '0',
                                                outOfScopePlays: '0',
                                            },
                                        }
                                    ]
                                }
                            ]
                        },
                    ],
                }
            },

            // Purpose: Still need to implement this test data
            testGetDashboardVerifications() {
                return {

                }
            },

            // PURPOSE: Call all end points
            async getAllData() {
                await this.getAnalyticsChartData()
                await this.getDashboardAnalytics()
                await this.getDashboardVerifications()
            },

            // PURPOSE: Get route chart data 
            async getAnalyticsChartData() {
                //  Get data from end point for target & burst
                let { data = null } =  await RouteController.GetDashboardAnalytics(this.campaign.id).catch(err => {
                    this.$root.$emit('snackbarError', ''+ err.response.data.message)
                })
                this.$store.commit('Chart/SET_CHART', data.auto)
                // ** For testing on local version
                // data = this.testGetAnalyticsChartData()
                
                // Store in global variable
                this.chartData = data
            },

            // PURPOSE: Get route chart/line summary data
            async getDashboardAnalytics() {
                let { data = null } = await ReportingController.getDashboardAnalytics(this.campaign.id).catch(err => {
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })
                // ** For testing on local version
                // data = this.testGetDashboardAnalytics()

                this.routeAnalytics = { ...data }
            },

            // PURPOSE: Get route chart/line collected (%) data
            async getDashboardVerifications() {
                if(!this.campaign.id) return
                const { data = null } = await RouteController.getDashboardVerifications(this.campaign.id).catch(err => {
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })

                // data = testGetDashboardVerifications()

                this.campaignVerifications = data
            },

            // PURPOSE: Split chart data by campaign
            splitChartByCampaign(campaigns) {
                campaigns.forEach(campaign => 
                {
                    // clean out zeros
                    campaign = this.cleanChartData(campaign);

                    return campaign
                })
                return campaigns
            },

            // PURPOSE: Split chart by selected burst
            splitChartByBurst(data) {
                // Get specific burst
                if(this.localChartView !== 0) {
                    return [data[0].bursts.find(burst => burst.id === this.localChartView)]
                }

                // Get all bursts
                return data[0].bursts
            },

            // PURPOSE: Create the base for a chart to be created, with ZEROs for entire date range, and set name
            chartBase(chartData) {
                // out data
                let mergedActiveMediaOwners = []

                // Get start + end date range
                let starts = chartData.map(d => moment(d.startDate));
                let minDate = moment.min(starts)
                let ends = chartData.map(d => moment(d.endDate));
                let maxDate = moment.max(ends)

                // Set the name
                let names = chartData.map(x => x.name)
                let uniqueNames = [...new Set(names)].sort((a, b) => a.localeCompare(b));
                let isBursts = chartData[0].mediaOwners != undefined && uniqueNames.length > 1;
                mergedActiveMediaOwners.name = (uniqueNames.length == 1) ? uniqueNames[0] : (isBursts ? "All Bursts" : "Multiple Formats");

                // Make the initial array, with empty stats
                mergedActiveMediaOwners['data'] = []
                this.metrics.forEach(key => {
                    var currDate = moment(minDate).add(-1,'days').startOf('day'); // to start counting at correct date
                    var lastDate = moment(maxDate).startOf('day');
                    mergedActiveMediaOwners.data[key] = []
                    while(currDate.add(1, 'days').diff(lastDate) <= 0) {                        
                        mergedActiveMediaOwners.data[key].push({date: currDate.format('YYYY-MM-DDTHH:mm:ss'), impacts: 0, plays: 0, hours: 0})                        
                    }
                })

                return mergedActiveMediaOwners
            },

            // PURPOSE: Merge chart data by dates
            mergeChart(baseData, utilities) {                
                // Now merge media owner data into 1 merged object
                this.metrics.forEach(key => {
                    utilities.forEach(activeMediaOwner => {
                        activeMediaOwner.data[key].forEach(cmo => {
                            baseData.data[key].forEach(emo => {
                                if(cmo.date == emo.date) {
                                    emo.impacts += cmo.impacts
                                    emo.plays += cmo.plays
                                    emo.hours += cmo.hours
                                }
                            })
                        })
                    })
                })

                // clean out zeros
                baseData = this.cleanChartData(baseData);

                return baseData
            },

            // PURPOSE: Split chart data by selected media owners 
            splitChartByMediaOwners(chartData) {
                // Get media owner data and put into array
                let activeMediaOwners = []
                chartData.forEach(burst => {
                    burst.mediaOwners.forEach(mediaOwner => {
                        this.localChartMediaOwners.forEach(id => {
                            if(id == mediaOwner.id) {
                                activeMediaOwners.push(mediaOwner)
                            }
                        })
                    })
                })

                let chartBase = this.chartBase(chartData)

                return this.mergeChart(chartBase, activeMediaOwners)
            },

            cleanChartData(chartData) {
                this.metrics.forEach(key => {
                    chartData.data[key] = chartData.data[key].filter(x => !(x.plays == 0 && x.hours == 0 && x.impacts == 0) || key == 'forecastData')
                })
                return chartData;
            },

            // PURPOSE: Split chart data by selected screens
            splitChartByScreens(chartData) {
                let activeScreens = []
                let iterator = chartData
                if(chartData.bursts != undefined)
                    iterator = chartData.bursts
                iterator.forEach(burst => {
                    // Get media owner data and put into array
                    burst.mediaOwners.forEach(mediaOwner => {
                        mediaOwner.screens.forEach(screen => {
                            this.localChartFormats.forEach(id => {
                                if(id == screen.id) {
                                    activeScreens.push(screen)
                                }
                            })
                        })
                    })
                })
                let chartBase = this.chartBase(activeScreens)

                return this.mergeChart(chartBase, activeScreens)
            },

            // PURPOSE: SPlit nested data into array of actual, forecasts and targets
            splitChartData(incomingChartData) {
                let results = [];

                const lineName = (dataType) => {
                    if (dataType === 'targetData')
                        return 'Target';
                    if (dataType === 'forecastData')
                        return 'Forecast';
                    if (dataType === 'actualData')
                        return 'Actual';

                    return 'N/A';
                };

                const mapArray = (campaign, dataType, type) => {
                    // check if array has data (else skip)
                    let dataCollection = [];

                    const addNormalSpace = (campaign, dc) => {
                        results.push({
                            dataType: dataType,
                            name: `${campaign.name} - ${lineName(dataType)}`,
                            data: dc,
                            type: type,
                            curve: "straight",
                            color: this.calculateChartColor(campaign, dataType),
                            dash: 0,
                        });
                    };

                    const addDeadSpace = (campaign, dc) => {
                        if (this.chartDataBehavior === 'cumulative') {
                            results.unshift({
                                dataType: dataType,
                                name: `${campaign.name} - dead space`,
                                data: dc,
                                type: type,
                                curve: "straight",
                                color: "#504f4f",
                                dash: 0,
                            });
                        }
                    };

                    let deadSpace = false;
                    let deadStart = null;
                    // make sure dates are ordered
                    campaign.data[dataType].sort((a, b) => moment(a.x) - moment(b.x));
                    let cdata = campaign.data[dataType];
                    for (let i = 0; i < cdata.length; i++) {
                        var celem1 = cdata[i];
                        var d1 = moment(celem1.x);
                        if (deadSpace) {
                            deadSpace = false;
                            let deadEnd = celem1;

                            // Fix: Create a separate object for deadStart point
                            deadStart = {
                                x: celem1.x,
                                y: celem1.y,
                            };

                            // Fix: Set the y value of deadStart to itself
                            deadStart.y = deadStart.y;

                            addDeadSpace(campaign, [deadStart, deadEnd]);
                            deadStart = null;
                        }
                        dataCollection.push(celem1);
                        var celem2 = cdata[i + 1];
                        if (celem2) {
                            var d2 = moment(celem2.x);
                            var daysDiff = d2.diff(d1, 'days');
                            if (daysDiff != 1) {
                                deadStart = celem1;
                                deadSpace = true;
                                addNormalSpace(campaign, dataCollection);
                                dataCollection = [];
                            }
                        }
                    }
                    if (dataCollection.length > 0) {
                        addNormalSpace(campaign, dataCollection);
                        dataCollection = [];
                        
                    }
                    deadSpace = true;
                };

                mapArray(incomingChartData, 'targetData', 'line');
                mapArray(incomingChartData, 'forecastData', 'area');
                mapArray(incomingChartData, 'actualData', 'area');

                return results;
            },


            //
            removeOverlappingChartData(bursts) {
                let results = []

                if(false && bursts && bursts.length > 1) {
                    for (let index = 1; index < bursts.length; index++) {
                        const previousBurst = bursts[index - 1];
                        const currentBurst = bursts[index];

                        previousBurst.data.targetData.forEach(previousTargetData => {
                            currentBurst.data.targetData.forEach(currentTargetData => {
                                if(previousTargetData.x == currentTargetData.x) {
                                }
                            })
                        })
                    }
                }

                return bursts
            },

            // PURPOSE: Split chart by selected KPI
            splitChartByMetric(chartData) {

                //bursts.forEach(burst => 
                {
                    chartData.data.actualData = chartData.data.actualData.map(data => { return { x: data.date, y: data[this.localChartMetric.toLowerCase()] } })
                    chartData.data.forecastData = chartData.data.forecastData.map(data => { return { x: data.date, y: data[this.localChartMetric.toLowerCase()] } })
                    chartData.data.targetData = chartData.data.targetData.map(data => { return { x: data.date, y: data[this.localChartMetric.toLowerCase()] } })
                }
                //)

                return chartData
            },

            // PURPOSE: Split chart by selected metric & add dead space if needed
            mapChartByFormatType(burst) {
                if(this.chartDataBehavior === 'cumulative') {

                    // Take the y axis out into its own array to pass into the cumulative sum function
                    let actualData = burst.data.actualData.map(data => { return data.y })
                    let forecastData = burst.data.forecastData.map(data => { return data.y })
                    let targetData = burst.data.targetData.map(data => { return data.y })

                    // Run a cumulative sum
                    actualData = cumulativeSum(actualData)
                    forecastData = cumulativeSum(forecastData)
                    targetData = cumulativeSum(targetData)

                    // Put new y axis values in place of the old ones
                    burst.data.actualData.forEach((data, index) => { data.y = actualData[index] })
                    burst.data.forecastData.forEach((data, index) => { data.y = forecastData[index] })
                    burst.data.targetData.forEach((data, index) => { data.y = targetData[index] })
                }

                return burst
            },

            // PURPOSE: Remove chart's redundant forecast data
            removeChartForecast(burst) {
                this.showForecast = false
                this.showPerformance = false

                let spliceUntilIndex = null, maxDate = null

                // Get max date the actual data is showing to
                burst.data.actualData.forEach(actual => { maxDate = actual.x })

                burst.data.forecastData.forEach((forecast, index) => { if(forecast.x === maxDate){ spliceUntilIndex = index } })

                burst.data.forecastData.splice(0, spliceUntilIndex)

                // If there is only one item in the forecast data then delete it
                if(burst.data.forecastData.length === 1) { burst.data.forecastData = [] }

                if(burst.data.forecastData.length) {
                    this.showForecast = true
                }
                if(burst.data.actualData.length) {
                    this.showPerformance = true
                }
                
                return burst
            },

            // PURPOSE: Style actual & forecast data on based on performance
            calculateChartColor(burst, dataType) {
                const currForecast = Number(this.activeRouteGraphSummaryData['forecast' + this.chartMetric].replace(/[^\d\.\-]/g, ""))
                const currActual = Number(this.activeRouteGraphSummaryData['actual' + this.chartMetric].replace(/[^\d\.\-]/g, ""))
                const currTarget = Number(this.activeRouteGraphSummaryData['target' + this.chartMetric].replace(/[^\d\.\-]/g, ""))

                switch (dataType) {
                    case 'targetData':
                        return '#A020F0'

                    case 'forecastData':
                        return (currForecast >= currTarget) ? '#28D791' : '#FD694E'
                        
                    case 'actualData':
                        return (currActual >= currTarget) ? '#1D9E6A' : '#FC2803'
                }
            },

            // PURPOSE: Return an object with color, and icon for graph performance
            routeGraphPerformanceIcon(val) {
                // Turn into digits only
                if(val) {
                    val = Number(val.replace('%', ''))
                }

                if(val >= 100) {
                    return {
                        color: '#28D791',
                        icon: 'mdi-trending-up' 
                    }
                }

                else if(val < 100 && val !== 0) {
                    return { 
                        color: '#FD694E', 
                        icon: 'mdi-trending-down' 
                    }
                }

                return { 
                    color: (this.$vuetify.theme.dark) ? '#fff' : '#000',
                    icon: 'mdi-trending-neutral' 
                }
            },

            // PURPOSE: Calculate current performance metric (actual * scope / 100)
            currentPerformance(activeRouteGraphSummaryData, metric) {
                const actual = Number(activeRouteGraphSummaryData['actual' + metric].replace(/[^\d\.\-]/g, ""))
                const offSet = actual * Number(activeRouteGraphSummaryData['outOfScopePercentage' + metric].replace('%', '')) / 100
                return (actual - offSet)
            },

            // PURPOSE: Calculate current performance metric percentage (performance / target * 100)
            currentPerformancePercent(activeRouteGraphSummaryData) {
                const currentPerformance = Number(this.currentPerformance(activeRouteGraphSummaryData, this.chartMetric))
                const currentTarget = Number(activeRouteGraphSummaryData['target' + this.chartMetric].replace(/[^\d\.\-]/g, ""))
                const result =  currentPerformance / currentTarget * 100

                return ((isNaN(result)) ? 0 : result.toFixed(2)) + '%'
            }
        },
    }
</script>

<style scoped lang="scss">
    // Change border color of collected circle
    $progress-circular-underlay-stroke: rgba(map-get($grey, 'base'), 1) !default;
</style>