<template>
  <v-card-text class="ma-0 pa-3">
    <v-row class="px-8 py-8 ma-0">
      <v-col
        cols="10"
        class="pa-2 ma-0"
        style="height: 20px"
      >
        <v-autocomplete
          v-model="selectedStakeholder"
          :key="selectedStakeholder ? selectedStakeholder.key : 'null'"
          outlined
          auto-select-first
          clearable
          label="Stakeholders"
          :items="stakeholders"
          item-text="friendlyName"
          return-object
          color="primary"
        />
      </v-col>

      <v-col cols="1" style="padding-left: 30px;">
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              v-if='$store.state.Permissions.StakeholderCreate'
              v-bind="attrs"
              v-on="on"
              color="primary"
              block
              @click="addStakeholderToCampaign"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add everyone from stakeholder</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="campaignStakeholdersFromApi.length > 0">
      <v-col cols="12">
        <v-treeview
          :active.sync="selectedAddedStakeholder"
          :items="campaignStakeholdersFromApi ? campaignStakeholdersFromApi : []"
          item-text="stakeholder.friendlyName"
          color="primary"
          activatable
          return-object
        >
          <template v-slot:prepend="{ item }">
            <v-icon> mdi-office-building </v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-icon v-bind="attrs" v-on="on" v-if="loggedInUserStakeholderId == item.stakeholder.id">
                  mdi-crown
                </v-icon>
              </template>
              <span>You're awesome</span>
            </v-tooltip>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    
    <div v-if="campaignStakeholdersFromApi.length > 0">
      <v-divider />
      <v-row class="pa-4 my-2">
        <v-col
          v-if="selectedAddedStakeholder || selectedStakeholder"
          cols="6"
          class="text-left mt-2"
        >
          <span class="text-h3">
            {{selectedAddedStakeholder ? selectedAddedStakeholder[0].stakeholder.friendlyName : selectedStakeholder.friendlyName}}</span>
        </v-col>
        <v-col
          cols="6"
          class="text-right"
         
        >
          <v-btn
            v-if="$store.state.Permissions.StakeholderDelete && selectedAddedStakeholder"
            color="red"
            @click="
              deleteStakeholderFromCampaignDialog = true;
              selectedStakeholderForDelete = selectedAddedStakeholder[0];
            "
            style="margin-right:25px;"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedAddedStakeholder || selectedStakeholder"
        class="pa-0 ma-0"
      >
        <v-col
          v-if="usersAssociatedWithCampaign.length > 0"
          cols="12"
        >
        <!-- Data Table of users of selected stakeholder-->
          <v-data-table
            :headers="headers"
            :items="usersAssociatedWithCampaign"
          >
            <template v-slot:item.added="{ item }">
              <div class="ma-2">
                <v-checkbox
                  v-model="item.addedToCampaign"
                  @change="toggleUserInCampaign(item)"
                />
              </div>
            </template>
            <template v-slot:item.image="{ item }">
              <div class="ma-2">
                <v-avatar size="60">
                  <img
                    :src="
                      item.contactCard.profilePicture
                        ? item.contactCard.profilePicture.urlThumb
                        : 'contact-placeholder.jpg'
                    "
                  >
                </v-avatar>
              </div>
            </template>
            <template v-if='burstId !== null' v-slot:item.isOwner="{ item }">
              <div class="">
                <v-checkbox
                  :on-icon="'mdi-crown'"
                  :off-icon="'mdi-crown-outline'"
                  v-model="item.isOwner"
                  @change="
                    checkIfAdmin(item)
                  "
                />
              </div>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          v-else
          class="text-center"
        >
          <span
            style="text-align: center"
          >No users have been associated with this stakeholder.</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <span
            style="text-align: center"
          >Select a stakeholder to view users.</span>
        </v-col>
      </v-row>
    </div>

    <v-row v-else style="padding-bottom: 20px">
      <v-col class="text-center mt-4">
        <span>No stakeholders have been added to this campaign.</span>
      </v-col>
    </v-row>

    <v-dialog
      v-model="promptCreateUserDialog"
      width="600"
    >
      <v-card class="pa-4">
        <span>This contact has no user account, would you like to create a user
          account for this contact and then add to campaign?</span>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRole"
              label="Select Role For New User*"
              :items="roles"
              item-text="name"
              :item-value="'id'"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="selectedRole === null"
            @click="createUserAcc"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="unselectUser"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteStakeholderFromCampaignDialog"
      width="600"
    >
      <v-card class="pa-4">
        <span>Are you sure you want to remove this stakeholder from this
          campaign?</span>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteStakeholder"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="
              deleteStakeholderFromCampaignDialog = false;
              selectedStakeholderForDelete = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
  import StakeholderController from '@/services/controllers/Stakeholder'
  import UserController from '@/services/controllers/User'
  import CampaignController from '@/services/controllers/Campaign'
  import countryPhonePrefixes from '../../../public/country-phone-prefix.json'
  
  export default {
    props: {
      campaignId: {
        required: true,
      },
      burstId:{
        type: Number,
        default: null,
      },
      ownerId: {
        type: Number,
        default: null,
      },
      type:{
        type: String,
        default: null,
      },
    },

    data: () => ({
      selectedStakeholder: null,
      requiredRule: [(v) => !!v || 'Field is required'],
      stakeholders: [],
      addedStakeholders: [],
      validContactCard: false,
      stakeholderSearchString: null,
      selectedAddedStakeholder: null,
      // User ids
      loggedInUserStakeholderId: null,
      loggedInUserId: null,
      selectedStakeholderForDelete: null,
      selectedRole: null,
      deleteStakeholderFromCampaignDialog: false,
      promptCreateUserDialog: false,
      editContactCardDialog: false,
      createdContactCardId: null,
      countryCodeOptions: countryPhonePrefixes,
      uploadedContactCardLogo: null,
      addContactDialog: false,
      selectedUserForEdit: null,
      // Stakeholders
      usersAssociatedWithCampaign: [],
      campaignStakeholdersFromApi: [],
      originalCampaignStakeholders: [],
      imgUrl: null,
      mediaUploadId: null,
      selectedContact: null,
      roles: [],
      newContactCard: {
        firstName: null,
        surname: null,
        countryCode: null,
        phoneNumber: null,
        email: null,
        jobTitle: null,
      }, 
      headers: [
        {
          sortable: true,
          value: 'added',
        },
        {
          sortable: true,
          value: 'image',
        },
        {
          sortable: true,
          text: 'Forename',
          value: 'contactCard.firstName',
        },
        {
          sortable: true,
          text: 'Surname',
          value: 'contactCard.surname',
        },
        {
          sortable: true,
          text: 'Title',
          value: 'contactCard.jobTitle',
        },
        {
          sortable: false,
          text: 'Phone',
          value: 'contactCard.phoneNumber',
        },
        {
          sortable: false,
          text: 'Email',
          value: 'contactCard.email',
        },
      ],
    }),

    watch: {
      selectedAddedStakeholder (value) {
        if(value !== null) {
        // Get users for stakeholder id
        UserController.searchContactCardsById(value[0].stakeholder.id).then(
          (contactsInStakeholders) => {
            // See which of the users in this stakeholder are added to the campaign.
            CampaignController.getAllUsers(this.campaignId).then((res) => {
              for (var i = 0; i < contactsInStakeholders.data.length; i++) {
                // match id of contact card with Id of users added to campaign
                for (var j = 0; j < res.data.length; j++) {
                  if (contactsInStakeholders.data[i].user) {
                    if (
                      contactsInStakeholders.data[i].user.id === res.data[j].id
                    ) {
                      contactsInStakeholders.data[i].addedToCampaign = true
                    }
                  }
                }
              }
              this.usersAssociatedWithCampaign = contactsInStakeholders.data
              // Function that returns the contact object if a contact in usersAssociatedWithCampaign  has a owner property that is not null, otherwise returns false
              const hasOwner = this.usersAssociatedWithCampaign.find(contact => contact.owner !== null)
              // Check if owner id is in usersAssociatedWithCampaign or a stakeholder hasOwner, if it is, then set the isOwner of the contact to true
              if (this.ownerId || hasOwner) {
                this.usersAssociatedWithCampaign.forEach(contact => {
                  if(contact.owner === null) {
                    contact.isOwner = false
                  }
                  if (contact.contactCard.id === (this.ownerId ? this.ownerId : hasOwner.contactCard.id)) {
                    contact.isOwner = true
                  }
                })
              }
            })
          },
        )
        }
      },
      // When selected stakeholder is changed, check if it is already added to the campaign, if not, get all contacts in stakeholder and check if they are added to the campaign
      selectedStakeholder (value, oldValue) {
        if (value) {
          // Find index of oldvalue in campaignStakeholdersFromApi
          if(oldValue && oldValue.id !== value.id) {
            var index = this.campaignStakeholdersFromApi.findIndex(
              (stakeholder) => stakeholder.stakeholder.id === oldValue.id,
            )
            // If index is not -1, remove oldvalue from campaignStakeholdersFromApi
            if (index !== -1) {
              this.campaignStakeholdersFromApi.splice(index, 1)
            }
          }
          let obj = {
            id: value.id,
            campaignId: this.campaignId,
            stakeholder: value,
            // position property, get the last position in the array and add 1
            position: this.campaignStakeholdersFromApi.length + 1,
            key: value.id // or key: this.campaignStakeholdersFromApi.length + 1
          }
          this.campaignStakeholdersFromApi.push(obj)
          // Set selectedActiveStakeholder to the newValue
          this.selectedAddedStakeholder = [obj]
          // Get users for stakeholder id
          UserController.searchContactCardsById(value.id)
          .then((contactsInStakeholders) => {
            CampaignController.getAllUsers(this.campaignId)
            .then((res) => {
              for (var i = 0; i < contactsInStakeholders.data.length; i++) {
                // match id of contact card with Id of users added to campaign
                for (var j = 0; j < res.data.length; j++) {
                  if (contactsInStakeholders.data[i].user) {
                    if (
                      contactsInStakeholders.data[i].user.id === res.data[j].id
                    ) {
                      contactsInStakeholders.data[i].addedToCampaign = true
                    }
                  }
                }
              }
              this.usersAssociatedWithCampaign = contactsInStakeholders.data
            })
          })
        }
      },
    },
    mounted(){
      if(this.burstId !== null) {
        this.headers.push({
          sortable: false,
          text: 'Burst Owner',
          value: 'isOwner',
        })
      }
        
    },
    async created () {
      this.getRoles()
      await this.getUser()
      this.getAllStakeholders()
      await this.getStakeholdersInCampaign()

    },
    methods: {
      // function that sets the selected user isOwner to true using $set and the rest to false
      async checkIfAdmin(item){
        let arr = [...this.usersAssociatedWithCampaign]
        arr.forEach(contact => {
          if (contact.contactCard.id === item.contactCard.id) {
            contact.isOwner = true
          } else {
            contact.isOwner = false
          }
        })
        this.usersAssociatedWithCampaign = arr
        if(item.isOwner) {
          await CampaignController.setBurstOwner({
            id: this.burstId,
            ownerId: item.user.id
          }).then((res) => {
            // snackbacr succes
            this.$root.$emit('snackbarSuccess', 'Burst owner changed successfully')
            if(this.type === 'manageBurst'){
              this.$root.$emit('updateBurstOwnerManageBursts', res.data, this.burstId)
            } else {
              this.$root.$emit('updateBurstOwner', res.data, this.burstId)
            }
          }).catch((err) => {
            // snackbacr error
            this.$root.$emit('snackbarError', err)
            console.log(err)
          })
        } else {
          // snackbacr error
          this.$root.$emit('snackbarWarning', 'This user is already the owner of this burst')
        }
        this.$nextTick()
      },
      async getUser() {
        const res = await UserController.whoAmI().catch(err => {
          // Catch and display snackbar error message 
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        if(res && res.data && res.data.contactCard) {
          // Set logged in user id and stakeholder id
          this.loggedInUserStakeholderId = res.data.contactCard.stakeholder.id
          this.loggedInUserId = res.data.contactCard.id
        }
      },

      unselectUser () {
        this.promptCreateUserDialog = false
        this.createdContactCardId = null
        this.selectedContact.addedToCampaign = null
      },

      toggleUserInCampaign (item) {
        // Check if selected stakeholder is added to the campaign
        //console.log(this.selectedAddedStakeholder)
        //console.log(this.originalCampaignStakeholders)
        //console.log(item)
        // Check if selectedAddedStakeholder is present in this.originalCampaignStakeholderss
        var stakeholderExists = this.originalCampaignStakeholders.find(stakehold => parseInt(stakehold.stakeholder.id) === parseInt(item.contactCard.stakeholder.id))
        //console.log(stakeholderExists)
        
        // If index is -1, it means that the selected stakeholder is not added to the campaign        
        if (item.addedToCampaign === true && stakeholderExists !== undefined) {
          // Add user to campaign.
          if (item.user) {
            CampaignController.setUsers({
              campaignId: this.campaignId,
              userId: item.user.id,
            })
              .then((res) => {
                this.$root.$emit('snackbarSuccess','Successfully added user to campaign.' )
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.selectedContact = item
            this.promptCreateUserDialog = true
            // If user object is null do popup that says would you like to create user to add to campaign and proceed from there.
            this.createdContactCardId = item.contactCard.id
          }
        } else if (stakeholderExists === undefined && item.addedToCampaign === true) {
          // If stakeholder not added to the campaign, show snackbar error message add stakeholder to campaign before adding users
          this.$root.$emit('snackbarSuccess','Successfully added stakeholder')
          CampaignController.setStakeholders({
            campaignId: this.campaignId,
            campaignStakeholders: this.formatStakeholdersArray(),
          })
          .then(async (res) => {
            if (item.user) {
            CampaignController.setUsers({
              campaignId: this.campaignId,
              userId: item.user.id,
            })
              .then((res) => {
                this.$root.$emit('snackbarSuccess','Successfully added user to campaign.' )
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.selectedContact = item
            this.promptCreateUserDialog = true
            // If user object is null do popup that says would you like to create user to add to campaign and proceed from there.
            this.createdContactCardId = item.contactCard.id
          }
            this.getStakeholdersInCampaignAfterAdd()
            // delete 
          })
          .catch((err) => {
            console.log(err)
          })
        } else {
          // Remove user from campaign.
          CampaignController.deleteUser({
            campaignId: this.campaignId,
            userId: item.user.id,
          })
            .then((res) => {
              this.$root.$emit( 'snackbarSuccess','Successfully removed user from campaign.')
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },

      getStakeholdersInCampaign () {
        CampaignController.getCampaign(this.campaignId).then((res) => {
          this.campaignStakeholdersFromApi = res.data.campaignStakeholders
          //console.log('Initial campaign stakeholder from api')
          //console.log(this.campaignStakeholdersFromApi)
          // Set original campaign stakeholders to the campaign stakeholders from api AS A NEW copy array, without references
          this.originalCampaignStakeholders = [...this.campaignStakeholdersFromApi]
          this.selectedAddedStakeholder = this.campaignStakeholdersFromApi.length !== 0 ? [this.campaignStakeholdersFromApi[0]] : null
          //console.log('Initial original campaign stakeholder from api')
          //console.log(this.originalCampaignStakeholders)
          this.removeAlreadyAddedStakeholders()
        })
      },

      getStakeholdersInCampaignAfterAdd() {
        // Append the selectedAddedstakeholder  to the originalCampaignStakeholders array
        //console.log('-----')
        //console.log(this.originalCampaignStakeholders)
        //console.log(this.selectedAddedStakeholder)
        this.originalCampaignStakeholders.push(this.selectedAddedStakeholder[0])
        this.selectedStakeholder = null
        this.removeAlreadyAddedStakeholders()
        this.$nextTick()
      },
      // FUnction that adds all stakeholder that are already in the campaign and appends the selected stakeholder and returns the array
      formatStakeholdersArray(){
        const allStakeholderArr = []
        // Push all the stakeholders that are already in the campaign
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          allStakeholderArr.push({
            stakeholderId: this.campaignStakeholdersFromApi[i].stakeholder.id,
            position: i,
          })
        }
        return allStakeholderArr
      },

      addStakeholderToCampaign () {
        CampaignController.setStakeholders({
          campaignId: this.campaignId,
          campaignStakeholders: this.formatStakeholdersArray(),
        })
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Stakeholder added to campaign.')
            this.getStakeholdersInCampaignAfterAdd(this.selectedStakeholder.id)
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      createUserAcc () {
        UserController.createUserFromContactCard({
          contactCardId: this.createdContactCardId,
          roleId: this.selectedRole,
        })
          .then((res) => {
            CampaignController.setUsers({
              campaignId: this.campaignId,
              userId: res.data.id,
            })
              .then((res) => {
                this.$root.$emit(
                  'snackbarSuccess',
                  'User account created successfully and user added to campaign.',
                )
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
              })
            this.createdContactCardId = null
            this.selectedRole = null
            this.selectedContact = null
            this.promptCreateUserDialog = false
            this.getAllStakeholders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      getRoles () {
        StakeholderController.getPrimaryRoles().then((res) => {
          this.roles = res.data
        })
      },

      // delete stakeholder FROM CAMPAIGN
      deleteStakeholder () {
        //Find selectedStakeholderForDelete in this.campaignStakeholdersFromApi
        const index = this.campaignStakeholdersFromApi.findIndex(
          (stakeholder) => stakeholder.id === this.selectedStakeholderForDelete.id,
        )
        var copyOriginal = [...this.originalCampaignStakeholders]
        // Splice campaignstakeholderfromapi at index
        this.campaignStakeholdersFromApi.splice(index, 1)
        // Reorder the array
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          this.campaignStakeholdersFromApi[i].position = i
        }
        // Set the stakeholders
        CampaignController.setStakeholders({
          campaignId: this.campaignId,
          campaignStakeholders: this.formatStakeholdersArray(),
        })
          .then(() => {
            this.$root.$emit('snackbarSuccess','Successfully deleted stakeholder')
            //console.log('List')
            //console.log(this.originalCampaignStakeholders)
            //console.log('to delete')
            //console.log(this.selectedStakeholderForDelete.stakeholder.id)
            const obj = copyOriginal.find(stakeholder => stakeholder.stakeholder.id === this.selectedStakeholderForDelete.stakeholder.id)
            //console.log(obj)
            // add the deleted stakeholder back to the list of stakeholders, don;t use the api call
            this.stakeholders.push(obj.stakeholder)
            // Delete the stakeholder from the original campaign stakeholders
            const index = this.originalCampaignStakeholders.findIndex(
              (stakeholder) => stakeholder.stakeholder.id === this.selectedStakeholderForDelete.stakeholder.id,
            )
            this.originalCampaignStakeholders.splice(index, 1)
            this.deleteStakeholderFromCampaignDialog = false
            this.selectedStakeholderForDelete = null
            // selectedAddedStakeholder should return the first entry in campaignStakeholdersFromApi if it exists, if not, return null
            this.selectedAddedStakeholder = this.campaignStakeholdersFromApi.length > 0 ? [this.campaignStakeholdersFromApi[0]] : null
            this.selectedStakeholder = null
            this.usersAssociatedWithCampaign = []
            this.$nextTick()
          })
          .catch((err) => {
              console.log(err)
          })
      },

      removeAlreadyAddedStakeholders () {
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          for (var j = 0; j < this.stakeholders.length; j++) {
            if (
              this.campaignStakeholdersFromApi[i].stakeholder.id ===
              this.stakeholders[j].id
            ) {
              this.stakeholders.splice(j, 1)
            }
          }
        }
      },

      clearSearch () {
        this.stakeholderSearchString = null
        this.getAllStakeholders()
      },

      searchForStakeholder () {
        StakeholderController.searchStakeholders(this.stakeholderSearchString)
          .then((res) => {
            this.stakeholders = res.data
            // Remove stakeholders as an option if they've already been added to the campaign
            this.removeAlreadyAddedStakeholders()
          })
      },

      getAllStakeholders () {
        StakeholderController.allStakeholders()
          .then((res) => {
            this.stakeholders = res.data
            //console.log('All stakeholders: ')
            //console.log(this.stakeholders)
            // Remove stakeholders as an option if they've already been added to the campaign
            this.removeAlreadyAddedStakeholders()
          })
      },
    },
  }
</script>
