<template>
    <div>
      <div id="map" style="width: 100%; height: 100%; border-radius: 4px"></div>
      <div
        style="
          position: absolute;
          width: 100%;
          height: 40px;
          top: 20px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 4px;
          color: white;
          pointer-events: none;
        "
      >
        <span style="position: absolute; left: 20px">Map</span>
        <v-switch
          style="position: absolute; right: 20px; top: -22px; pointer-events: all;"
          v-model="markerSwitch"
          @change="toggleMarkers"
        ></v-switch>
        <button
          small
          color="secondary"
          style="position: absolute;right: 75px;top: -2px;pointer-events: all;"
          @click="toggleFullscreen"
        >
          <v-icon>{{isFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"}}</v-icon>
        </button>
      </div>
    </div>
  </template>
  <script>
  import Vue from "vue";
  import { Loader } from "@googlemaps/js-api-loader";
  import { MarkerClusterer } from "@googlemaps/markerclusterer";
  import PlayoutMapPopup from "./PlayoutMapPopup.vue";
  
  const loader = new Loader({
    apiKey: "AIzaSyDhzM14Txth7ED17gZzJMKNT5dMxel9v5o",
    libraries: ["visualization"],
    mapIds: ["1aebb066bd50a01f"],
  });
  
  export default {
    name: "PlayoutMap",
    props: {
      mapData: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      isFullscreen: false,
  
      // Google Maps
      map: null,
      heatmap: null,
      markerSwitch: true,
      heatmapMaxIntensity: 0,
      isMapLoaded: false,
      markers: [],
      markerCluster: null,
      infoWindow: null,
  
      mapOptions: {
        center: { lat: 54, lng: -3.75 },
        zoom: 5.6,
        disableDefaultUI: true,
        zoomControl: true,
        mapId: "1aebb066bd50a01f",
        styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "landscape",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#181818",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1b1b1b",
            },
          ],
        },
        {
          featureType: "road",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2c2c2c",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8a8a8a",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#373737",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#3c3c3c",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              lightness: -40,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [
            {
              color: "#4e4e4e",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "water",
          stylers: [
            {
              color: "#000000",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#0a0a0a",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d3d3d",
            },
          ],
        },
      ],
      },
    }),
    watch: {
      processedHeatmapData(newData) {
        if (this.heatmap) {
          this.heatmap.setData(newData);
        }
        if (this.markers.length) {
          this.updateMarkers();
        }
      },
    },
    mounted() {
      this.isMapLoaded = false;
      // Initialize the map
      loader
        .load()
        .then(async () => {
          const { Map, InfoWindow } = await google.maps.importLibrary("maps");
          const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
          
          this.map = this.displayMap();
          this.infoWindow = new InfoWindow({
            content: "",
            disableAutoPan: true,
          });
          this.isMapLoaded = true;
        })
        .catch((error) => {
          console.error("Failed to load Google Maps:", error);
        });
    },
    computed: {
    processedHeatmapData() {
      if (!this.mapData || !this.mapData.data || !this.isMapLoaded) {
        return [];
      }

      const data = this.mapData.data;
      const length = data.length;
      const processedData = new Array(length);

      for (let i = 0; i < length; i++) {
        const frame = data[i];
        processedData[i] = {
          location: new google.maps.LatLng(
            frame.location.lat,
            frame.location.lng
          ),
          weight: frame.plays,
        };
      }

      if (
        this.mapData.maxIntensity != null &&
        this.mapData.maxIntensity > this.heatmapMaxIntensity
      ) {
        this.heatmapMaxIntensity = this.mapData.maxIntensity;
        if (this.heatmap) {
          this.heatmap.set("maxIntensity", this.heatmapMaxIntensity);
        }
      }

      return processedData;
    },
    medianPlays() {
      // work out the median number of plays
      if (!this.mapData || !this.mapData.data || !this.isMapLoaded) {
        return 0;
      }

      const data = this.mapData.data;
      const length = data.length;
      const plays = new Array(length);

      for (let i = 0; i < length; i++) {
        const frame = data[i];
        plays[i] = frame.plays;
      }

      const sortedPlays = plays.sort((a, b) => a - b);
      const middleIndex = Math.floor(sortedPlays.length / 2);
      const medianPlays =
        sortedPlays.length % 2
          ? sortedPlays[middleIndex]
          : (sortedPlays[middleIndex - 1] + sortedPlays[middleIndex]) / 2;

      return medianPlays;
    },
  },
  
    methods: {
        toggleFullscreen() {
      const container = this.$el;
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.webkitRequestFullscreen) {
          // Safari
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          // IE/Edge
          container.msRequestFullscreen();
        }
        this.isFullscreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          // Safari
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
        this.isFullscreen = false;
      }
    },
      toggleMarkers() {
        if (this.markerSwitch) {
          this.updateMarkers();
        } else {
          this.markerCluster.clearMarkers();
        }
      },
      displayMap() {
        const mapDiv = document.getElementById("map");
        const map = new google.maps.Map(mapDiv, this.mapOptions);
  
        // Create a heatmap layer and assign it to the heatmap data property
        this.heatmap = new google.maps.visualization.HeatmapLayer({
          data: this.processedHeatmapData,
          map: map,
          radius: 7.5,
          opacity: 0.8,
        });
  
        // Create markers and a marker cluster
        this.markers = this.createMarkers(this.mapData.data);
        this.markerCluster = new MarkerClusterer({ map, markers: this.markers });
  
        return map;
      },
      createMarkers(data) {
        return data.map((item, i) => {
            var markerLabel = item.formatName;

            if (markerLabel.length > 4) {
                // Split by the space, remove words like 'the' and commas, and remove any words which contain no alpha character
                // Convert each word to lowercase before checking if it's in the list to exclude
                markerLabel = markerLabel.split(' ')
                    .filter(word => !['the', 'towers', 'tower', ','].includes(word.toLowerCase()) && word.length > 1)
                    .map(word => word.replace(/[^a-zA-Z0-9]/g, ''))
                    .reduce((a, b) => a.length <= b.length ? a : b);

                // If the label is still too long, trim it to 4 characters
                if (markerLabel.length > 4) {
                    markerLabel = markerLabel.slice(0, 4);
                }
                else if(markerLabel.length < 4) {
                    markerLabel = item.formatName.slice(0, 4);
                }
            }

          const marker = new google.maps.Marker({
            position: { lat: item.location.lat, lng: item.location.lng },
            label: {
                text: markerLabel,
                color: 'lightgray',
                fontSize: "12px",
                // change font family
                fontFamily: 'Roboto',
                fontWeight: 'bold'
            },
            title: item.formatName,
            // change marker icon (found in public\pins\frame-pin.svg) not src
            icon: {
              url: "pins/frame-pin.svg",
              scaledSize: new google.maps.Size(40, 40),
            }
          });
  
          marker.addListener("click", () => {
            this.infoWindow.close(); // Close the previous info window
  
            this.infoWindow.setContent(`<div id="popup-${item.id}"></div>`);
            this.infoWindow.open(this.map, marker);
  
            this.$nextTick(() => {
              new Vue({
                el: `#popup-${item.id}`,
                render: (h) =>
                  h(PlayoutMapPopup, {
                    props: {
                      formatName: item.formatName,
                      mediaOwnerName: item.mediaOwnerName,
                      plays: item.plays,
                      impacts: item.impacts,
                      medianPlays: this.medianPlays,
                    },
                  }),
              });
            });
          });
          return marker;
        });
      },
      updateMarkers() {
      // Clear existing markers from the clusterer
      this.markerCluster.clearMarkers();

      // Create new markers and add them to the clusterer
      this.markers = this.createMarkers(this.mapData.data);
      this.markerCluster.addMarkers(this.markers);
    },
    },
  };
  </script>
  <style scoped></style>