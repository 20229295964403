<template>
    <div>
        <v-card style="height: 100%">
            <!-- Burst title -->
            <v-row class="ma-0" :style="{ backgroundColor: ($vuetify.theme.dark ? '#363636' : '#d6d6d6') }">
                <v-col cols="4">
                    <h3>
                        {{ burst.name.charAt(0).toUpperCase() + burst.name.slice(1) }} - {{ burst.deliveryType.name }}

                        <span v-if="burst.startDate" class="ml-4" style="font-size: 0.65em;" :style="{ fontWeight: `300`, fontSize: `12px` }">
                            {{ burst.startDate | moment }} - {{ burst.endDate | moment }}
                        </span>
                    </h3>
                </v-col>
                <v-col cols="4" style="margin-top: 5px;">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                            class="
                                d-inline-block 
                                text-truncate
                            "
                            style="
                                max-width: 400px;
                                font-size: 0.85em;
                            "
                            v-bind="attrs" v-on="on"
                        >
                            <b>Latest:</b> {{ burst.workflowStatus ? burst.workflowStatus.as  : 'Milestones pending'}}
                        </span>
                        </template>
                        <span>{{ burst.workflowStatus ? burst.workflowStatus.as  : 'Milestones pending' }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <!-- Burst content -->
            <v-card-text>
                <v-row>
                    <v-col cols="8" class="d-flex mt-4" :style="{ position: `relative` }">
                        <!-- Search Screens -->
                        <div>
                            <v-text-field 
                                v-model="searchScreens" 
                                outlined 
                                label="Search" 
                                dense 
                                style="max-width: 430px;"
                                append-icon="mdi-magnify"
                                clearable
                                @click:clear="searchScreens = ''"
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="1" class="d-flex mt-4">
                        <div class="caption">Route Version: </div>
                    </v-col>
                    <v-col cols="2" class="d-flex" :style="{ position: `relative` }">
                        <div class="d-flex mt-3">

                            <!-- Route Versions Dropdown -->
                            <v-select v-model="changingRouteVersionId" :items="routeVersions"
                                :item-text="(item) => 'v' + item.routeReleaseId + '.' + item.routeReleaseRevision + ' [' + item.routeAlgorithmVersion + ']'"
                                item-value="id" label="Route Version" hide-details icon="mdi-routes" single-line outlined
                                dense @click.stop class="text-capitalize my-1" :menu-props="{ closeOnContentClick: true }">
                                <template slot="append-outer">
                                    <v-icon v-show="changingRouteVersionId > 0"
                                        @click="changingRouteVersionId = -1; updateRouteVersion();" color="red" class="mr-2"
                                        :menu-props="{ closeOnContentClick: true }">mdi-close</v-icon>
                                    <v-icon v-show="burst.routeVersionId != changingRouteVersionId"
                                        @click="updateRouteVersion()" color="green" class="mr-2">mdi-check</v-icon>
                                </template>
                            </v-select>
                        </div>
                    </v-col>
                </v-row>
                <!-- Screens -->
                <div v-if="firstLoad">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }" >
                            <v-hover v-slot="{ hover }" >
                                <!-- Sub Issue of an epic display-->
                                <div 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="toggleExpandAll()"
                                    class="text-right"
                                >
                                    <v-icon 
                                        :color="hover || (expanded.length === showCurrScreenList.length) 
                                            ? 'primary' 
                                            : 'white'"
                                    >
                                        mdi-arrow-expand</v-icon>
                                </div>
                            </v-hover>
                        </template>
                        <span>Expand/Close</span>
                    </v-tooltip>
                    <v-data-table 
                        v-model="selected"
                        :headers="impactsHeaders" 
                        :items="showCurrScreenList" 
                        :hide-default-footer="true"
                        :expanded.sync="expanded" 
                        item-class="ma-0 pa-0" 
                        show-expand
                        show-select
                        :items-per-page="-1" 
                    >
                        <!-- Selected -->
                        <template v-slot:item.isSelected="{ item }">
                            <div style="width: 5px">
                                <v-icon @click="selectTargetRow(item)" :color="item.targetStatusAuto">
                                    {{ (item.targetStatusAuto === 'green') ? 'mdi-checkbox-marked-circle-outline' :
                                        'mdi-checkbox-blank-circle-outline' }}
                                </v-icon>
                            </div>
                        </template>

                        <!-- Granularity Dropdown -->
                        <template v-slot:item.route.routeGranularityAudienceId="{ item }">
                            <v-select v-if="!item.route.isATotal" v-model="item.route.routeGranularityAudienceId"
                                :items="granularityAudiences.data" item-text="description" item-value="id"
                                @change="updateRowStatus(item, 'granularity')" @click.stop label="Granularity" hide-details
                                icon="mdi-filter" single-line outlined dense class="text-capitalize my-1" />
                        </template>

                        <!-- Demographics Dropdown -->
                        <template v-slot:item.route.routeDemographicId="{ item }">
                            <v-select v-if="!item.route.isATotal" v-model="item.route.routeDemographicId"
                                :items="demographics.data" item-text="description" item-value="id"
                                @change="updateRowStatus(item, 'demographic')" @click.stop label="Demographics" hide-details
                                icon="mdi-filter" single-line outlined dense class="text-capitalize my-1" />
                        </template>

                        <!-- Custom Query -->
                        <template v-slot:item.route.demographicCustom="{ item }">
                            <v-text-field v-model="item.route.demographicCustom" @click.stop @change="updateRowStatus(item, 'query')"
                                hide-details single-line outlined dense class="text-capitalize my-1" />
                        </template>

                        <!-- Auto Target -->
                        <template v-slot:item.route.targetImpactsAuto="{ item }">
                            <div>
                                <span :class="item.targetStatusAuto + '--text'">{{ item.route.targetImpactsAuto }}</span>
                            </div>
                        </template>

                        <!-- Route Version -->
                        <template v-slot:item.route.routeVersion="{ item }">
                            <div>
                                <span v-if="item.route.routeReleaseId == 0"
                                    :class="item.targetStatusAuto + '--text caption'">N/A</span>
                                <span v-if="item.route.routeReleaseId > 0"
                                    :class="item.targetStatusAuto + '--text caption'">
                                    v{{ item.route.routeReleaseId }}.{{ item.route.routeReleaseRevision }}[{{ item.route.routeAlgorithmVersion }}]
                                </span>
                            </div>
                        </template>

                        <!-- Impacts Manual Target -->
                        <template v-slot:item.route.targetImpactsManual="{ item }">
                            <v-text-field v-model="item.route.targetImpactsManual" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'impacts')" type="number" hide-details
                                single-line outlined dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>
                        <!-- Impacts Manual Actual -->
                        <template v-slot:item.route.actualImpactsManual="{ item }">
                            <v-text-field v-model="item.route.actualImpactsManual" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'impacts')" type="number" hide-details
                                single-line outlined dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>

                        <!-- Plays Manual Target -->
                        <template v-slot:item.route.targetPlaysManual="{ item }">
                            <v-text-field v-model="item.route.targetPlaysManual" type="number" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'plays')" hide-details single-line outlined
                                dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>
                        <!-- Plays Manual Actual -->
                        <template v-slot:item.route.actualPlaysManual="{ item }">
                            <v-text-field v-model="item.route.actualPlaysManual" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'plays')" type="number" hide-details
                                single-line outlined dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>

                        <!-- Hours Manual Target -->
                        <template v-slot:item.route.targetHoursManual="{ item }">
                            <v-text-field v-model="item.route.targetHoursManual" type="number" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'hours')" hide-details single-line outlined
                                dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>
                        <!-- Hours Manual Actual -->
                        <template v-slot:item.route.actualHoursManual="{ item }">
                            <v-text-field v-model="item.route.actualHoursManual" @click.stop
                                @blur="updateRouteImpacts(item, 'manual', 'hours')" type="number" hide-details
                                single-line outlined dense class="text-capitalize my-1" style="max-width: 300px" />
                        </template>

                        <!-- Expanded Info -->
                        <template v-slot:expanded-item="{ headers, item }" >
                            <!-- we must remove padding, margins and min-height from td -->
                            <td :colspan="headers.length"
                                :class="{ 'ma-0 pa-0': true, 'expanded-closing': !transitioned[getItemId(item)] }"
                                style="height: auto;">
                                <v-expand-transition elevation="0">
                                    <!-- Container we'll transition -->
                                    <div v-show="transitioned[getItemId(item)]">
                                        <!-- container for content. replace with whatever you want -->
                                        <div class="pa-2" style="min-height: 50px;">
                                            <v-row class='justify-center' no-gutters>
                                                <!-- Slot Length -->
                                                <v-col 
                                                    cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 1 5%;' : ''"
                                                    tile outlined
                                                >
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Slot Length</label>
                                                        <label class="d-flex justify-center text-center" :style="{ color: (item.slotDurationSeconds === 0) ? 'grey' : 'white'}">{{ slotLength(item) }}s</label>
                                                    </div>
                                                </v-col>
                                                <!-- Slot Break Length -->
                                                <v-col 
                                                    cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined
                                                >
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Slot Break Length</label>
                                                        <label class="d-flex justify-center text-center" :style="{ color: (item.slotBreakSeconds === 0) ? 'grey' : 'white'}">{{ slotBreakLength(item) }}s</label>
                                                    </div>
                                                </v-col>
                                                <!-- SOT -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">SOT %</label>
                                                        <label class="d-flex justify-center text-center"
                                                            contenteditable
                                                            @blur="updateContent($event, item)" 
                                                            style="
                                                                width:80%;
                                                                margin: 0 auto;
                                                            "
                                                        >
                                                            {{ calcSOT(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <!-- Frame Count -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Frame Count</label>
                                                        <label class="d-flex justify-center text-center">
                                                            <!-- click action does not append Locations-->
                                                            <a @click.stop="
                                                                $router.push({
                                                                    name: 'Artwork',
                                                                    query: {
                                                                        sid: item.screen.id,
                                                                        cbsid: item.id,
                                                                        cid: $route.query.cid,
                                                                        bid: burst.id,
                                                                        tab: 'locations'
                                                                    },
                                                                });
                                                            " style="text-decoration: none"
                                                                class="mouse-pointer">{{ item.locationCount - 1 }}</a>
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <!-- Live Dates -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Start Date</label>
                                                        <label class="d-flex justify-center text-center">
                                                            {{ getCampaignStartDate(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">End Date</label>
                                                        <label class="d-flex justify-center text-center">
                                                            {{ getCampaignEndDate(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>

                                                <!-- Campaign Times -->
                                                <v-col cols="12" tile outlined v-if="item.screenEvents.length > 0">
                                                    <v-card elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Campaign Slot Times</label>
                                                        <div class="text-center flex-column">
                                                            <v-row class="flex-column" no-gutters>
                                                                <v-col cols="12">
                                                                    <v-data-table
                                                                    :headers="screenSchedulingEditHeaders"
                                                                    :items="item.screenEvents"
                                                                    :hide-default-footer="true"

                                                                    >
                                                                    <!--
                                                                    <template v-slot:item.actions="{ item }">
                                                                        <v-btn
                                                                        class="m-2"
                                                                        icon
                                                                        small
                                                                        color="primary"
                                                                        @click="deleteExistingScheduledEventFromScreen(item)"
                                                                        >
                                                                        <v-icon> mdi-delete </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    -->
                                                                    </v-data-table>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-expand-transition>
                            </td>
                        </template>
                        <template v-slot:item.data-table
                        -expand="props">
                            <v-icon :class="{
                                'v-data-table__expand-icon': true,
                                'v-data-table__expand-icon--active': props.isExpanded && transitioned[getItemId(props.item)]
                            }" @click="toggleExpand(props)">
                                mdi-chevron-down
                            </v-icon>
                        </template>

                    </v-data-table>

                    <div class="mt-10">
                        <v-btn :loading="submittingNewTargets" :disabled="!showUpdateTargetsBtn"
                            @click="updateTargets()">Update Auto Targets</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
// Controllers
import RouteController from '@/services/controllers/Route'
import CampaignController from '@/services/controllers/Campaign'

// Search Mixins
import collectionSearch from '@/mixins/collection/search'
import roundNumber from '@/mixins/helpers/roundNumber'

// Date formatter
import moment from 'moment'

export default {
    mixins: [roundNumber],

    props: {
        campaign: {
            type: Object,
            required: true,
        },

        burst: {
            type: Object,
            required: true,
        },

        chartMetric: {
            type: String,
            required: true,
        },

        chartDataType: {
            type: String,
            required: true,
        },

        chartMediaOwners: {
            type: Array,
            required: true,
        },

        chartScreens: {
            type: Array,
            required: true,
        },
    },

    filters: {
        moment: function (date) {
            return moment(date).format('DD MMMM YYYY')
        },
    },

    data() {
        return {
            submittingNewTargets: false,
            firstLoad: false,
            updatingScreenList: false,
            searchScreens: '',
            originalScreenList: null,
            updatedScreenList: null,
            demographics: [],
            selected: [],
            granularityAudiences: [],
            dashboardTargets: null,
            routeVersions: [],
            changingRouteVersionId: 0,
            expanded: [],
            transitioned: [],
            closeTimeouts: {},
            RepeatIntervals: ['Specific Days', 'Daily'],
            screenSchedulingEditHeaders: [
            {
                text: 'Event type',
                align: 'start',
                sortable: true,
                value: 'repeatInterval',
            },
            {
                text: 'Day Of Week',
                align: 'start',
                sortable: true,
                value: 'dayOfWeek',
            },
            {
                text: 'Start Time',
                align: 'start',
                sortable: true,
                value: 'startTime',
            },
            {
                text: 'End Time',
                align: 'start',
                sortable: true,
                value: 'endTime',
            }
            /*,
            {
                text: 'Actions',
                align: 'start',
                sortable: false,
                value: 'actions',
            },*/
            ],
        }
    },
    watch: {
        expanded (oldVal, newVal) {
            // if newVal is present in oldVal, then close it, if not then open it (and set the transitioned)
            newVal.forEach((item,id) => {
                if (oldVal.indexOf(item.id) === -1) {
                    this.closeExpand(item)
                }
            })
            oldVal.forEach((item,id)  => {
                if (newVal.indexOf(item.id) === -1) {
                    clearTimeout(this.closeTimeouts[item.id])
                    this.$nextTick(() => this.$set(this.transitioned, item.id, true))
                }
            })
        },
    },

    computed: {
        showCurrScreenList: {
            get() {
                if (this.updatedScreenList && this.updatedScreenList.length > 0 && !this.updatingScreenList) {
                    let collection = collectionSearch(this.updatedScreenList, 'all', this.searchScreens)

                    // Filter by formats / screens
                    if (this.chartScreens.length > 0) {
                        collection = []

                        this.chartScreens.forEach(screenID => {
                            this.updatedScreenList.forEach(item => {
                                if (screenID === item.screen.id)
                                    collection.push(item)
                            })
                        })
                    }

                    // Filter by media owners
                    else if (this.chartMediaOwners.length > 0) {
                        collection = []

                        this.chartMediaOwners.forEach(mediaOwnerID => {
                            this.updatedScreenList.forEach(item => {
                                if (mediaOwnerID === item.screen.mediaOwner.id)
                                    collection.push(item)
                            })
                        })
                    }
                    return collection
                }
                return []
            }
        },

        impactsHeaders: {
            get() {
                let result = []

                //  Add first 3 columns (default)
                result.push({ text: '', align: 'start', sortable: false, value: 'isSelected' })
                result.push({ text: 'Media Owner', align: 'start', sortable: true, value: 'screen.mediaOwner.name' })
                result.push({ text: 'Name', align: 'start', sortable: false, value: 'screen.name' })

                // Add columns based on chartMetric
                if (this.chartMetric.toLowerCase() === 'impacts') {
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        result.push({ text: 'Audience Granularity', align: 'start', sortable: false, value: 'route.routeGranularityAudienceId' })
                        result.push({ text: 'Audience Demographics', align: 'start', sortable: false, value: 'route.routeDemographicId' })

                        result.push({ text: 'Audience Query', align: 'start', sortable: false, value: 'route.demographicCustom' })

                        result.push({ text: 'Route Version', align: 'start', sortable: false, value: 'route.routeVersion' })

                        // Add Auto and Manual target results 
                        result.push({ text: 'Impacts Auto Target', align: 'start', sortable: false, value: 'route.targetImpactsAuto' })
                    }
                    else {
                        result.push({ text: 'Impacts Manual Target', align: 'start', sortable: false, value: 'route.targetImpactsManual' })
                        result.push({ text: 'Impacts Manual Actual', align: 'start', sortable: false, value: 'route.actualImpactsManual' })
                    }
                }

                else if (this.chartMetric.toLowerCase() === 'plays') {
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        result.push({ text: 'Plays Auto Target', align: 'start', sortable: false, value: 'route.targetPlaysAuto' })
                    }
                    else {
                        result.push({ text: 'Plays Manual Target', align: 'start', sortable: false, value: 'route.targetPlaysManual' })
                        result.push({ text: 'Plays Manual Actual', align: 'start', sortable: false, value: 'route.actualPlaysManual' })
                    }
                }

                else if (this.chartMetric.toLowerCase() === 'hours') {
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        result.push({ text: 'Hours Auto Target', align: 'start', sortable: false, value: 'route.targetHoursAuto' })
                    }
                    else {
                        result.push({ text: 'Hours Manual Target', align: 'start', sortable: false, value: 'route.targetHoursManual' })
                        result.push({ text: 'Hours Manual Actual', align: 'start', sortable: false, value: 'route.actualHoursManual' })
                    }
                }
                result.push({ text: '', value: 'data-table-expand' })
                return result
            }
        },

        showUpdateTargetsBtn: {
            get() {
                let result = false
                this.showCurrScreenList.forEach(item => {
                    if (item.targetStatusAuto === 'amber')
                        result = true
                })

                return result
            }
        },
        calcSOT() {
            return (item) => {
                let sot = 0
                let slotBreakSeconds = item.slotBreakSeconds === 0 ? item.screen.slotBreakSeconds : item.slotBreakSeconds
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                sot = (slotDurationSeconds / (slotDurationSeconds + slotBreakSeconds)) * 100
                return Math.round(sot * 100) / 100
            }
        },
        //
        slotLength() {
            return (item) => {
                let slotLength = 0
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                let sot = this.calcSOT(item)
                let slotBreakLength = (((100 / sot) * slotDurationSeconds) - slotDurationSeconds)
                slotLength = (-slotBreakLength * sot) / (sot - 100)
                // check if slotLength is a number
                if (isNaN(slotLength)) {
                    return item.screen.slotDurationSeconds
                }
                return Math.round(slotLength * 100 / 100)
            }
        },
        reverseCalcSOT() {
            return (sot, duration) => {
                let slotbreak = (100 * duration - sot * duration) / sot
                return slotbreak
            }
        },
        // Get slot break length from sot
        slotBreakLength() {
            return (item) => {
                let slotBreakLength = 0
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                let sot = this.calcSOT(item)
                slotBreakLength = (((100 / sot) * slotDurationSeconds) - slotDurationSeconds)
                return Math.round(slotBreakLength * 100 / 100)
            }
        },
        getCampaignStartDate() {
            return (item) => {
                let campaignBurstScreenId = item.route.campaignBurstScreenId
                let campaignBurstScreen = this.burst.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId)
                var startDate = campaignBurstScreen.startDate ?? this.burst.startDate ?? this.campaign.startDate;
                startDate = startDate ? moment(startDate).format('DD MMM YYYY') : 'Not Set'
                return startDate
            }
        },
        getCampaignEndDate() {
            return (item) => {
                let campaignBurstScreenId = item.route.campaignBurstScreenId
                let campaignBurstScreen = this.burst.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId)
                var endDate = campaignBurstScreen.endDate ?? this.burst.endDate ?? this.campaign.endDate;
                endDate = endDate ? moment(endDate).format('DD MMM YYYY') : 'Not Set'
                return endDate
            }
        },
        // Campaign slot events
        getCampaignEventType() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.repeatInterval
            }
        },
        getCampaignEventDate() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.dayOfWeek
            }
        },
        getCampaignSlotStartTime() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.startTime
            }
        },
        getCampaignSlotEndTime() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.endTime
            }
        },
    },

    async created() {
        // Get dropdown values
        this.demographics = await RouteController.getDemographics()
        this.granularityAudiences = await RouteController.getGranularityAudiences()
        let rvers = await RouteController.getRouteVersions()
        this.routeVersions.push(...rvers.data)
    },

    async mounted() {
        // Clone prop so we can change a local copy before saving
        let cleanedCampaignBurstScreens = this.burst.campaignBurstScreens.map(cbs => {
            delete cbs.screen.mediaOwner.screen // delete circular reference
            return cbs
        })
        this.updatedScreenList = cleanedCampaignBurstScreens
        this.changingRouteVersionId = this.burst.routeVersionId

        // Get targets
        await this.mapDashboardTargets()

        // Clone original mapped screen list
        this.originalScreenList = JSON.parse(JSON.stringify(this.updatedScreenList))

        // Component is finished mounting and can be shown on screen now
        this.firstLoad = true;
    },

    methods: {
        toggleExpandAll() {
            // Add every screen to expanded list or remove every screen from expanded list
            if (this.expanded.length === 0) {
                this.updatedScreenList.forEach((item, index) => {
                    // use toggleExpand to add to expanded list
                    this.expanded.push(item)
                    this.$nextTick(() => this.$set(this.transitioned, item.id, true))
                })
            }
            else {
                this.expanded = []
                this.transitioned = []
            }
        
        },
        async deleteExistingScheduledEventFromScreen (item, screenEvents) {
            const index = screenEvents.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))

            screenEvents.splice(index, 1)

            await CampaignController.editBurstScreen(burstObj)
            .then((res) => {
                this.$root.$emit('snackbarSuccess', 'Successfully removed scheduled event from screen.')
            })
            .catch((err) => {
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        },
        async updateContent(e, item) {
            const inputText = parseFloat(e.target.innerHTML)
            if (!item || !inputText) return
            const slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
            item.slotBreakSeconds = this.reverseCalcSOT(inputText, slotDurationSeconds)
            // update campaignBurstScree obj
            let burstObj = {
                id: item.id,
                slotBreakSeconds: Math.round(item.slotBreakSeconds), // TODO: remove Math.floor() when backend is fixed
                slotDurationSeconds: item.slotDurationSeconds,
                startDate: item.startDate,
                endDate: item.endDate,
                triggerList: item.triggerList
            }
            await CampaignController.editBurstScreen(burstObj).then((res) => {
              item = res.data
              this.$nextTick()
              //this.selectedTriggers = res.data.triggerList
              this.$root.$emit('snackbarSuccess', 'Successfully updated SOT')
            })
            .catch((err) => {
              this.$root.$emit('snackbarError',''+ err.response.data.message)
            })
            
        },
        getItemId(item) {
            return item.id // Must be uid of record (would be nice if v-data-table exposed a "getItemKey" method)
        },
        toggleExpand(props) {
            const item = props.item
            const id = this.getItemId(item)
            if (props.isExpanded && this.transitioned[id]) {
                // If we're expanded and not in the process of closing, close
                this.closeExpand(item)
            } else {
                // If we're closed or in the process of closing, expand
                // Stop us from closing if a close transition was started
                clearTimeout(this.closeTimeouts[id])
                // Tell v-data-table to add the expansion content for the item
                props.expand(true)
                // Show expansion content with transition animation after it's had time to get added to the DOM
                this.$nextTick(() => this.$set(this.transitioned, id, true))
            }
        },
        closeExpand(item) {
            const id = this.getItemId(item)
            // Mark that this item is in the process of closing
            this.$set(this.transitioned, id, false)
            // Remove expansion content from DOM after transition animation has had enough time to finish
            //this.closeTimeouts[id] = setTimeout(() => this.$refs.vDataTable.expand(item, false), 600)
        },
        async mapDashboardTargets() {
            const { data } = await RouteController.getDashboardTargets(this.burst.id)
            data.forEach(item => {
                this.updatedScreenList.forEach((elem, index) => {
                    if (item.campaignBurstScreenId == elem.id) {
                        // Initial data
                        this.updatedScreenList[index].route = { ...item }
                        this.updatedScreenList[index].isSelected = false
                        //  Initial color
                        this.updatedScreenList[index].targetStatusAuto = (Number(this.updatedScreenList[index].route['target' + this.chartMetric + 'Auto']) > 0)
                            ? this.updatedScreenList[index].targetStatusAuto = "green"
                            : this.updatedScreenList[index].targetStatusAuto = "red"
                    }
                })
            })
        },

        async updateRouteVersion() {
            this.burst.routeVersionId = this.changingRouteVersionId

            // convert to CampaignBurstUpdate obj
            let burstObj = {
                id: this.burst.id,
                name: this.burst.name,
                routeVersionId: this.burst.routeVersionId,
                startDate: this.burst.startDate,
                endDate: this.burst.endDate,
            }

            await CampaignController.editBurst(burstObj)
                .then(async () => {
                    this.$root.$emit('snackbarSuccess', 'Default route version updated.')
                })
                .catch((err) => {
                    this.$root.$emit('snackbarError', err)
                })
        },

        async updateTargets() {
            this.submittingNewTargets = true

            for (let index = 0; index < this.updatedScreenList.length; index++) {
                const item = this.updatedScreenList[index]
                if (item.targetStatusAuto === 'amber'){
                    await this.updateRouteImpacts(item, 'auto', this.chartMetric.toLowerCase()).catch(error => { })
                }
            }
            this.submittingNewTargets = false
        },

        async updateRouteImpacts(screen, mode, type) {
            // Screen to change
            const updatedScreen = this.updatedScreenList.find((item) => item.id === screen.id)
            const originalScreen = this.originalScreenList.find((item) => item.id === screen.id)
            if (JSON.stringify(originalScreen) !== JSON.stringify(updatedScreen)) {
                // Map into object to send to the api
                const newScreen = {
                    campaignBurstScreenId: updatedScreen.id,
                    target: updatedScreen.route[ 'target' + type.charAt(0).toUpperCase() + type.slice(1) + 'Manual'],
                    actual: updatedScreen.route[ 'actual' + type.charAt(0).toUpperCase() + type.slice(1) + 'Manual'],
                    mode: mode,
                    type: type,
                    routeDemographicId: updatedScreen.route.routeDemographicId,
                    routeGranularityAudienceId: updatedScreen.route.routeGranularityAudienceId,
                    demographicCustom: updatedScreen.route.demographicCustom,
                    routeVersionId: this.burst.routeVersionId
                }

                // Capture the response from the api
                const { data } = await RouteController.updateDashboardTargets(newScreen).catch(err => {
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })

                // Update current with new data from the response
                if (data) {
                    this.updatingScreenList = true

                    this.updatedScreenList.forEach((elem, index) => {
                        if (elem.id == data.campaignBurstScreenId) {
                            // Update the updated list with the new data
                            this.updatedScreenList[index].route = { ...data }
                            this.updatedScreenList[index].targetStatusAuto = 'green'
                            this.updatedScreenList[index].isSelected = false

                            //  Update original with new data
                            this.originalScreenList[index].route = { ...data }
                            this.originalScreenList[index].targetStatusAuto = 'green'
                            this.originalScreenList[index].isSelected = false
                        }
                    })

                    this.updatingScreenList = false
                    this.$root.$emit('reloadRouteGraph')
                }
            }
        },

        selectTargetRow(item) {
            this.updatingScreenList = true

            if(this.selected.length > 0 && this.selected.find(selectedItem => selectedItem.id === item.id)){
                this.selected.forEach((selectedItem, index) => {
                // Update selected row status
                selectedItem.isSelected = !selectedItem.isSelected

                const selectedCampaignBurstScreenId = selectedItem.route.campaignBurstScreenId;
                // Update route data
                selectedItem.route = this.originalScreenList.find(item => item.route.campaignBurstScreenId === selectedCampaignBurstScreenId).route;

                //  Update select box color
                if (!selectedItem.isSelected && selectedItem.route['target' + this.chartMetric + 'Auto'] > 0)
                    selectedItem.targetStatusAuto = 'green'

                else if (selectedItem.isSelected)
                    selectedItem.targetStatusAuto = 'amber'

                else
                    selectedItem.targetStatusAuto = 'red'
                });
            }
            else{
                this.updatedScreenList.forEach((elem, index) => {
                if (elem.id === item.id) {
                    // Update selected row status
                    this.updatedScreenList[index].isSelected = !this.updatedScreenList[index].isSelected

                    // Update route data
                    this.updatedScreenList[index].route = JSON.parse(JSON.stringify(this.originalScreenList[index].route))

                    //  Update select box color
                    if (!this.updatedScreenList[index].isSelected && this.updatedScreenList[index].route['target' + this.chartMetric + 'Auto'] > 0)
                        this.updatedScreenList[index].targetStatusAuto = 'green'

                    else if (this.updatedScreenList[index].isSelected)
                        this.updatedScreenList[index].targetStatusAuto = 'amber'

                    else
                        this.updatedScreenList[index].targetStatusAuto = 'red'
                }
            })
            }

            this.updatingScreenList = false
        },

        updateRowStatus(item, event) {
            this.updatingScreenList = true

            if(this.selected.length > 0 && event && this.selected.find(selectedItem => selectedItem.id === item.id)){
                switch(event) {
                    case "granularity":
                        this.selected.forEach((selectedItem) => {
                            selectedItem.route.routeGranularityAudienceId = item.route.routeGranularityAudienceId
                            selectedItem.isSelected = true;
                            selectedItem.targetStatusAuto = 'amber'
                        });
                        break;
                    case "demographic":
                        this.selected.forEach((selectedItem) => {
                            selectedItem.route.routeDemographicId = item.route.routeDemographicId
                            selectedItem.isSelected = true;
                            selectedItem.targetStatusAuto = 'amber'
                        });
                        break;
                    case "query":
                        this.selected.forEach((selectedItem) => {
                            selectedItem.route.demographicCustom = item.route.demographicCustom
                            selectedItem.isSelected = true;
                            selectedItem.targetStatusAuto = 'amber'
                        });
                        break;
                    default:
                        // code block
                    }
            }
            else{
                // old code only updates one item
                this.updatedScreenList.forEach((elem, index) => {
                    if (elem.id === item.id) {
                        this.updatedScreenList[index].isSelected = true
                        this.updatedScreenList[index].targetStatusAuto = 'amber'
                    }
                })
            }
            
            this.updatingScreenList = false
        },

        resetTargetState() {
            this.mapDashboardTargets()
            this.updatedScreenList = JSON.parse(JSON.stringify(this.originalScreenList))
        },
    }
}
</script>
<style scoped>
.expanded-closing {
    border-bottom: none !important;
}
</style>