<template>
    <div>
        <v-card style="height: 100%" class="mb-6">
            <v-card-text>
                <v-row>
                    <v-data-table 
                        :headers="headers"
                        :items="getMediaOwnersList"
                        :hide-default-footer="false"
                        :items-per-page="10" 
                        :expanded.sync="expanded"
                        show-expand
                        style="width: 100%"
                    >
                        <!-- Name + Logo -->
                        <template v-slot:name="{ item }">
                            <div v-if="item.logo !== null">
                                <v-img :src="item.logo" style="width: 60px; height: 50px"></v-img>
                            </div>
                            <div v-else style="max-width: 100px;">
                                {{ item.name }}
                            </div>
                        </template>

                        <!-- Formats -->
                        <template v-slot:item.format="{ item }">
                            <div style="max-width: 100px;">
                                {{ item.screen.length }}
                            </div>
                        </template>

                        <!-- kpi -->
                        <template v-slot:item.kpi="{ item }">
                            <div style="max-width: 150px;" class="d-flex justify-end ml-3">
                                <!-- Select button with 3 values to choose from-->
                                <v-select
                                    :items="kpiList"
                                    v-model="item.kpi"
                                    item-value="name"
                                    label="KPI"
                                    outlined
                                    dense
                                    hide-details
                                    @change="changeKPI(item)"
                                >
                                </v-select>
                            </div>
                        </template>

                        <!-- Dates -->
                        <!-- TODO: change dates to display the earliest startDate present and the latest endDate present -->
                        <template v-slot:item.date="{ item }">
                            <div>
                                <small style="font-size: x-small;">{{ getEarliestDate(getMediaOwnerDates(item)) | moment }} - {{ getLatestDate(getMediaOwnerDates(item)) | moment }}</small>
                            </div>
                        </template>

                        <!-- Booked -->
                        <template v-slot:item.booked="{ item }">
                            <div>
                                <small>{{ calculateBookedTotal(item) | addCommas }}</small>
                            </div>
                        </template>

                        <!-- Delivered -->
                        <template v-slot:item.deliver="{ item }">
                            <div>
                                <small>{{ calculatePlayoutTotal(item) | addCommas }}</small>
                            </div>
                        </template>

                        <!-- Performance -->
                        <template v-slot:item.performance="{ item }">
                            <div>
                                <small v-if="type.includes('playout')">{{ calcPerformance(calculateBookedTotal(item), calculatePlayoutTotal(item)) }}</small>
                                <small>%</small>
                            </div>
                        </template>

                        <!-- Expansion-->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headersExpanded.length" style="width: 100%" class="ma-0 pa-0">
                                <v-data-table
                                    :headers="headersExpanded"
                                    :items="item.screen"
                                    hide-default-footer
                                    :items-per-page="100"
                                    style="width: 100%;"
                                >
                                    <!-- Name -->
                                    <template v-slot:item.name="{ item }" >
                                        <div style="max-width: 200px;">{{ item.name }}</div>
                                    </template>
                                    
                                    <!-- Dates -->
                                    <template v-slot:item.date="{ item }">
                                        <small style="font-size: x-small;">{{ getEarliestDate(getScreenDates(item)) | moment }} - {{ getLatestDate(getScreenDates(item)) | moment }}</small>
                                    </template>
                                    <!-- Booked -->
                                    <template v-slot:item.booked="{ item }">
                                        <small>
                                            {{ calculateScreenBookedTotal(item) | addCommas }}
                                        </small>
                                    </template>

                                    <!-- Delivered / Actuals -->
                                    <template v-slot:item.deliver="{ item }">
                                        <small>
                                            <div :contenteditable="hasAnalyticsEditPermission && type.includes('tracking')" @blur="(e) => updateTarget(item, e, 'deliver')">
                                                <span v-if="type.includes('playout')">{{ calculateScreenPlayoutTotal(item) | addCommas}}</span>
                                            </div>
                                        </small>
                                    </template>

                                    <!-- Performance -->
                                    <template v-slot:item.performance="{ item }">
                                        <div>
                                            <small v-if="type.includes('playout')">{{ calcPerformance(calculateScreenBookedTotal(item), calculateScreenPlayoutTotal(item)) }}</small>
                                            <small>%</small>
                                        </div>
                                    </template>
                                    <!-- KPI -->
                                    <template v-slot:item.kpi="{item}">
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- comment dialog -->
        <v-dialog 
            v-model="showCommDialog" 
            max-width="500px"
            v-if="selectedItem"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <v-card v-if="selectedItem.note" class="
                        d-flex 
                        justify-center 
                        align-center 
                        flex-column 
                        text-center
                        mb-4
                        pa-4
                        border
                    ">
                        <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                        ">
                            <v-col cols="4">
                                <small>Format: {{ findScreenNamebyCBSID(selectedItem.note.campaignBurstScreenId) }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>Mode: {{ selectedItem.note.dashboardMode }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>KPI: {{ selectedItem.note.dashboardKPI }}</small>
                            </v-col>
                        </v-row>
                        {{ selectedItem.note.note }}
                    </v-card>
                    <v-textarea
                        v-if="hasAnalyticsEditPermission"
                        v-model="comment"
                        label="Note"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        class="mt-4"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!hasAnalyticsEditPermission" color="primary" text @click="addNote(selectedItem)">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Comment dialog -->
        <v-dialog 
            v-model="showCommDialogMO" 
            max-width="500px"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <div v-if="selectedMoNotes.length > 0">
                        <v-card v-for="note in selectedMoNotes" :key="note.id"
                        class="
                            d-flex 
                            justify-center 
                            align-center 
                            flex-column 
                            text-center
                            mb-4
                            pa-4
                            border
                        ">
                            <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                            ">
                                <v-col cols="4">
                                    <small>Format: {{ findScreenNamebyCBSID(note.campaignBurstScreenId) }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>Mode: {{ note.dashboardMode }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>KPI: {{ note.dashboardKPI }}</small>
                                </v-col>
                            </v-row>
                           <span>{{ note.note }}</span>
                        </v-card>
                    </div>
                    <div v-else>
                        No notes
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// External libraries
import moment from 'moment'
// Controllers
import RouteController from '@/services/controllers/Route'
import ReportingController from '@/services/controllers/Reporting'
/** Components/Modals */
export default {
    components: {
    },
    name: 'PlayoutReport',
    props: {
        campaign: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'report'
        }
    },
    provide: function () {
        return {
        closeIssue: this.closeIssue,
        }
    },
    data() {
        return {
            headers: [
                { text: 'Media Owner', value: 'name', width: '100px', align: 'left', sortable: false },
                { text: 'Formats', value: 'format', width: '100px', align: 'center', sortable: false },
                { text: 'Dates', value: 'date', width: '100px', align: 'center', sortable: false },
                { text: 'KPI', value: 'kpi', width: '200px', align: 'center', sortable: false },
                { text: 'Booked', value: 'booked', width: '100px', align: 'center', sortable: false },
                { text: this.type.includes('graph') ? 'Actuals' : 'Delivered', value: 'deliver', width: '100px', align: 'center', sortable: false },
                { text: 'Performance %', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            headersExpanded: [
                { text: '', value: 'name', width: '260px', align: 'left', sortable: false },
                { text: '', value: 'date', width: '260px', align: 'left', sortable: false },
                { text: '', value: 'kpi', width: '188px', align: 'center', sortable: false },
                { text: '', value: 'booked', width: '100px', align: 'left', sortable: false },
                { text: '', value: 'deliver', width: '140px', align: 'left', sortable: false },
                { text: '', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            expanded: [],
            showCommDialog: false,
            showCommDialogMO: false,
            selectedItem: null,
            comment: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            dashboardTargets: [],
            kpiList: ['Actual Plays - Playout'],
            notesData: [],
            datePicker: null,
            displayDate: null,
            targetFullRange: false,
            selectedMoNotes: [],
            openIssueModal: false,
            selBurst: null,
            playoutDashboardStats: []
        } 
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
        addCommas: function (number) {
            console.log("=> addCommas", number)
            const num = parseFloat(number).toFixed(2);
            // Convert the number to a string
            const numberString = num.toString();
            // Split the number into integer and decimal parts
            const [integerPart, decimalPart] = numberString.split('.');
            // Add commas to the integer part
            const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            // Combine the integer part with the decimal part (if any)
            const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
            // Return the result
            return result;
        },
    },
    watch: {
        expanded: function(val, oldVal) {
            // Call the function after 100 ms so the DOM can update
            setTimeout(() => {
                this.addPasteEventListener()
            }, 100)
        }
    },
    beforeMount() {
        
    },
    computed: {
        removeCommas(){
            return (number) => {
                return number.replace(/,/g, '')
            }
        },
        datesMatchBurst() {
            return (burst) => {
                // ensure all dates match within the burst
                let allMatch = true;
                burst.screen.forEach(cbs => {
                    if(!this.datesMatch(cbs))
                        allMatch = false
                })
                return allMatch
            };
        },
        getCampaignBurstDates() {
            return () => {
                let startDate = this.burst.startDate || this.campaign.startDate;
                let endDate = this.burst.endDate || this.campaign.endDate;
                return { startDate, endDate }
            }
        },
        getScreenDates() {
            return (screen) => {
                console.log("=> getScreenDates", screen.mediaOwnerId, screen.name, screen.kpi);
                let metricGroup = this.getMetricGroup(screen.mediaOwnerId,screen.name);
                if(!metricGroup) return [];
                return this.getMetricGroupDates(metricGroup, screen.kpi)
            };
        },
        getMediaOwnerDates() {
            return (mediaOwner) => {
                let dates = []
                mediaOwner.screen.forEach(screen => {
                    dates = [...this.getScreenDates(screen)]
                })
                return dates
            };
        },
        getEarliestDate() {
            return (dates) => {
                // Iterate through item.screen and append each date to an array                
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                let earliestDate = dates.reduce((a, b) => {
                    return moment(a).isBefore(moment(b)) ? a : b
                })
                return earliestDate
            };
        },
        getLatestDate(){
            return (dates) => {
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                console.log("=>dates",dates)
                let latestDate = dates.reduce((a, b) => {
                    return moment(b).isAfter(moment(a)) ? b : a
                })
                return latestDate
            };
        },
        getMediaOwnersList() {
            let mediaOwners = [];
            if (!this.playoutDashboardStats) return mediaOwners;
            // Get all the unique media owners from the playoutDashboardStats
            this.playoutDashboardStats.forEach((metricGroup) => {
                // Check if media owner is already in the list
                var mo = metricGroup.mediaOwner;
                console.log("=> mo", mo.friendlyName);
                let isMediaOwnerInList = mediaOwners.find((mediaOwner) => mediaOwner.id === mo.id);
                if (!isMediaOwnerInList) mediaOwners.push(mo)
                delete mo.screen; // it will get created in the next step
            });
            

            // Iterate through this.burstCopy and add the screens to the mediaOwners if the screen.screen.mediaOwner is in the mediaOwners list
            this.playoutDashboardStats.forEach((metricGroup) => {
                var mo = metricGroup.mediaOwner;
                let mediaOwner = mediaOwners.find((owner) => owner.id === mo.id);
                if (mediaOwner) {
                    var moScreen = mediaOwner.screen;
                    if (!moScreen) {
                        mediaOwner.screen = [];
                    }
                    var screen = {
                        name: metricGroup.screenName,
                        mediaOwnerId: mo.id
                    };
                    mediaOwner.screen.push(screen);
                }
            });
            // For every screen in the mediaOwners, find the dashboardTarget that matches the screen.id
            
            return mediaOwners;
        },

        calcPerformance() {
            return (booked, delivered) => {
                console.log("=>calcPerformance", booked, delivered);
                let perf = parseFloat(delivered) / parseFloat(booked) * 100;
                    // Check if perf is not in range of 1-100 or if it is NaN or Infinity
                    if (isNaN(perf) || !isFinite(perf) || perf < 1) return 0;
                    return perf.toFixed(2);
            };
        },
        calculateBookedTotal(){
            return (item) => {
                let total = 0
                // TODO: calculate media owner booked total
                return total
            }
        },
        calculateScreenBookedTotal() {
            return (screen) => {
                let total = 0
                // TODO: calculate media owner booked total
                return total
            }
        },
        calculatePlayoutTotal(){
            return (item) => {
                let total = 0
                // find screen item in playoutDashboardStats per screen
                item.screen.forEach(screen => {
                    total += this.calculateScreenPlayoutTotal(screen)
                })
                return total
            }
        },
        calculateScreenPlayoutTotal() {
            return (screen) => {
                let metricGroup = this.getMetricGroup(screen.mediaOwnerId,screen.name);
                if(!metricGroup) return 0;
                return this.totalMetricGroupStats(metricGroup, screen.kpi)
            }
        },
        // Computed function to find index of this.selectedKPI in the kpiList
        getKPIIndex(){
            //return this.kpiList.findIndex(kpi => kpi === this.selectedKPI)
            return (item) => {
                if(this.kpiList.findIndex(kpi => kpi === item.kpi) === -1) return 0
                return this.kpiList.findIndex(kpi => kpi === item.kpi)
            }
        },
        findScreenNamebyCBSID(){
            return (cbsid) => {
                let screen = this.burstCopy.campaignBurstScreens.find(screen => screen.id === cbsid)
                return screen.screen.name
            }
        },
        // Function that gets a media owner object as a prop and return true or false depending if there are screens in mediaOwner.screen that have notes
        hasMoNotes() {
            return (mediaOwner) => {
            let hasNotes = false
            mediaOwner.screen.forEach(screen => {
                const isNote = this.hasFormatNotes(screen)
                if (isNote) {
                    hasNotes = true
                    return
                }

            })
            return hasNotes
            }
        },
        hasFormatNotes() {
            return (format) => {
                let hasNotes = false
                let isNotes = this.notesData.filter(note => note.campaignBurstScreenId === format.cbsid)
                // Filterthe notesData where 
                const currentTab = localStorage.getItem('actionTab')
                if(parseInt(currentTab) === 2) isNotes = isNotes.filter(note => note.dashboardMode === 'auto')
                else isNotes = isNotes.filter(note => note.dashboardMode === 'manual')
                // Check if the format.kpi is Impressions, Plays or Impacts/Impressions
                let kpi = ''
                if (format.kpi === 'Impressions') {
                    kpi = 'hours';
                } else if (format.kpi === 'Plays') {
                    kpi = 'plays';
                } else if (format.kpi === 'Impacts/Impressions') {
                    kpi = 'impacts';
                }
                const isNote = isNotes.find(note => note.dashboardKPI === kpi)
                if (isNote) {
                    hasNotes = true
                    if(isNote.note === '') return hasNotes = false
                }
                return hasNotes
            }
        },
        // Function to check is user has analyticsEdit permission
       hasAnalyticsEditPermission() {
        return this.$store.state.Permissions.analyticsEdit
      },
    },
    async created(){
        // Get the dashboardTargets for Tracking & Verified
        if (this.type.includes('playout'))
        {
            console.log("calling mapDashboardTargetsPlayout");
            await this.mapDashboardTargetsPlayout()
        }

    },
    methods: {
        getMetricGroupDates(metricGroup, metricType) {
            // Iterate through item.screen and append each date to an array
            let dates = []
            metricGroup.dateStats.forEach((metric) => {
                // get the metric value belonging to the metric
                let found = metric.metrics.find((m) => m.metricName === metricType)
                if(found) dates.push(metric.date)
            });
            return dates
        },
        getMetricGroup(mediaOwnerId, screenName) {
            
            let mg = this.playoutDashboardStats.find((metricGroup) => metricGroup.mediaOwner.id === mediaOwnerId && metricGroup.screenName == screenName);
            console.log("=> getMetricGroup", this.playoutDashboardStats);
            return mg
        },
        totalMetricGroupStats(metricGroup, metricType)
        {
            let total = 0;
            console.log("=> totalMetricGroupStats", metricType);
            metricGroup.dateStats.forEach((metric) => {
                // get the metric value belonging to the metric
                let found = metric.metrics.find((m) => m.metricName === metricType)
                if(found) total += found.value
            });
            return total
        },
        async changeKPI(item, type){
            console.log("=>KPI", item);
            if(type === 'upd') {// change th e kpi value intentionally and then back to it to update table after updating the target
                let oldKPI = item.kpi
                if(oldKPI === '') item.kpi = 'Actual Plays - Playout'
                else if(oldKPI === 'Actual Plays - Playout') item.kpi = ''
                this.$nextTick()
                item.kpi = oldKPI
            } else {
            // find index of item in this.getMediaOwnersList
                let index = this.getMediaOwnersList.findIndex(mo => mo.id === item.id)
                this.getMediaOwnersList[index].screen.forEach(screen => {
                    screen.kpi = item.kpi
                let expandCopy = [...this.expanded]
                this.expanded = []
                this.$nextTick()
                this.expanded = [...expandCopy]
                this.$nextTick()
            })
        }
        },

        updatePickerDate(newDate) {
        // This method updates the initialDate when the user changes the month
            this.displayDate = newDate;
        },
        //TODO: Add safeguard workflowStatus.id = 0 
        filterColor(status) {
            switch(status) {
                case 1:
                    return '#499F68'
                case 2:
                    return 'grey'
                case 3:
                    return '#F464A9'
                case 4:
                    return '#4AA6D5'
                case 0:
                    return 'transparent'
                default:
                    return 'transparent'
            }  
        },
        closeDialog() {
            this.showCommDialog = false
            this.showCommDialogMO = false
            this.selectedItem = null
            this.selectedMoNotes = []
            this.comment = ''
            this.$nextTick()
        },
        addPasteEventListener() {
            const editableElements = document.querySelectorAll('[contenteditable]');
            editableElements.forEach((element) => {
                element.addEventListener('paste', this.handlePaste);
            });
        },
        handlePaste(event) {
            event.preventDefault();
            // clear the innerText of the element . editable
            event.target.innerText = '';
            var text = event.clipboardData.getData('text/plain')
            document.execCommand('insertText', false, text)
        },
        async mapDashboardTargetsPlayout() {
            await ReportingController.getPlayoutDashboardStats(this.campaign.id).then(response => {
                console.log(response.data)
                this.playoutDashboardStats = response.data
                console.log("=>playoutDashboardStats",this.playoutDashboardStats);
            })
        },
        async mapDashboardTargets() {
            await RouteController.getDashboardTargets(this.burst.id).then(response => {
                this.dashboardTargets = [...response.data]
                // FInd the dashboardTarget that matches this.getMediaOwnersList screen.id (keep it mind that every media owner has an array of screens
                this.getMediaOwnersList.forEach(mediaOwner => {
                    // Assign impressions as first value for mo

                    mediaOwner.kpi = this.kpiList[0]
                    mediaOwner.screen.forEach(screen => {
                        let target = this.dashboardTargets.find(target => target.campaignBurstScreenId === screen.cbsid)
                        // Filter the manual date ranges from this.dashboardTargets.dateRanges
                        let manualDateRanges = target.dateRanges.filter(dateRange => dateRange.mode === 'manual')
                        // Find the manual date range .kpi that matches the 'impressions'
                        let manualActualDateRanges = manualDateRanges.filter(dateRange => dateRange.designation === 'actual')
                        let manualTargetDateRanges = manualDateRanges.filter(dateRange => dateRange.designation === 'target')

                        // Find the manual date range .kpi that matches the 'impacts'
                        let impactsActualDateRange = manualActualDateRanges.find(dateRange => dateRange.kpi === 'impacts')
                        let impactsTargetFullRange = manualTargetDateRanges.find(dateRange => dateRange.kpi === 'impacts')
                        // format the manualDateRange
                        if(impactsActualDateRange) {
                            impactsActualDateRange.startDate = moment(impactsActualDateRange.startDate).format('YYYY-MM-DD')
                            impactsActualDateRange.endDate = moment(impactsActualDateRange.endDate).format('YYYY-MM-DD')
                        }
                        
                        // Find the manual date range .kpi that matches the 'plays'
                        let playsActualDateRange = manualActualDateRanges.find(dateRange => dateRange.kpi === 'plays')
                        let playsTargetFullRange = manualTargetDateRanges.find(dateRange => dateRange.kpi === 'plays')

                        // format the manualDateRange
                        if(playsActualDateRange) {
                            playsActualDateRange.startDate = moment(playsActualDateRange.startDate).format('YYYY-MM-DD')
                            playsActualDateRange.endDate = moment(playsActualDateRange.endDate).format('YYYY-MM-DD')
                        }
                        if(target){
                            screen.moId = mediaOwner.id
                            screen.kpi = this.kpiList[0]
                            screen.targetsManual = [
                                /*
                                {
                                    name: 'Impressions',
                                    booked: target.targetHoursManual,
                                    deliver: target.actualHoursManual,
                                    startDate: impressionsDateRange.startDate,
                                    endDate: impressionsDateRange.endDate,
                                }, */
                                {
                                    name: 'Plays',
                                    // Booked
                                    booked: this.addCommas(target.targetPlaysManual),
                                    deliver: this.addCommas(target.actualPlaysManual),
                                    // Verified tab
                                    graphBooked: this.addCommas(target.targetPlaysAuto),
                                    graphActuals: this.addCommas(target.actualPlays),
                                    // Playout tab
                                    playoutBooked: this.addCommas(target.targetPlaysPlayout),
                                    playoutActuals: this.addCommas(target.actualPlaysPlayout),

                                    startDate: playsActualDateRange.startDate,
                                    endDate: playsActualDateRange.endDate,
                                    targetFullRange: playsTargetFullRange.targetFullRange,
                                },
                                {
                                    name: 'Impacts/Impressions',
                                    // Booked
                                    booked: this.addCommas(target.targetImpactsManual),
                                    deliver: this.addCommas(target.actualImpactsManual),
                                    // Verified tab 
                                    graphBooked: this.addCommas(target.targetImpactsAuto),
                                    graphActuals: this.addCommas(target.actualImpacts),
                                    // Playout tab
                                    playoutBooked: this.addCommas(target.targetImpactsPlayout),
                                    playoutActuals: this.addCommas(target.actualImpactsPlayout),

                                    startDate: impactsActualDateRange.startDate,
                                    endDate: impactsActualDateRange.endDate,
                                    targetFullRange: impactsTargetFullRange.targetFullRange,
                                },
                            ]
                            
                        }
                    })
                })
                // IF thereas a media Owner with name includes JCD, change the kpi to 'Impacts/Impressions'
                this.getMediaOwnersList.forEach(mediaOwner => {
                    var name = mediaOwner.n || mediaOwner.name;
                    if(name.includes('JCD')) {
                        mediaOwner.kpi = 'Impacts/Impressions'
                        mediaOwner.screen.forEach(screen => {
                            screen.kpi = 'Impacts/Impressions'
                            /* swap kpi names 
                            let temp = screen.targetsManual[0]
                            screen.targetsManual[0] = screen.targetsManual[1]
                            screen.targetsManual[1] = temp */

                        })
                    }
                })
                // Force the table to re-render
                this.$nextTick()
            })
        },
        async addNote(item) {
            try {
                const currentTab = localStorage.getItem('actionTab');
                const obj = {
                    campaignBurstScreenId: this.selectedItem.cbsid,
                    mode: parseInt(currentTab) === 2 ? "auto" : "manual",
                    note: this.comment,
                };

                if (item.kpi === 'Plays') {
                    obj.type = 'plays';
                } else if (item.kpi === 'Impacts/Impressions') {
                    obj.type = 'impacts';
                }

                const res = await RouteController.setDashboardNotes(obj);

                const formatObj = {
                    campaignBurstScreenId: obj.campaignBurstScreenId,
                    dashboardKPI: obj.type,
                    note: obj.note,
                    dashboardMode: obj.mode,
                };

                let index = this.notesData.findIndex(note => note.campaignBurstScreenId === obj.campaignBurstScreenId && note.dashboardKPI === obj.type);

                if (index !== -1) {
                    // Update existing note or replace it
                    this.$set(this.notesData, index, formatObj);
                } else {
                    // Push new note if not found
                    this.notesData.push(formatObj);
                }

                // Update selectedItem and item notes using Vue.set for reactivity
                this.$set(this.selectedItem, 'note', formatObj);
                this.$set(item, 'note', formatObj);

                this.$root.$emit('snackbarSuccess', "Note added successfully");
            } catch (err) {
                console.log(err);
            } finally {
                this.message = '';

                // Trigger reactivity by forcing the component to re-render
                this.$forceUpdate();
            }
        },

        showCommentDialog(item){
            // Data pre-processing
            this.selectedItem = item
            // check if selectedItem has one or more notes, if so, assign to this.selectedItem.notes array
            let notes = this.notesData.filter(note => note.campaignBurstScreenId === item.cbsid)
            // If current tab === 2 (verified) then filter notes by mode = auto
            const currentTab = localStorage.getItem('actionTab')
            if(parseInt(currentTab) === 2) notes = notes.filter(note => note.dashboardMode === 'auto')
            else notes = notes.filter(note => note.dashboardMode === 'manual')
            // Find the note that has type = item.kpi
            let kpi = null
            if (item.kpi === 'Impressions') {
                kpi = 'hours';
            } else if (item.kpi === 'Plays') {
                kpi = 'plays';
            } else if (item.kpi === 'Impacts/Impressions') {
                kpi = 'impacts';
            }
            let note = notes.find(note => note.dashboardKPI === kpi)
            if(note) this.selectedItem.note = note
            else this.selectedItem.note = null
            if(note) this.comment = note.note
            // Modal control
            this.showCommDialog = true
            this.$nextTick()
        },
        showCommentDialogAll(mediaOwner){
            this.showCommDialogMO = true
            // Get a list of notes that have the same campaignBurstScreenId as the mediaOwner.screen.cbsid (where screen is an array)
            let notes = this.notesData.filter(note => mediaOwner.screen.find(screen => screen.cbsid === note.campaignBurstScreenId))
            // If current tab === 2 (verified) then filter notes by mode = auto7
            const currentTab = localStorage.getItem('actionTab')
            if(parseInt(currentTab) === 2) notes = notes.filter(note => note.dashboardMode === 'auto')
            else notes = notes.filter(note => note.dashboardMode === 'manual')
            // Filter notes by kpi
            let kpi = ''
            if(mediaOwner.kpi === 'Impressions') kpi = 'hours'
            else if(mediaOwner.kpi === 'Plays') kpi = 'plays'
            else if(mediaOwner.kpi === 'Impacts/Impressions') kpi = 'impacts'
            notes = notes.filter(note => note.dashboardKPI === kpi)
            this.selectedMoNotes = notes
        },
    },
}
</script>
<style scoped>
*::v-deep .v-data-table__expanded .v-data-table-header th {
  height: 0px !important;
}
*::v-deep td .no-margin {
  padding: 0 !important;
}
</style>
<style lang="scss">
#no-background-hover {
   background-color: transparent !important;
}
</style>
<style scoped>
.v-card {
    margin-top:0;
    margin-bottom:0;
}
.together{
    min-width: 0
  }
.v-expansion-panel-header {
    padding: 0px 24px 0px 0px;
}
.customSpacing:not(:first-child) {
    /* If not first item, add margin top 1 em */
    margin-top: 1.5em;
}
.datesMismatch {
    color: #ff9029;
}
</style>