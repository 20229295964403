<template>
  <div ref="fullscreenComponent">
    <v-card style="padding: 25px 25px 15px 25px;background: var(--v-sidebarColorLight-darken2);min-height: 87px;"
      :class="{ 'expanded': isFilterExpanded }">
      <!-- <span
        style="position: absolute;right: 15px;top: 25px; text-transform: uppercase;font-weight: 500;letter-spacing: 8px;color: white;">
        <span>Playout</span>
        <v-icon style="padding: 0px 10px; margin-top: -2px;color: white;">mdi-animation-play-outline</v-icon>
      </span> -->

      <v-row no-gutters>
        <v-col style=" display: flex;">
          <v-text-field ref="searchTerm" v-model="searchTerm" label="K Number" hide-details="auto" outlined clearable
            dark dense style="max-width: 300px; margin-right: 10px;"></v-text-field>
          <v-btn height="40px" width="20px" style="margin-right: 10px;" color="primary"
            :disabled="!fetchingAPIData && !searchTerm" :loading="fetchingAPIData" @click="fetchAPIData">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-select v-if="isFilteredDataNotEmpty" v-model="selectedRouteRequest"
            :items="initializedSupportInfo.routeRequest" item-text="name" item-value="id" label="Audience" outlined
            dense style="max-width: 350px;" :menu-props="{ maxHeight: '400' }">
          </v-select>
        </v-col>
        <v-col sm="auto" style="display: flex; align-items: center; justify-content: flex-end;">
          <v-row justify="end" style="align-items: center;">
            <v-col sm="auto" style="padding: 0px 5px;">
              <v-btn ref="filterButton" @click="toggleMoreFilters" :color="filtersActive ? 'secondary' : undefined"
                style="color: white; background-color: var(--v-sidebarColorLight-darken1) ; border: 1px solid #555; text-transform: uppercase; font-weight: 500; letter-spacing: 4px; min-width: 120px; height: 40px; margin-right: 10px;">
                More Filters
                <v-icon right>{{ isFilterExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
            <v-col sm="auto" style="padding: 0px 5px;">
              <v-menu offset-y nudge-left="15">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small :loading="downloadingReport" v-bind="attrs" v-on="on"
                    style="min-width: 40px; height: 40px;">
                    <v-icon>mdi-file-download-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="downloadReport('Daily')">
                    <v-list-item-title>Daily</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadReport('Hourly')">
                    <v-list-item-title>Hourly</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col sm="auto" style="padding: 0px 5px;">
              <v-btn small @click="toggleFullscreen" style="min-width: 40px; height: 40px;">
                <v-icon>mdi-fullscreen</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div style="position: relative;">
        <div v-if="isFilterExpanded" ref="overlay"
          style="position: absolute; top: calc(100% + 10px); left: 0; right: 0; background: rgba(30, 30, 30, 0.5); border: 2px solid var(--v-sidebarColorLight-darken1); border-radius: 10px; backdrop-filter: blur(10px); z-index: 1000; padding: 25px;">
          <!-- Expandable Filter -->
          <v-row no-gutters style="margin-top:40px;margin-bottom: -15px;">
            <v-col key="1" cols="12" sm="6" style="display: flex;">
              <v-menu ref="datePickerMenu" v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="10"
                :nudge-bottom="-290" :return-value.sync="datePickerRange" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formattedDateRange" label="Selected Date Range" hide-details="auto" outlined
                    dense readonly dark style="max-width: 300px; min-width: 250px; width: 100%;" v-bind="attrs"
                    v-on="on">
                    <template v-slot:append>
                      <v-icon @click="datePickerMenu = !datePickerMenu">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-card>
                  <v-container class="pa-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-date-picker ref="leftDatePicker" v-model="datePickerRange" type="date" range lang="en"
                          value-type="format" class="datepicker-dark" :no-title="true" :show-current="false">
                        </v-date-picker>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-date-picker ref="rightDatePicker" v-model="datePickerRange" type="date" range lang="en"
                          value-type="format" class="datepicker-dark" :no-title="true" :show-current="false">
                        </v-date-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-menu>
            </v-col>
            <v-col key="2" cols="12" sm="6" style="">
              <!-- FILTERS -->
              <v-row style="height: 50px;margin-top: -30px;">
                <v-col key="1" cols="12" lg="6" style="">
                  <v-select v-model="selectedTvRegions" :items="initializedSupportInfo.tvRegion" item-text="name"
                    item-value="id" label="Tv Regions" multiple outlined dense :menu-props="{ maxHeight: '400' }">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2" small>
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 2" class="grey--text text-caption">
                        (+{{ selectedTvRegions.length - 2 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col key="2" cols="12" lg="6" style="">
                  <v-select v-model="selectedConurbations" :items="initializedSupportInfo.conurbation" item-text="name"
                    item-value="id" label="Conurbations" multiple outlined dense :menu-props="{ maxHeight: '400' }">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2" small>
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 2" class="grey--text text-caption">
                        (+{{ selectedConurbations.length - 2 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col key="3" cols="12" lg="6" style="">
                  <v-select v-model="selectedMediaOwners" :items="initializedSupportInfo.mediaOwner" item-text="name"
                    item-value="id" chips label="Media Owners" multiple outlined dense></v-select>
                </v-col>
                <v-col key="4" cols="12" lg="6" style="">
                  <v-select v-model="selectedFormatDelivers" :items="initializedSupportInfo.formatDeliver"
                    item-text="name" item-value="id" chips label="Formats" multiple outlined dense></v-select>
                </v-col>
              </v-row>
              <v-row style="height: 50px;margin-top: -30px;">
                <v-col key="5" cols="12" lg="6" style="">
                  <v-select v-model="selectedCreativeNames" :items="initializedSupportInfo.creativeName"
                    item-text="name" item-value="id" chips label="Creative Names" multiple outlined dense></v-select>
                </v-col>
                <v-col key="6" cols="12" lg="6" style="">
                  <v-select v-model="selectedSalesCodes" :items="initializedSupportInfo.salesCode" item-text="name"
                    item-value="id" chips label="Sales Codes" multiple outlined dense></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col key="7" cols="12" lg="6" style="">
                  <v-select v-model="selectedFormatGroups" :items="initializedSupportInfo.formatGroup" item-text="name"
                    item-value="id" chips label="Format Groups" multiple outlined dense></v-select>
                </v-col>
                <v-col key="8" cols="12" lg="6" style="">
                  <v-select v-model="selectedLocationDescs" :items="initializedSupportInfo.locationDesc"
                    item-text="name" item-value="id" chips label="Location Descriptions" multiple outlined
                    dense></v-select>
                </v-col>
              </v-row>
              <v-row style="height: 50px;margin-top: -30px;">
                <v-col key="9" cols="12" lg="6" style="">
                  <v-select v-model="selectedEnvDescs" :items="initializedSupportInfo.envDesc" item-text="name"
                    item-value="id" chips label="Environmental Descriptions" multiple outlined dense></v-select>
                </v-col>
                <v-col key="10" cols="12" lg="6" style="">
                  <v-select v-model="selectedAddresses" :items="initializedSupportInfo.address" item-text="name"
                    item-value="id" chips label="Addresses" multiple outlined dense></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col key="11" cols="12" lg="6" style="">
                  <v-select v-model="selectedPostCodes" :items="initializedSupportInfo.postCode" item-text="name"
                    item-value="id" chips label="Post Codes" multiple outlined dense></v-select>
                </v-col>
                <v-col key="12" cols="12" lg="6" style="">
                  <v-select v-model="selectedTowns" :items="initializedSupportInfo.town" item-text="name"
                    item-value="id" chips label="Towns" multiple outlined dense></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="height: 50px;margin-top: -30px;">
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Experimental Section</h3>
            </v-col>
            <v-col cols="12" sm="4" style="display: flex;">
              <v-select v-model="chart1Param" :items="chartOptions" label="Select Chart 1 Param" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" style="display: flex;">
              <v-select v-model="chart2Param" :items="chartOptions" label="Select Chart 2 Param" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" style="display: flex;">
              <v-select v-model="chart3Param" :items="chartOptions" label="Select Chart 3 Param" outlined dense></v-select>
            </v-col>
          </v-row>
          <v-row style="height: 50px;margin-top: -30px;">
            <v-col cols="12" style="display: flex; justify-content: flex-end; padding-right: 10px;">
              <div style="display: flex; width: 100%; justify-content: space-between; max-width: 300px;">
                <v-btn color="secondary" @click="resetFilters" style="flex: 2; margin-right: 10px;">
                  Reset
                </v-btn>
                <v-btn color="primary" @click="updateFilters" style="flex: 5;"> Filter </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <div v-if="isFilteredDataNotEmpty">
      <v-row style="margin-top: 0px">
        <!-- PLAYS -->
        <v-col v-if="miniChartData.plays.totalPlays > 0" key="1" cols="12"
          :lg="12/ ( 1 + (miniChartData.plays.totalPlays > 0 ? 1 : 0) + (miniChartData.impacts.totalImpacts > 0 ? 1 : 0))">
          <v-card style="background: var(--v-sidebarColorLight-darken2); margin:0px;overflow: hidden;height: 150px;">

            <div style="padding: 22px 22px 0px;">
              <span
                style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;; font-size: 1.5em;">Plays</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;position: relative; z-index: 2;">
              <table
                style="width:100%; margin-top: 5px; text-transform: uppercase;font-weight: 500;letter-spacing: 2px;">
                <tr>
                  <td style="text-align: center; vertical-align: middle; font-size: 3em;">
                    {{ Math.round(miniChartData.plays.totalPlays).toLocaleString() }}</td>
                </tr>
              </table>
            </div>
            <PlayoutMiniChart :miniData="miniChartData.plays"
              style="position: absolute;width: calc(100% + 34px);height: 100%;left: -23px;bottom: -15px;" />
          </v-card>
        </v-col>
        <!-- IMPACTS -->
        <v-col v-if="miniChartData.impacts.totalImpacts > 0" key="2" cols="12"
          :lg="12/ ( 1 + (miniChartData.plays.totalPlays > 0 ? 1 : 0) + (miniChartData.impacts.totalImpacts > 0 ? 1 : 0))">
          <v-card style="background: var(--v-sidebarColorLight-darken2); margin:0px;overflow: hidden;height: 150px;">
            <div style="padding: 22px 22px 0px;">
              <span
                style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;; font-size: 1.5em;">Impacts</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;position: relative; z-index: 2;">
              <table
                style="width:100%; margin-top: 5px; text-transform: uppercase;font-weight: 500;letter-spacing: 2px;">
                <tr>
                  <td style="text-align: center; vertical-align: middle; font-size: 3em;">
                    {{ Math.round(miniChartData.impacts.totalImpacts).toLocaleString() }}</td>
                </tr>
              </table>
            </div>
            <PlayoutMiniChart :miniData="miniChartData.impacts"
              style="position: absolute;width: calc(100% + 34px);height: 100%;left: -23px;bottom: -15px;" />
          </v-card>
        </v-col>
        <!-- Others -->
        <v-col v-if="miniChartData.reach.totalReach > 0" key="3" cols="12"
          :lg="12/ ( 1 + (miniChartData.plays.totalPlays > 0 ? 1 : 0) + (miniChartData.impacts.totalImpacts > 0 ? 1 : 0))">
          <v-card style="background: var(--v-sidebarColorLight-darken2); margin:0px;overflow: hidden;height: 150px;">
            <div style="padding: 22px 22px 0px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Others</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;position: relative; z-index: 2;">
              <table
                style="width:100%; margin-top: 5px; text-transform: uppercase;font-weight: 500;letter-spacing: 2px;">
                <tr>
                  <td>Reach</td>
                  <td style="text-align: right">{{ Math.round(miniChartData.reach.totalReach).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>Cover</td>
                  <td style="text-align: right">{{ Math.round(miniChartData.cover.totalCover).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td style="text-align: left;">Average Frequency</td>
                  <td style="text-align: right;">{{
                    Math.round(miniChartData.averageFrequency.totalAverageFrequency).toLocaleString() }}</td>
                </tr>
              </table>
            </div>
            <PlayoutMiniChart :miniData="miniChartData.reach"
              style="position: absolute;width: calc(100% + 34px);height: 100%;left: -23px;bottom: -15px;" />
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:15px">
        <v-col cols="12" sm="8">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> Master</span>
            </div>
            <div style="width: 100%;height: calc(100% - 51px);    padding: 0px 26px 34px 15px;">
              <PlayoutMasterChart :masterData="masterChartData" style="width: 100%; height: 100%;" />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px;display: flex; margin:0px">
            <PlayoutMap :mapData="mapData" style="width: 100%; height: 100%;" />
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top: 15px">
        <v-col key="1" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{ mediaOwnerChartName }}</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="mediaOwnerChartData" />
            </div>
          </v-card>
        </v-col>
        <v-col key="2" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{ formatChartName }} </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="formatChartData" />
            </div>
          </v-card>
        </v-col>
        <v-col key="3" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{ tvRegionChartName }} </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px)">
              <PlayoutGroupTypeChart :groupTypeData="tvRegionChartData" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- JSON Data Card -->
    <!-- <v-card
      style="background: black; color: white; z-index: 9999; height: 1000px; margin-bottom: 20px; overflow: hidden;">
      <v-card-text style="height: 100%; overflow-y: auto;">
        <pre style="color: white; margin: 0;">{{ this.dataObject }}</pre>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
// Services
import ReportingController from '@/services/controllers/Reporting'
import { debounce } from 'lodash';

// Components
import PlayoutMap from './PlayoutMap.vue';
import PlayoutMasterChart from './PlayoutMasterChart.vue';
import PlayoutMiniChart from './PlayoutMiniChart.vue';
import PlayoutGroupTypeChart from './PlayoutGroupTypeChart.vue';

export default {
  name: 'PlayoutDashboard',
  props: {
    kNumber: {
      type: String,
      required: false
    },
  },
  components: {
    PlayoutMap,
    PlayoutMasterChart,
    PlayoutMiniChart,
    PlayoutGroupTypeChart
  },
  data: () => ({
    apiData: {},// require('@/assets/dev/json/Playout Visualizer Data - Example 4 - K56124 - Daily.json'),// require('@/assets/dev/json/Playout Visualizer Data - Example 6 - K56106 - Daily.json'), // Example Loader: require('@/assets/dev/json/Playout Visualizer Data - Example 4 - K56124 - Daily.json'),

    searchTerm: '',
    loadedSearchTerm: '',
    granularity: 'Daily',
    fetchingAPIData: false,
    downloadingReport: false,

    startDateTime: null,
    endDateTime: null,

    //filters
    isFilterExpanded: false,
    dateTimeRangeFilter: [], // example datetime range ["2024-01-16T00:00:00", "2024-01-20T00:00:00"]
    mediaOwnerFilter: [],
    formatDeliverFilter: [],
    conurbationFilter: [],
    tvRegionFilter: [],
    creativeNameFilter: [],
    salesCodeFilter: [],
    formatGroupFilter: [],
    locationDescFilter: [],
    envDescFilter: [],
    addressFilter: [],
    postCodeFilter: [],
    townFilter: [],

    //Selection
    selectedDateTimeRange: [],
    selectedMediaOwners: [],
    selectedFormatDelivers: [],
    selectedConurbations: [],
    selectedTvRegions: [],
    selectedRouteRequest: [],
    selectedCreativeNames: [],
    selectedSalesCodes: [],
    selectedFormatGroups: [],
    selectedLocationDescs: [],
    selectedEnvDescs: [],
    selectedAddresses: [],
    selectedPostCodes: [],
    selectedTowns: [],

    //UI data
    dataObject: {},
    displayTextBox: false,
    filtersActive: false,
    formattedDateRange: [],
    datePickerRange: [],
    datePickerMenu: false,
    leftPickerDate: null,
    rightPickerDate: null,

    chart1Param: 'mediaOwner',
    chart2Param: 'formatDeliver',
    chart3Param: 'tvRegion',

    chartOptions: ['mediaOwner', 'formatDeliver', 'tvRegion', 'creativeName', 'salesCode',
      'formatGroup', 'locationDesc', 'envDesc', 'address', 'postCode', 'town', 'conurbation'],
  }),
  created() {
    this.currentMonth = this.getCurrentMonth();
    this.nextMonth = this.getNextMonth();
  },
  watch: {
    filteredSupportInfo(newInfo) {
      // Select Route Request for first time loading
      if (this.selectedRouteRequest.length === 0) {
        const newRouteRequest = newInfo.get('routeRequest');
        if (!newRouteRequest) {
          return;
        }
        for (const [id, item] of newRouteRequest.entries()) {
          if (item.name.includes('All Adult')) {
            this.selectedRouteRequest = id;
            break;
          }
        }
      }
    },
    isFilterExpanded(value) {
      if (value) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    datePickerRange(newVal) {
      if (newVal.length === 2) {
        const [startDate, endDate] = newVal;
        if (new Date(startDate) > new Date(endDate)) {
          this.datePickerRange = [endDate, startDate];
        }
        this.selectedDateTimeRange = this.datePickerRange;
        this.formattedDateRange = this.formatDateRange(this.datePickerRange);
        this.$nextTick(() => {
          this.datePickerMenu = false;
        });
      }

      if (newVal.length === 1 && newVal[0]) {
        this.formattedDateRange = this.formatDateRange(newVal);
      }
    }
  },
  mounted() {
    this.setInitialMonths();

    if(this.kNumber){
      // if a kNumber is provided, search for it
      this.searchTerm = this.kNumber;
      this.fetchAPIData();
    }
  },

  computed: {
    // #region Initialize variables
    initializedSupportInfo() {
      return {
        frameInfo: this.apiData.supportInfo?.frameInfo || [],
        frameId: this.apiData.supportInfo?.frameId || [],
        creativeName: this.apiData.supportInfo?.creativeName || [],
        mediaOwner: this.apiData.supportInfo?.mediaOwner || [],
        formatDeliver: this.apiData.supportInfo?.formatDeliver || [],
        salesCode: this.apiData.supportInfo?.salesCode || [],
        formatGroup: this.apiData.supportInfo?.formatGroup || [],
        locationDesc: this.apiData.supportInfo?.locationDesc || [],
        envDesc: this.apiData.supportInfo?.envDesc || [],
        address: this.apiData.supportInfo?.address || [],
        postCode: this.apiData.supportInfo?.postCode || [],
        town: this.apiData.supportInfo?.town || [],
        tvRegion: this.apiData.supportInfo?.tvRegion || [],
        conurbation: this.apiData.supportInfo?.conurbation || [],
        routeRequest: this.apiData.supportInfo?.routeRequest || [],
      };
    },
    // endregion

    // #region Apply filtering
    filteredDateTime() {
      const defaultMinDate = '1970-01-01T00:00:00.000Z';
      const defaultMaxDate = '9999-12-31T23:59:59.999Z';

      let [startDate, endDate] = this.dateTimeRangeFilter.length
        ? this.dateTimeRangeFilter
        : [defaultMinDate, defaultMaxDate];

      const startTime = new Date(startDate).toISOString();
      const endTime = new Date(endDate).toISOString();
      
      return [startTime, endTime];
    },
    filteredFramesLookup() {
      const filteredFrames = {
        frames: [],
      };

      for (const frame of this.initializedSupportInfo.frameInfo) {
        if (
          (!this.mediaOwnerFilter.length || this.mediaOwnerFilter.includes(frame.mediaOwnerRefId)) &&
          (!this.formatDeliverFilter.length || this.formatDeliverFilter.includes(frame.formatDeliverRefId)) &&
          (!this.conurbationFilter.length || this.conurbationFilter.includes(frame.conurbationRefId)) &&
          (!this.tvRegionFilter.length || this.tvRegionFilter.includes(frame.tvRegionRefId)) &&
          (!this.creativeNameFilter.length || this.creativeNameFilter.includes(frame.creativeNameRefId)) &&
          (!this.salesCodeFilter.length || this.salesCodeFilter.includes(frame.salesCodeRefId)) &&
          (!this.formatGroupFilter.length || this.formatGroupFilter.includes(frame.formatGroupRefId)) &&
          (!this.locationDescFilter.length || this.locationDescFilter.includes(frame.locationDescRefId)) &&
          (!this.envDescFilter.length || this.envDescFilter.includes(frame.envDescRefId)) &&
          (!this.addressFilter.length || this.addressFilter.includes(frame.addressRefId)) &&
          (!this.postCodeFilter.length || this.postCodeFilter.includes(frame.postCodeRefId)) &&
          (!this.townFilter.length || this.townFilter.includes(frame.townRefId))
        ) {
          filteredFrames.frames.push(frame);
        }
      }

      const filteredFramesLookup  = new Map();
      filteredFrames.frames.forEach(frame => {
        filteredFramesLookup .set(frame.frameIdRefId, frame);
      });

      return filteredFramesLookup;
    },
    filteredSupportInfo() {
      const filteredFramesLookup = this.filteredFramesLookup;
      const supportInfo = this.initializedSupportInfo;

      const filteredSupportInfo = new Map();

      // Step 1: Gather all `xxxRefId` fields
      const refIdFields = {};
      const refIdsMap = new Map();
      for (const key of Object.keys(supportInfo)) {
        if (Array.isArray(supportInfo[key])) {
          const refIdField = `${key}RefId`;
          refIdFields[key] = refIdField;
          refIdsMap.set(refIdField, new Set()); // Initialize set for each refIdField
        }
      }

      // Step 2: Loop all frames once to get corresponding `refIds`
      for (const frame of filteredFramesLookup.values()) {
        for (const refIdField of Object.entries(refIdFields)) {
          if (refIdField in frame) {
            refIdsMap.get(refIdField).add(frame[refIdField]);
          }
        }
      }

      // Step 3: Filter `supportInfo` based on the gathered `refIds`
      for (const [key, value] of Object.entries(supportInfo)) {
        if (Array.isArray(value)) {
          const refIdField = refIdFields[key];
          let filteredItems = value;
          if (refIdsMap.get(refIdField) && refIdsMap.get(refIdField).size > 0) {
            filteredItems = value.filter(item => refIdsMap.get(refIdField).has(item.id));
          } 
          const filteredMap = new Map(filteredItems.map(item => [item.id, item]));
          filteredSupportInfo.set(key, filteredMap);
        }
      }

      // Replace frameInfo in filteredSupportInfo with the filtered frames from filteredFramesLookup
      filteredSupportInfo.set('frameInfo', new Map(filteredFramesLookup));

      return filteredSupportInfo;
    },
    filteredData() {
      if (!this.apiData.data) {
        return {};
      }
      
      // const startCheck = performance.now();
      // Apply filters
      const [startTime, endTime] = this.filteredDateTime;
      const filteredFramesLookup = this.filteredFramesLookup;
      
      const filteredData = {
        data: []
      };

      //Create template
      const types = [
        "mediaOwner",
        "tvRegion",
        "formatDeliver",
        "creativeName",
        "salesCode",
        "formatGroup",
        "locationDesc",
        "envDesc",
        "address",
        "postCode",
        "town",
        "conurbation"
      ];
      const filteredItemTemplate = this.createFilteredItemTemplate(types);
      const groupedTotalTemplate = this.createGroupedTotalTemplate();

      for (const item of this.apiData.data) {
        // JavaScript and Apex Chart are handling UTC datetime, so all datetime using are UTC now
        const itemDateTime = new Date(item.dateTime).toISOString();
        if (itemDateTime < startTime || itemDateTime > endTime) 
            continue;

        const filteredItem = JSON.parse(JSON.stringify(filteredItemTemplate));
        filteredItem.dateTime = itemDateTime;

        for (const frame of item.frames) {
          const matchingFrameInfo = filteredFramesLookup.get(frame.frameIdRefId);
          if (!matchingFrameInfo) 
              continue;

          filteredItem.frames.push(frame);
          this.aggregateTotals(filteredItem.grandTotals, frame);

          for (const group of filteredItem.groupedTotals) {
            let groupedTotal = group.data.find(
              (data) => data.refId === matchingFrameInfo[`${group.type}RefId`]
            );
            if (!groupedTotal) {
              groupedTotal = JSON.parse(JSON.stringify(groupedTotalTemplate));
              groupedTotal.refId = matchingFrameInfo[`${group.type}RefId`];
              group.data.push(groupedTotal);
            }
            this.aggregateTotals(groupedTotal, frame);
          }
        }
        filteredData.data.push(filteredItem);
      }

      // const endCheck = performance.now();
      // const duration = endCheck - startCheck;

      // console.log(`Start time: ${startCheck} ms`);
      // console.log(`End time: ${endCheck} ms`);
      // console.log(`Duration: ${duration} ms`);

      // const dataSize = this.roughSizeOfObject(filteredData);
      // console.log('Data Size:', this.formatByteSize(dataSize));

      // const dataToShow = {
      //   startTime: new Date(startTime).toISOString(),
      //   endTime: new Date(endTime).toISOString()
      // };
      // console.log('dataToShow', dataToShow);
      // this.showDataOnDevTextBox(this.filteredSupportInfo);
      return filteredData;
    },
    // #endregion
    // #region Computed Chart Data Values
    dailyFigures() {
      // Add valueType here, and get data by dailyChartData.plays, dailyChartData.impacts, etc...
      const valueType = {
        plays: 'plays',
        impacts: 'i',
        impactsP: 'ip',
        impactsV: 'iv',
        reach: 'r',
        reachP: 'rp',
        reachV: 'rv',
        grp: 'grp',
        cover: 'c',
        averageFrequency: 'af'
      };
      const getTotalLabelName = (type) => `total${type.charAt(0).toUpperCase() + type.slice(1)}`;
      
      const data = this.filteredData?.data;

      const results = Object.keys(valueType).reduce((acc, type) => {
        acc[type] = { series: [], categories: [], [getTotalLabelName(type)]: 0 };
        return acc;
      }, {});

      if (!data || !data.length) {
        return results;
      }

      const categories = [];
      const aggregateTotals = Object.keys(valueType).reduce((acc, type) => {
        acc[type] = { values: [], total: 0 };
        return acc;
      }, {});

      for (const item of data) {
        categories.push(item.dateTime);
        for (const type of Object.keys(valueType)) {
          let value = 0;
          if (type === 'plays') {
            value = item.grandTotals[valueType[type]] || 0;
          } else {
            value = item.grandTotals.rf
              .filter(rfItem => rfItem.rid === this.selectedRouteRequest)
              .reduce((sum, rfItem) => sum + (rfItem[valueType[type]] || 0), 0);
          }
          aggregateTotals[type].values.push(value);
          aggregateTotals[type].total += value;
        }
      }

      Object.keys(results).forEach(type => {
        results[type].series = [{ data: aggregateTotals[type].values }];
        results[type].categories = categories;
        results[type][getTotalLabelName(type)] = aggregateTotals[type].total;
      });

      return results;
    },
    // #endregion
    // #region Chart Data
    mapData() {
      const data = this.filteredData?.data;
      if (!data || !data.length) {
        return { data: [], maxIntensity: 0 };
      }

      const mapData = [];
      const mergedFrames = new Map();
      let maxIntensity = 0;

      for (const date of this.filteredData.data) {
        for (const frame of date.frames) {
          const matchingFrameInfo = this.filteredSupportInfo.get('frameInfo').get(frame.frameIdRefId);

          const key = `${matchingFrameInfo.lat},${matchingFrameInfo.long}`;
          const mergedFrame = mergedFrames.get(key) || this.initializeMergedFrame(matchingFrameInfo);
          mergedFrame.plays += frame.plays;
          mergedFrame.impacts += frame.impacts;
          mergedFrames.set(key, mergedFrame);

          maxIntensity = Math.max(maxIntensity, mergedFrames.get(key).plays);
        }
      }

      for (const [key, value] of mergedFrames) {
        mapData.push(value);
      }

      return { data: mapData, maxIntensity };
    },
    masterChartData() {
      const dailyPlays = this.dailyFigures.plays;
      const dailyImpacts = this.dailyFigures.impacts;

      const series = [];

      if (dailyPlays.series.length > 0 && dailyPlays.series[0].data.some(play => play !== 0)) {
        const cumulativePlays = dailyPlays.series[0].data.reduce((acc, value, index) => {
          acc.push((acc[index - 1] || 0) + value);
          return acc;
        }, []);
        series.push(
          { name: 'Plays - Daily', type: 'area', data: dailyPlays.series[0].data },
          { name: 'Plays - Cumulative', type: 'column', data: cumulativePlays }
        );
      }

      if (dailyImpacts.series.length > 0 && dailyImpacts.series[0].data.some(impact => impact !== 0)) {
        const cumulativeImpacts = dailyImpacts.series[0].data.reduce((acc, value, index) => {
          acc.push((acc[index - 1] || 0) + value);
          return acc;
        }, []);
        series.push(
          { name: 'Impacts - Daily', type: 'area', data: dailyImpacts.series[0].data },
          { name: 'Impacts - Cumulative', type: 'column', data: cumulativeImpacts }
        );
      }

      return {
        series,
        categories: dailyPlays.categories,
      };
    },
    miniChartData() {
      return {
        plays: this.dailyFigures.plays,
        impacts: this.dailyFigures.impacts,
        reach: this.dailyFigures.reach,
        cover: this.dailyFigures.cover,
        averageFrequency: this.dailyFigures.averageFrequency,
      };
    },
    mediaOwnerChartData() {
      return this.calculateGroupedChartData(this.chart1Param);
    },
    formatChartData() {
      return this.calculateGroupedChartData(this.chart2Param);
    },
    tvRegionChartData() {
      return this.calculateGroupedChartData(this.chart3Param);
    },
    mediaOwnerChartName() {
      return this.getChartName(this.chart1Param);
    },
    formatChartName() {
      return this.getChartName(this.chart2Param);
    },
    tvRegionChartName() {
      return this.getChartName(this.chart3Param);
    },
    // #endregion
    // #region UI
    isFilteredDataNotEmpty() {
      if (this.filtersActive)
        return this.filtersActive;
      
        const isNotEmpty = Array.isArray(this.filteredData.data) && this.filteredData.data.length > 0;
      return isNotEmpty;
    }
    // #endregion
  },

  methods: {
    // #region Data Manipulation in Computed: FilteredData // Modify here to make data in the shape of the chart
    createFilteredItemTemplate(types) {
      const toReadableFormat = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
      };
      const totalMapping = types.map(type => {
        const readableName = toReadableFormat(type);
        return {
          name: `By ${readableName}`,
          shortName: `by${readableName.replace(/\s+/g, '')}`,
          type: type,
          data: [],
        };
      });

      return {
        dateTime: [],
        frames: [],
        groupedTotals: totalMapping,
        grandTotals: {
          plays: 0,
          rf: [],
        },
      };
    },
    createGroupedTotalTemplate() {
      return {
        refId: null,
        plays: 0,
        rf: [],
      };
    },
    aggregateTotals(targetTotals, sourceFrame) {
      const existingRfMap = new Map(targetTotals.rf ? targetTotals.rf.map(item => [item.rid, item]) : []);

      targetTotals.plays += sourceFrame.plays;
      // Using map for faster Route Request Figure aggregation
      for (let i = 0; i < sourceFrame.rf.length; i++) {
        const rfItem = sourceFrame.rf[i];
        let existingRfItem = existingRfMap.get(rfItem.rid);
        if (existingRfItem) {
          existingRfItem.i += rfItem.i;
          existingRfItem.ip += rfItem.ip;
          existingRfItem.iv += rfItem.iv;
          existingRfItem.grp += rfItem.grp;
          existingRfItem.r += rfItem.r;
          existingRfItem.rp += rfItem.rp;
          existingRfItem.rv += rfItem.rv;
          existingRfItem.c += rfItem.c;
          existingRfItem.af += rfItem.af;
        } else {
          // If the rfItem doesn't exist in targetTotals.rf, add it
          const newRfItem = { ...rfItem };
          targetTotals.rf.push(newRfItem);
          existingRfMap.set(rfItem.rid, newRfItem); // Update the map with the new item
        }
      }
    },
    calculateGroupedChartData(type) {
      const data = this.filteredData?.data;
      if (!data || !data.length) {
        return {series: [], categories: []};
      }

      const supportInfo = this.filteredSupportInfo.get(type);

      const categories = Array.from(supportInfo.values()).map(entry => entry.name);
      const playsData = new Array(supportInfo.size).fill(0);
      const impactsData = new Array(supportInfo.size).fill(0);

      const refIdToIndex = new Map(Array.from(supportInfo.keys()).map((id, index) => [id, index]));

      for (let item of data) {
        for (let total of item.groupedTotals) {
          if (total.type === type) {
            for (let groupItem of total.data) {
              const { refId, plays, rf } = groupItem;
              const index = refIdToIndex.get(refId);
              playsData[index] += plays;
              const impacts = rf
                .filter(rfItem => rfItem.rid === this.selectedRouteRequest)
                .reduce((sum, rfItem) => sum + (rfItem.i || 0), 0);
              impactsData[index] += impacts;
            }
          }
        }
      }

      const hasNonZeroPlays = playsData.some(play => play !== 0);
      const hasNonZeroImpacts = impactsData.some(impact => impact !== 0);

      const series = [];
      if (hasNonZeroPlays) {
        series.push({ name: 'Plays', data: playsData });
      }
      if (hasNonZeroImpacts) {
        series.push({ name: 'Impacts', data: impactsData });
      }

      return { series, categories };
    },
    initializeMergedFrame(matchingFrameInfo) {
      const supportInfo = this.filteredSupportInfo;
      return {
        location: { lat: matchingFrameInfo.lat, lng: matchingFrameInfo.long },
        plays: 0,
        impacts: 0,
        formatName: supportInfo.get('formatDeliver').get(matchingFrameInfo.formatDeliverRefId)?.name || null,
        mediaOwnerName: supportInfo.get('mediaOwner').get(matchingFrameInfo.mediaOwnerRefId)?.name || null,
        tvRegionName: supportInfo.get('tvRegion').get(matchingFrameInfo.tvRegionRefId)?.name || null,
        conurbationName: supportInfo.get('conurbation').get(matchingFrameInfo.conurbationRefId)?.name || null
      };
    },
    // #endregion
    // #region UI functions
    toggleMoreFilters() {
      this.isFilterExpanded = !this.isFilterExpanded;
    },
    getChartName(param) {
      const chartNames = {
        mediaOwner: 'Media Owner',
        formatDeliver: 'Formats on Deliver',
        tvRegion: 'TV Regions',
        creativeName: 'Creatives',
        salesCode: 'Sales Codes',
        formatGroup: 'Format Groups',
        locationDesc: 'Location Descriptions',
        envDesc: 'Environmental Descriptions',
        address: 'Addresses',
        postCode: 'Post Codes',
        town: 'Towns',
        conurbation: 'Conurbations'
      };
      return chartNames[param] || 'Chart';
    },
    handleClickOutside(event) {
      const overlayElement = this.$refs.overlay;
      const buttonElement = this.$refs.filterButton.$el;
      const datePickerElementLeft = this.$refs.leftDatePicker && this.$refs.leftDatePicker.$el ? this.$refs.leftDatePicker.$el : null;
      const datePickerElementRight = this.$refs.rightDatePicker && this.$refs.rightDatePicker.$el ? this.$refs.rightDatePicker.$el : null;
      const calendarContainer = document.querySelector('.mx-datepicker-main.mx-datepicker-popup');
      const menuContainers = Array.from(document.querySelectorAll('.v-menu__content'));

      const isEventOutside = (target, elements) => elements.length > 0 && !elements.some(el => el.contains(target));

      if (
        overlayElement && !overlayElement.contains(event.target) &&
        buttonElement && !buttonElement.contains(event.target) &&
        (!datePickerElementLeft || !datePickerElementLeft.contains(event.target)) &&
        (!datePickerElementRight || !datePickerElementRight.contains(event.target)) &&
        (!calendarContainer || !calendarContainer.contains(event.target)) &&
        isEventOutside(event.target, menuContainers)
      ) {
        this.isFilterExpanded = false;
      }
    },
    toggleFullscreen() {
      const elem = this.$refs.fullscreenComponent;

      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch(err => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    setInitialMonths() {
      this.leftPickerDate = this.getCurrentMonth();
      this.rightPickerDate = this.getNextMonth();

      this.$nextTick(() => {
        if (this.$refs.leftDatePicker) {
          this.$refs.leftDatePicker.pickerDate = this.leftPickerDate;
        }
        if (this.$refs.rightDatePicker) {
          this.$refs.rightDatePicker.pickerDate = this.rightPickerDate;
        }
      });
    },
    getCurrentMonth() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    },
    getNextMonth() {
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      return `${nextMonth.getFullYear()}-${String(nextMonth.getMonth() + 1).padStart(2, '0')}`;
    },
    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
      return formattedDate.replace(/\//g, '-');
    },
    formatDateRange(dateRange) {
      if (dateRange.length === 2) {
        const [startDate, endDate] = dateRange;
        return `${this.formatDate(startDate)} to ${this.formatDate(endDate)}`;
      }
      return dateRange.length === 1 ? this.formatDate(dateRange[0]) : '';
    },
    updateFilters() {
      this.isFilterExpanded = false;
      if (
        this.selectedDateTimeRange.length > 0 ||
        this.selectedMediaOwners.length > 0 ||
        this.selectedFormatDelivers.length > 0 ||
        this.selectedConurbations.length > 0 ||
        this.selectedTvRegions.length > 0 ||
        this.selectedCreativeNames.length > 0 ||
        this.selectedSalesCodes.length > 0 ||
        this.selectedFormatGroups.length > 0 ||
        this.selectedLocationDescs.length > 0 ||
        this.selectedEnvDescs.length > 0 ||
        this.selectedAddresses.length > 0 ||
        this.selectedPostCodes.length > 0 ||
        this.selectedTowns.length > 0
      ) {
        this.filtersActive = true;
      }
      else {
        this.filtersActive = false;
      };

      this.debouncedUpdateFilters();
    },
    resetFilters() {
      this.selectedDateTimeRange = [];
      this.formattedDateRange = [];
      this.selectedMediaOwners = [];
      this.selectedFormatDelivers = [];
      this.selectedConurbations = [];
      this.selectedTvRegions = [];
      this.selectedCreativeNames = [];
      this.selectedSalesCodes = [];
      this.selectedFormatGroups = [];
      this.selectedLocationDescs = [];
      this.selectedEnvDescs = [];
      this.selectedAddresses = [];
      this.selectedPostCodes = [];
      this.selectedTowns = [];
      this.filtersActive = false;
      this.isFilterExpanded = false;
      this.debouncedUpdateFilters();
    },

    // Debounced update logic
    debouncedUpdateFilters: debounce(function () {
      this.dateTimeRangeFilter = this.selectedDateTimeRange;
      this.mediaOwnerFilter = this.selectedMediaOwners;
      this.formatDeliverFilter = this.selectedFormatDelivers;
      this.conurbationFilter = this.selectedConurbations;
      this.tvRegionFilter = this.selectedTvRegions;
      this.creativeNameFilter = this.selectedCreativeNames;
      this.salesCodeFilter = this.selectedSalesCodes;
      this.formatGroupFilter = this.selectedFormatGroups;
      this.locationDescFilter = this.selectedLocationDescs;
      this.envDescFilter = this.selectedEnvDescs;
      this.addressFilter = this.selectedAddresses;
      this.postCodeFilter = this.selectedPostCodes;
      this.townFilter = this.selectedTowns;
    }, 750),
    // #endregion
    // #region API Functions
    async fetchAPIData() {
      this.fetchingAPIData = true;
      this.loadedSearchTerm = '';
      await ReportingController.getPlayoutReportExpanded(this.searchTerm, 0, 'Daily')
        .then((res) => {
          this.loadedSearchTerm = this.searchTerm;
          this.apiData = res.data;
        }).finally(() => {
          this.fetchingAPIData = false;
        });
    },

    async downloadReport(granularity) {
        this.downloadingReport = true; 
        let res = await ReportingController.getPlayoutReportExport(this.loadedSearchTerm, 0, granularity).catch(async err => {

        }).finally(() => {
          this.downloadingReport = false;
        })
        const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
        const fileName = folderPath[folderPath.length-1]
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      },
    // #endregion

    // #region For Dev Only
    showDataOnDevTextBox(dataToDisplay) {
      this.dataObject = JSON.stringify(dataToDisplay, null, 2);
      this.displayTextBox = true;
      this.$forceUpdate();
    },
    roughSizeOfObject(object) {
      const objectList = [];
      const stack = [object];
      let bytes = 0;

      while (stack.length) {
        const value = stack.pop();

        if (typeof value === 'boolean') {
          bytes += 4;
        } else if (typeof value === 'string') {
          bytes += value.length * 2;
        } else if (typeof value === 'number') {
          bytes += 8;
        } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
          objectList.push(value);

          for (const i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },

    formatByteSize(bytes) {
      if (bytes < 1024) return bytes + ' bytes';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
      else return (bytes / 1073741824).toFixed(2) + ' GB';
    },
    logMemoryUsage(label) {
      if (performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit } = performance.memory;
        console.log(`[${label}] Memory Usage:`);
        console.log(`Used JS Heap: ${(usedJSHeapSize / 1048576).toFixed(2)} MB`);
        console.log(`Total JS Heap: ${(totalJSHeapSize / 1048576).toFixed(2)} MB`);
        console.log(`JS Heap Size Limit: ${(jsHeapSizeLimit / 1048576).toFixed(2)} MB`);
      } else {
        console.warn('Performance memory API is not available in this browser.');
      }
    }

    // #endregion
  },
  beforeDestroy() {
    // Clean up any event listeners or intervals
  }
}
</script>