<template>
    <v-container>
        <!-- <ContactFormVue />
        Filter by props -->
        <v-row class="my-0 py-0 px-2">
            <!-- Search -->
            <v-col class="ma-0 pa-0 pr-5 d-flex flex-column">
                <v-combobox
                    v-model="searchText"
                    :items="searchValues"
                    item-text="value"
                    :search-input.sync="search"
                    label="Search values"
                    clearable
                    multiple
                    small-chips
                    outlined
                    style="width: 100%"
                    append-icon="mdi-magnify"
                    @click:append="submitSearch()"
                    @click:clear="
                        searchText = []
                        submitSearch()
                    "
                >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :style="'background: none; border: 1px solid ' + item.colour"
                        :input-value="selected"
                        label
                        small
                    >
                        <span class="pr-2">
                            {{ item.value }}
                        </span>
                        <v-icon
                            small
                            @click="parent.selectItem(item)"
                        >
                            $delete
                        </v-icon>
                    </v-chip>
                </template>
                </v-combobox>
            </v-col>
            <!-- Sort by -->
            <v-col
                v-show="viewType !== 'map'"
                cols="12"
                md="3"
                class="ma-0 pa-0 pr-5"
            >
                <v-select
                    v-model="sortBy"
                    :items="SortByList"
                    v-on:change="submitSearch"
                    item-text="name"
                    label="Sort By"
                    color="primary"
                    outlined
                    return-object
                />
            </v-col>           
            <!-- Order -->
            <v-col
                v-show="viewType !== 'map'"
                cols="12"
                md="3"
                class="ma-0 pa-0 pr-5"
            >
                <v-select
                    v-model="orderBy"
                    :items="[{name: 'Ascending', value: 'asc'},{name: 'Descending', value: 'desc'}]"
                    item-text="name"
                    v-on:change="submitSearch"
                    color="primary"
                    label="Order By"
                    return-object
                    outlined
                />
            </v-col>
            <!-- Locations Limit -->
            <v-col
                v-show="viewType === 'map'"
                cols="12"
                md="3"
                class="ma-0 pa-0 pr-5"
            >
                <v-select
                    v-model="markerLimit"
                    :items="[100, 500, 1000, 5000]"
                    color="primary"
                    label="Marker Limit"
                    outlined
                />
            </v-col>
            <!-- Upload pop -->
            <v-col
                cols="12"
                md="1"
                class="ma-0 pa-0 pr-5"
            >
                <UploadPop
                    v-bind:campaigns="allCampaigns"
                    v-bind:tagCategories="tags"
                    v-bind:dotList="dots"
                 />
            </v-col>
        </v-row>
        <!-- Generate + c -->
        <v-row class="my-4 mt-lg-n2 mx-n1 d-flex justify-space-between align-center">
            <div>
                <GenerateLink
                    v-bind:pageSize="this.pageSize"
                    v-bind:finalSearchValue="this.searchText.length !== 0 ? Mutation : this.finalSearchValue"
                    v-bind:sortBy="this.sortBy"
                    v-bind:orderBy="this.orderBy"
                    v-bind:mapView="this.viewType"
                />
            </div>
            <div class="d-flex align-center ml-8" style="gap: 1em">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="viewType === 'map' || viewType === 'tag'"
                        v-on="on"
                        v-bind="attrs"
                        :color="headerDropAll ? 'primary' : ''"
                        @click="headerDropAll = !headerDropAll"
                        icon
                    >
                        <v-icon style="font-size: 2.2em;">mdi-card-multiple-outline</v-icon>
                    </v-btn>
                </template>
                <span>Show/Hide card titles</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="viewType === 'map' || viewType === 'tag'"
                        v-on="on"
                        v-bind="attrs"
                        :color="!tagsDropAll ? 'primary' : ''"
                        @click="tagsDropAll === 1 ? tagsDropAll = 0 : tagsDropAll = 1"
                        icon
                    >
                        <v-icon style="font-size: 2.2em;">mdi-tag-outline</v-icon>
                    </v-btn>
                </template>
                <span>Show/Hide tags</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        v-on="on"
                        v-bind="attrs"
                        :color="viewType === 'map' ? 'primary' : ''"
                        @click="
                            viewType = viewType !== 'map' ? 'map' : 'grid'
                            headerDropAll = false
                            tagsDropAll = 1
                            startingAt = 0
                            pops = []
                            locationtableData = []
                            infiniteScrolling()
                        "
                        icon
                    >
                        <v-icon style="font-size: 2.2em;">mdi-map-outline</v-icon>
                    </v-btn>
                </template>
                <span>Map View</span>
            </v-tooltip>
            </div>
            <!-- CLIPBOARD-->
            <div
                class="d-flex justify-end ml-auto align-center"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-if="$store.state.Permissions.tagGlobalEdit"
                            v-on="on"
                            v-bind="attrs"
                            :color="viewType === 'tag' ? 'primary' : ''"
                            class="mr-5"
                            @click="
                                viewType = viewType !== 'tag' ? 'tag' : 'grid'
                                headerDropAll = false
                                tagsDropAll = 1
                                startingAt = 0
                                pops = []
                                locationtableData = []
                                infiniteScrolling()
                            "
                            icon
                        >
                            <v-icon style="font-size: 2.2em;">mdi-tag</v-icon>
                        </v-btn>
                    </template>
                    <span>Edit tags</span>
                </v-tooltip>
                <!-- Empty Clipboard -->
                <v-tooltip top v-if="$store.state.PopGal.listPops.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="red darken-3"
                        @click="
                          $store.dispatch('PopGal/emptyClipboard')
                        "
                      >
                        <v-icon>mdi-clipboard-off</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Empty Selection</span>
                </v-tooltip>

                <!-- Edit clipboard -->
                <EditClipboard 
                    v-bind:campaigns="allCampaigns"
                    v-bind:tagCategories="tags"
                    v-bind:dotList="dots"
                />
                <!-- END CLIPBOARD-->
                
            </div>
        </v-row>
        <!-- Results found-->
        <v-row v-if="viewType !== 'tag' " class="mt-3 px-2 d-flex justify-start align-center" style="gap: 1em">
            <h2>{{ 'Total: ' + totalPops }}</h2>
        </v-row>
        <!-- Pop cards -->
        <v-expand-x-transition>
            <v-layout v-if="viewType === 'grid'" row wrap>
                <v-col 
                    v-for="(pop) in pops"
                    :key="`${pop.id}`"
                    lg="4"
                    md="6"
                    sm="12"
                >
                    <v-lazy
                        v-model.lazy="isActive"
                        :options="{
                            threshold: .5
                        }"
                        min-height="200"
                        transition="fade-transition"
                    >
                        <v-card
                            flat
                            elevation="2"
                            class="ma-2 pa-0"
                            v-bind:class="{ flipme: cardOne[pop.id] == 'start' }"
                            :style="{ maxHeight: 'auto', maxWidth: '500px' }"
                        >
                            <!-- Card Header -->
                            <v-card-title 
                                class="primary"
                                :class="cardOne[pop.id] == 'start' ? 'card__face--back' : ''"
                            >
                                <!-- DISPLAY DOT -->
                                <div class="d-flex justify-space-between align-center" style="width: 100%;">
                                    <div 
                                        v-if="pop.dot"
                                        class="dot"
                                        :style="{ background: pop.dot.value.split(' ')[1]}" 
                                        style="border: 1px solid black"
                                    ></div>
                                    <div v-else class="dot"></div>
                                    <div v-if="checkScreenQuality(pop)">
                                        <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs"
                                                        v-on="on" color="red darken-3" class="ml-2" style="margin-bottom: 0.12em;">mdi-flag</v-icon>
                                                </template>
                                                <span>Low Quality Image</span>
                                            </v-tooltip>
                                    </div>
                                    <!--
                                    <div>
                                        <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-hover v-slot="{ hover }">
                                                        <v-icon 
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            :color="hover || isPopEmotionActive(pop) || cardOne[pop.id] == 'flipped' ? 'red darken-3' : ''" 
                                                            class="ml-2" 
                                                            style="margin-bottom: 0.12em;"
                                                            @click="emotionalImpact(pop.id)"
                                                            >
                                                            {{ hover || isPopEmotionActive(pop) ? 'mdi-heart' : 'mdi-heart-outline' }}
                                                        </v-icon>
                                                    </v-hover>
                                                </template>
                                                <span>Emotional impact</span>
                                            </v-tooltip>
                                    </div>-->
                                    <div 
                                        class="d-flex flex-row align-center"
                                        :class="
                                            (pop.campaignName.length < 10 && $route.name !== 'PopGallery') ? 'ml-14 justify-center' : 'mr-2' &&
                                            (pop.campaignName.length >= 10 && $route.name !== 'PopGallery') ? 'ml-7' : 'mr-0'
                                        "
                                        style='width:100%; flex: 1; min-width: 0;'
                                        :style="$route.name === 'PopGallery' || !(pop.campaignName && pop.campaignName.length < 10) ? {justifyContent: 'center'} : {justifyContent: 'end'}"
                                    >
                                        <h6 class="subtitle-1" style="
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;"
                                        >
                                            {{ pop.campaignId !== 0 ? pop.campaignName : pop.popName}}
                                        </h6>
                                        <v-btn
                                            v-show="pop.campaignId != 0"
                                            @click="showMore(pop.id)"
                                            :ripple="false"
                                            icon
                                            class="toggleUpDown ma-0 pa-0"
                                            :class='{ "rotate": readMore[pop.id] }'
                                        ><v-icon>mdi-chevron-down</v-icon></v-btn>
                                    </div>
                                    <!-- Actions -->
                                    <div class="d-flex flex-row" style="gap: 0.6em;">
                                        <!-- DUPLICATE BUTTON-->
                                        <div v-show="$store.state.Permissions.proofOfPlayEdit">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        v-bind:color="checkSelectedList(pop) ? 'red darken-3' : 'white'"
                                                        icon
                                                        @click="addSelectedList(pop)"
                                                    >
                                                        <v-icon style="font-size: 1.84em" v-if="checkSelectedList(pop)">mdi-clipboard-check-outline</v-icon>
                                                        <v-icon style="font-size: 1.84em" v-else>mdi-clipboard-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span v-if="checkSelectedList(pop)">Remove from selection</span>
                                                <span v-else>Add to selection</span>
                                            </v-tooltip>
                                        </div>
                                        <EditPop 
                                            v-bind:data="pop"
                                            v-bind:campaigns="allCampaigns"
                                            v-bind:tagCategories="tags"
                                            v-bind:dotList="dots"
                                        />
                                        <!-- Delete Btn-->
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    v-show="$store.state.Permissions.proofOfPlayRemoveTag"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="red darken-3"
                                                    class="mr-n3"
                                                    style="width: 0%; margin-top: -0.06em;"
                                                    icon
                                                    @click="
                                                        deleteContentDialog = true
                                                        selectedContentForEdit = pop
                                                    "
                                                    >
                                                        <v-icon style="font-size: 2em">mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete PoP</span>
                                            </v-tooltip>
                                    </div>
                                </div>
                                <!-- Show more data -->
                                <v-expand-transition>
                                    <v-list 
                                        v-if="readMore[pop.id] && pop.campaignId != 0"
                                        color="primary d-flex flex-column text-center"
                                        style="gap: 0.15em; width: 100%;"
                                    >
                                        <!-- Campaign -->
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-list-item v-bind="attrs" v-on="on" class="ma-n2">
                                                    <v-list-item-action class="mr-0">
                                                        <v-icon small>mdi-bullhorn-outline</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="caption">{{ pop.campaignName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Campaign</span>
                                        </v-tooltip>
                                        <v-divider
                                            style="opacity: 0.4"
                                        ></v-divider>
                                        <!-- Burst -->
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-list-item v-bind="attrs" v-on="on" class="ma-n2">
                                                    <v-list-item-action class="mr-0">
                                                        <v-icon small>mdi-firework</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="caption">{{ pop.campaignBurstName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Burst</span>
                                        </v-tooltip>
                                        <v-divider
                                            style="opacity: 0.4"
                                        ></v-divider>
                                        <!-- Screen -->
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-list-item v-bind="attrs" v-on="on" class="ma-n2">
                                                    <v-list-item-action class="mr-0">
                                                        <v-icon small>mdi-monitor-screenshot</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="caption">{{ pop.screenName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Screen</span>
                                        </v-tooltip>
                                        <v-divider
                                            style="opacity: 0.4"
                                        ></v-divider>
                                        <!-- Media owner -->
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-list-item v-bind="attrs" v-on="on" class="ma-n2">
                                                    <v-list-item-action class="mr-0">
                                                        <v-icon small>mdi-domain</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="caption">{{ pop.mediaOwnerName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Media owner</span>
                                        </v-tooltip>
                                        <v-divider
                                            style="opacity: 0.4"
                                        ></v-divider>
                                        <!-- ID -->
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-list-item v-bind="attrs" v-on="on" class="ma-n2">
                                                    <v-list-item-action class="mr-0">
                                                        <v-icon small>mdi-pound</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="caption">{{ pop.popName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span>Pop ID</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-expand-transition>
                            </v-card-title>
                            {{ /* v-if="cardOne[pop.id] == 'start'"  */ }}
                            <v-row 
                                :style="{ minHeight: 'calc(100% - 1rem)' }"
                                class="ma-0 pt-3 px-3 card"
                                v-bind:class="{ flipme: cardOne[pop.id] == 'start' }"
                            >
                                <v-col cols="auto" class="d-flex justify-center align-center ma-0 pa-0 pb-3" style="width: 100%;"> 
                                    <div
                                        v-if="
                                            pop.media.url.split('.').pop() === 'mp4' ||
                                            pop.media.url.split('.').pop() === 'webm' ||
                                            pop.media.url.split('.').pop() === 'mov'
                                        "
                                        class="d-flex justify-center align-center"
                                        style="width: 100%; object-fit: cover; cursor: pointer"
                                    >  
                                        <video
                                            :key="pop.media.url"
                                            style="
                                                max-height: 400px;
                                                max-width: 330px;
                                                display: block;
                                            "
                                            controls
                                            >
                                            <source
                                                :src="pop.media.url"
                                                controls
                                            >
                                        </video>
                                    </div>
                                    <div v-else style="width: 100%">
                                        <v-img
                                            contain 
                                            :lazy-src="pop.media.urlThumb"
                                            :src="pop.media.urlThumb"
                                            :style="{ position: 'relative', maxHeight: '400px', cursor: 'pointer' }"
                                            @click="openImage(pop.media.url)"
                                        >
                                            <!-- Display download btn -->
                                            <div
                                                :style="
                                                    ($vuetify.theme.dark)
                                                    ? {
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px'
                                                    }
                                                    : {
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px'
                                                    }
                                                "
                                            >
                                                <v-btn 
                                                    fab 
                                                    small
                                                    @click.prevent="downloadItem({media: pop.media})"
                                                ><v-icon>mdi-download</v-icon></v-btn>
                                            </div>
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                    </div>
                                </v-col>
                                <!-- Display Tags -->
                                <v-expansion-panels flat :value="tagsDropAll">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="d-flex align-center">
                                            <span class="text-center" style="opacity:0.35; margin-bottom: 0.6%;">Toggle Tag View</span>
                                            <!-- 
                                            <v-col cols="auto" class="d-flex justify-center my-n5 pb-6 overflow-hidden">
                                                <v-chip
                                                    class="text-capitalize mt-3 ml-5 flex-grow-0 flex-shrink-0"
                                                    :style="'height: 25px; background: none; border: 1px solid '+addColorToTag(pop.media.tags.find(x => x.shortName === 'uploadedby'))"
                                                    @click="searchTag(pop.media.tags.find(x => x.shortName === 'uploadedby'))"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >{{pop.media.tags.find(x => x.shortName === 'uploadedby').value.toUpperCase()}}</span>
                                                        </template>
                                                        <span>{{(pop.media.tags.find(x => x.shortName === 'uploadedby').categoryName)}}</span>
                                                    </v-tooltip>
                                                </v-chip>
                                            </v-col>
                                            -->
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <!--
                                            <div v-if="pop.media.tags.length <= 1" class="text-center" style="opacity: 0.5">No tags to display</div>
                                            -->
                                            <v-col cols="auto" class="d-flex justify-center my-n5 pb-6 flex-wrap" style="gap:1em;">
                                                <v-chip
                                                    v-for="(tag, index) in pop.media.tags" :key="index"
                                                    class="text-capitalize mt-3 flex-grow-0 flex-shrink-0"
                                                    :style="'height: 25px; background: none; border: 1px solid '+addColorToTag(tag)"
                                                    @click="searchTag(tag)"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >{{tag.value.toUpperCase()}}</span>
                                                        </template>
                                                        <span>{{(tag.categoryName)}}</span>
                                                    </v-tooltip>
                                                </v-chip>
                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-row>
                             <!-- Emotional impcat
                            <v-row
                                v-if="cardOne[pop.id] == 'flipped'" 
                                class="ma-0 pt-3 px-3 card"
                                :style="{ minHeight: 'calc(100% - 1rem)' }"
                            >   
                                <Heatmap
                                    :pop="pop"
                                />
                            </v-row>-->
                        </v-card>
                    </v-lazy>
                </v-col>
            </v-layout>
        </v-expand-x-transition>
        <!-- Map View -->
        <v-expand-x-transition>
            <v-layout v-if="viewType === 'map'" >
                <MapView v-bind:locationtableData="filteredLocationtableData" />
            </v-layout>
        </v-expand-x-transition>
        <!-- Edit Tags View -->
        <v-expand-x-transition>
            <v-layout v-if="viewType === 'tag'" >
                <EditTags 
                    v-bind:masterTagCateg="originalTagCategoryList"
                    v-bind:tagCateg="tagCategoriesEditTagsModal"
                />
            </v-layout>
        </v-expand-x-transition>
         <!-- DIALOGS -->
         <v-row>
            <!-- Delete pop dialog -->
            <v-dialog
                v-if="selectedContentForEdit"
                v-model="deleteContentDialog"
                width="600"
            >
                <v-card class="px-6 pt-6 text-center" >
                    Are you sure you want to delete
                    {{ JSON.stringify(selectedContentForEdit.title) }}?        
                    <v-card-actions class="pb-6 pt-4">
                        <v-col
                            cols="12"
                            class="text-center ma-0 pa-0"
                        >
                            <v-btn
                                color="primary"
                                class="mr-3"
                                @click="deletePoPs()">
                                Confirm
                            </v-btn>
                            <v-btn
                                color="red"
                                @click="deleteContentDialog = false">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        {{/** Misc elements Loader/Persistent snackbar */}}
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
                class="ml-3"
            ></v-progress-circular>
            <br/>
            <div class="">Fetching pops...</div>
        </v-overlay>
        <v-snackbar
            v-if="!checkDisclaimer() && $route.name === 'PopGallery'"
            v-model="snackbar"
            color="primary"
            :timeout="timeout"
            :top="top" 
            :right="right"
        >
            Photos are often taken on mobile phone meaning the quality can vary. The ad looks fine in person when checked (unless otherwise stated). Pro shoots are tagged 'PRO'.

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="closePersistSnack()"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import helpersColor from '@/mixins/helpers/color'
import CampaignController from "@/services/controllers/Campaign"
import axios from 'axios'

import UploadPop from './Upload.vue'
import EditPop from './Edit.vue'
import ContactFormVue from './ContactForm.vue'
import GenerateLink from './GenerateLink.vue'
import EditClipboard from './edit/EditClipboard.vue'
import MapView from './MapView.vue'
import EditTags from './edit/EditTags.vue'
import Heatmap from './Heatmap.vue'

export default {
    mixins: [
      helpersColor,
    ],

    components: {
    UploadPop,
    EditPop,
    ContactFormVue,
    GenerateLink,
    EditClipboard,
    MapView,
    EditTags,
    Heatmap
},
    props: ['cid'],
    data: () => ({
            // Initial data loaded
            overlay: false,
            isLoading: false,
            // Init tags array
            tags: [],
            // Dots
            dots: [],
            // Init selected pop array (list of pops from db)
            pops: [],
            // Sort by title only, expand eventually
            sortBy: {name: 'Date Added', id: -1},
            // Order by param, expand eventually
            orderBy: {name: 'Descending', value: 'desc'},
            // Init category
            selectedCategory: null,
            // Init category value
            selectedValue: null,
            // Init filter array
            addedFilters:[],
            // Display individually or slideshow
            viewStructureType: 1,
            // Stores Pops on front for further data manipulation
            localPops: [],
            // Delete PoP Up
            deleteContentDialog: false,
            selectedContentForEdit: null,
            // Campaigns
            allCampaigns: [],
            readMore: {},
            // Match categ color to value for display
            originalTagCategoryList: [],
            tagCategoriesEditTagsModal: [],
            isActive: false,
            // Infinite pop scrolling
            startingAt: 0,
            totalPops: 0,
            pageSize: 40,
            // Search bar
            searchValues: [],
            searchText: [],
            search: null,    
            //contact
            contactFormDialog: false,
            search: null,
            finalSearchValue: '',
            // Persistent snackbar
            snackbar: false,
            timeout: -1,
            top: true,
            right: true,
            // Controllers
            headerDropAll: false,
            tagsDropAll: 1,
            viewType: 'grid',
            // Pop data filtered for mapview
            locationtableData: [],
            // Map view Marker Limit
            markerLimit: 500,
            // Heatmaps
            emotionalImpactActive: [],
            cardOne: {},
    }),
    watch: {
        searchText (val, prev) {
            if (val.length === prev.length) return

            this.searchText = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        categoryId: 7781, 
                        shortName: 'misc',
                        category: 'misc', 
                        value: v,
                        colour: 'white'
                    }
                }
                return v
            })
        },
        headerDropAll: {
            handler () {
                if(this.headerDropAll){
                    Object.keys(this.readMore).forEach(key => {
                        this.readMore[key] = true
                    })
                } else {
                    Object.keys(this.readMore).forEach(key => {
                        this.readMore[key] = false
                    })
                }
            },
            immediate: true
        }
    },
    computed: {
        // Filter locationtableData for mapview and return only the ammount of markers specified in markerLimit
        filteredLocationtableData() {
            let result = []
            result = this.locationtableData.filter((item, index) => {
                return index < this.markerLimit
            })
            return result
        },
        Categories() {
            return this.originalTagCategoryList.map((data) => {
                return data
            })
        },
        Values() {
            let values = []
            if(this.selectedCategory) {
                this.originalTagCategoryList.forEach((item) => {
                    if(item.shortName === this.selectedCategory.shortName)
                    item.tags.forEach((tag)=>{
                        values.push(tag.value)
                    })
                })
            }
            return values
        },
        SortByList() {
            let values = []
            values.push({name: 'Date Added', id: -1},{name: 'Title', id: 0})
            this.originalTagCategoryList.forEach((item) => {
                values.push(item)
            })
            return values
        },
        Mutation(){
            let result = []
            // trim every value of the array
            this.searchText.forEach((item) => {
                item.value.trim()
            })
            const arr = this.searchText.map(object => object.value)
            result = arr.join('|')
            result.trim()
            return result
        },
        isPopEmotionActive() {
            return (pop) => {
                return this.emotionalImpactActive.includes(pop.id)
            }
        }
    },

    async created() {
        if(this.canPopEdit() || this.canPopDelete())
            await this.getAllCampaigns()
        await this.getTags()
        await this.GlobalTagValues()
        // Pre-populate query variables if exist
        if(this.$route.name === 'PopGallery' && typeof this.$route.query === 'object'){
            this.pagesize = this.$route.query.pageSize === undefined ? 40 : this.$route.query.pageSize
            this.orderBy.value = this.$route.query.orderBy === undefined ? 'desc' : this.$route.query.orderBy
            this.finalSearchValue = this.$route.query.searchValues === undefined ? '' : this.$route.query.searchValues
            this.startingAt = this.$route.query.startingAt === undefined ? 0 : this.$route.query.startingAt
            this.sortBy.id = this.$route.query.sortCategoryId === undefined ? -1 : this.$route.query.sortCategoryId
            if(this.$route.query.mapView !== undefined){
                if(this.$route.query.mapView.includes('true')){
                    this.viewType = 'map'
                }
            }
            // Pre-populate search bar
            if(this.finalSearchValue !== ''){
                const result = this.finalSearchValue.split('|')
                result.forEach(item => {
                    const matchingValue = this.searchValues.find(tagValue => tagValue.value.toUpperCase() === item.toUpperCase() )
                    if(matchingValue){
                        this.searchText.push(matchingValue) 
                    }else {
                        this.searchText.push({
                        categoryId: 7781,
                        shortName: 'misc',
                        category: 'misc', 
                        value: item,
                        colour: 'white'
                    })
                    }
                })
            }
        }
        if(this.cid){
            const foundCampaign = this.allCampaigns.find(obj => {return obj.id === this.cid})
            this.searchText.push({
                categoryId: 7781,
                shortName: 'misc',
                category: 'misc', 
                value: foundCampaign.name,
                colour: 'white'
            })
        }
        await this.infiniteScrolling()
    },
    provide: function () {
        return {
            dynamicSort: this.dynamicSort,
            addColorToTag: this.addColorToTag,
            updatePops: this.updatePops,
            appendPop: this.appendPop,
            totalPopsIncrement: this.totalPopsIncrement,
            downloadItem: this.downloadItem,
            openImage: this.openImage,
            searchTag: this.searchTag,
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            // Check if we have reached the end of the page
            if (document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight -2) {
                // If we are already loading data or have reached the end, do nothing
                if (this.overlay || this.reachedEnd || this.viewType === 'map' || this.viewType === 'tag') return;
                this.infiniteScrolling();
            }
        });
        // Activate snack bar when page mounted also check coockies
        this.snackbar = true
    },
    methods: {
        // Show/Hide timeline
        emotionalImpact(id) {
            // add the pop id with the value of true to the array emotionalImpactActive
            if(!this.emotionalImpactActive.includes(id)){
                this.emotionalImpactActive.push(id)
            } else {
                // remove the pop id from the array emotionalImpactActive
                this.emotionalImpactActive.splice(this.emotionalImpactActive.indexOf(id), 1)
            }
            if(this.cardOne[id] == 'start'){
            this.$set(this.cardOne, id, 'flipped')
            } else {
            this.$set(this.cardOne, id, 'start')
            }
        },
        // Function to check wahtever a pop has a screen quality of playback quality tag and checks if one of the values is 'low'
        checkScreenQuality(pop) {
            const screenQualityTag = pop.media.tags.find(x => x.shortName === 'screenquality')
            const playBackTag = pop.media.tags.find(x => x.shortName === 'playbackquality')
            if(screenQualityTag){
                if(screenQualityTag.value.trim().toUpperCase() === 'LOW'){
                    return true
                }
            }
            if(playBackTag){
                if(playBackTag.value.trim().toUpperCase() === 'LOW'){
                    return true
                }
            }
            return false
        },
        // Copy/paste methods
        // Function to paste triggers to multiple artworks
        pasteGlobalTriggers() {
            // Assign list of selected artworks to paste into to a respective var 
            const selectedList = this.$store.state.Clipboard.selected
            // Loader start
            this.$store.dispatch('PopGal/toggleLoader', true)
            // For loop traversing the selected cards and assigning the triggers to respective artwork for update
            for(var i=0;i<selectedList.length;i++){
                this.saveTriggerQueue(selectedList[i], this.$store.state.PopGal.triggersCopyData)
            }
            // Call recursive function to update db with updated triggers
            this.saveNextTrigger()
        },
        addSelectedList(selectedPop){
            this.$store.dispatch('PopGal/addSelected', selectedPop)
        },
        checkSelectedList(id){
            return this.$store.state.PopGal.listPops.includes(id)
        },
        // Copy/paste triggers function
        selectedPopCopy(data){
            this.$store.dispatch('PopGal/setClipboard', data)
        },
        /** Misc */
        checkDisclaimer(){
            return this.$cookies.get('disclaimer')
        },
        closePersistSnack(){
            this.snackbar = false
            this.$cookies.set('disclaimer', true, "30d")
        },
        Video(pop){
            return pop.media.mimeType
        },
        GlobalTagValues() {
            this.originalTagCategoryList.forEach((item) => {
                item.tags.forEach((tag)=>{
                    let color = this.originalTagCategoryList.filter(obj => {
                        return obj.id === item.id
                    })
                    this.searchValues.push({ 
                        categoryId: item.id, 
                        shortName: item.shortName,
                        category: item.name, 
                        value: tag.value,
                        colour: color ? color[0].colour : 'white'
                    })
                })
            })
        },
        openImage(url) {
            window.open(
            url,
            '_blank', // <- This is what makes it open in a new window.
            )
        },
        addColorToTag(tag) {
            const filterCateg = this.originalTagCategoryList.filter(obj => {
                    return obj.id === tag.categoryId
            })
            if(filterCateg == []){
                return 'white'
            } else {
                return filterCateg[0].colour
            }
            
        },
        totalPopsIncrement(){
            this.totalPops++
        },
        mergeById(arr) {
            return {
                with: function(arr2) {
                    return _.map(arr, item => {
                        return _.find(arr2, obj => obj.id === item.id) || item
                    })
                }
            }
        },
        async downloadItem({ media }) {
            let downloadUrl
            let downloadFileName
            let mimeType
            downloadUrl = media.url
            downloadFileName = media.originalFileName
            mimeType = media.mimeType
            let cachebuster = Math.round(new Date().getTime() / 1000);
            const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
            const blob = new Blob([response.data], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = downloadFileName;
            link.click();
            URL.revokeObjectURL(link.href);
        },
        dynamicSort(prop) {
            var sortOrder = 1;
            if(prop[0] === "-") {
                sortOrder = -1;
                prop = prop.substr(1);
            }
            return function (a,b) {
                var result = (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        /** Toggle Card header show more/less */
        showMore(id) {
            if(this.readMore[id]){
                this.$set(this.readMore, id, false);
            } else {
                this.$set(this.readMore, id, true);
            }
        },
        /** Infinte scroll + data fetch */
        async infiniteScrolling() {
            if(this.searchText.length !== 0){
                this.finalSearchValue = this.Mutation
            }
            this.overlay = true
            if(this.viewType === 'map'){
                this.pageSize = 0
                setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 150);
            } else{
                this.pageSize = 40
            }
            if((this.startingAt === 0 || Math.ceil(this.totalPops/ 10) * 10 >= this.startingAt)) {
                await CampaignController.getPoPs('?pageSize='+this.pageSize+'&startingAt='+this.startingAt+'&searchValues='+encodeURIComponent(this.finalSearchValue)+'&sortCategoryId='+this.sortBy.id+'&orderBy='+this.orderBy.value).then((res) =>{
                    this.totalPops = res.data.total
                    this.startingAt += this.pageSize
                    res.data.list.forEach(async (pop) =>{
                        // Set all pop header card to close state
                        this.$set(this.readMore, pop.id, this.headerDropAll)
                        // Store all dots along with pop id for further display outside of filters list
                        const dot = pop.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                        pop['dot'] = dot[0]
                        // Remove dot filter from display
                        pop.media.tags = pop.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                        this.pops.push(pop)
                        if(this.viewType === 'map'){
                            await this.getInitialCoords(pop)
                        }
                    })
                }).catch((err)=>{
                        console.log(err)
                        return
                    })
            }
            // fill cardOne with start value of every pop so this is true v-if="cardOne[pop.id] == 'flipped'" 
            this.pops.forEach((pop) => {
                this.$set(this.cardOne, pop.id, 'start')
            })
            this.overlay = false
        },
        async getInitialCoords(pop) {
            const coords = await pop.media.tags.find(tag => tag.shortName === 'latlong')
            if(!coords || !coords.value.includes(',')){return}
            this.locationtableData.push(pop)
        },
        async submitSearch() {
            this.finalSearchValue = ''
            this.startingAt = 0
            this.pops = []
            this.locationtableData = []
            await this.infiniteScrolling()
            window.scrollTo(0,0)
            this.$nextTick()
        },
        async searchTag(tag){
            tag.colour = this.addColorToTag(tag)
            this.searchText = []
            this.searchText.push(tag)
            this.startingAt = 0
            this.pops = []
            this.locationtableData = []
            await this.infiniteScrolling()
            window.scrollTo(0,0)
        },
        async updatePops(newPop){
            const oldVal = this.pops.find(item => item.id === newPop.data.id)
            const popAtIndex = this.pops.indexOf(oldVal)
            this.pops.splice(popAtIndex, 1, newPop.data)
            await this.$nextTick()
        },
        async appendPop(newPop){
            this.pops.unshift(newPop.data)
            await this.$nextTick()
        },
        async deletePoPs() {
            const deletePop = await CampaignController.deletePoP(this.selectedContentForEdit.id)
            if(!deletePop){
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
            }
            this.$root.$emit(
                'snackbarSuccess',
                'Successfully deleted media from PoP gallery.',
            )
            this.pops = this.pops.filter((pop) => {
                return pop.id !== this.selectedContentForEdit.id;
            })
            this.totalPops--
            await this.$nextTick()
            this.deleteContentDialog = false
        },
        async getAllCampaigns() {
            // Get all campaigns
            const campaigns = await CampaignController.getAllCampaigns()
            if(campaigns){
                campaigns.data.forEach(campaign =>{
                    this.allCampaigns.push(campaign)
                })
            }
            this.allCampaigns.sort(this.dynamicSort("name"))
        },
        async getTags() {
            const tagCategories = await CampaignController.getPoPTagCategories()
            if(!tagCategories) return
            this.tagCategoriesEditTagsModal = [...tagCategories.data]
            tagCategories.data.forEach((tagCategory) =>{
                this.originalTagCategoryList.push(tagCategory)
                if(!(tagCategory.shortName === "errordot" || tagCategory.shortName === "uploadedby" || tagCategory.shortName === 'datetimetaken')) {
                    this.tags.push(tagCategory)
                } else {
                    if(tagCategory.shortName === "errordot") {
                        tagCategory.tags.forEach((tag) =>{
                            if(tag.tagId < 1000) {
                                const name = tag.value.split(" ")[0]
                                const hex = tag.value.split(" ")[1]
                                this.dots.push({id: tag.tagId ,name: name, color: hex})
                            }
                        })
                    }
                }

            })
        },
        /** Permissions */
        canPopEdit() {
            return this.$store.state.Permissions.proofOfPlayEdit || this.$store.state.Permissions.proofOfPlayEditTag
        },
        canPopDelete() {
            return this.$store.state.Permissions.proofOfPlayRemove || this.$store.state.Permissions.proofOfPlayRemoveTag
        },
    }
}

</script>
<style scoped>
    .toggleUpDown {
        transition: transform .2s ease-in-out !important;
    }
    .toggleUpDown.rotate {
        transform: rotate(-180deg);
    }
    .dot {
        min-height: 12px;
        min-width: 12px;
        border-radius: 50%;
        background-color: none;
    }
.flipme {
  transform: rotateY(180deg);
}
.card {
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.card__face {
  align-items: start;
}
.card__face--back {
  transform: rotateY(180deg);
}
</style>